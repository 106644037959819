import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import authApi from "../../../../utils/authApi";
// import "moment-timezone";
// import { camelCase } from "lodash";
import { useSelector } from "react-redux";
import { APIBASE } from "../../../../constants";

const TrackPackagePopup = ({ orderId }) => {
  const [loading, setLoading] = useState(true);
  // console.log('Rendering TrackPackagePopup for orderId:', orderId);
  // console.log(loading);
  const [trackingData, setTrackingData] = useState([]);
  const token = useSelector((state) => state.userAuth.user._token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authApi.get(
          `${APIBASE}customer/order/process/${orderId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );


        if (response?.status === 200) {
          setTrackingData(response.data);
          console.log("tracked data", response.data);
        }
      } catch (error) {
        console.error("Error fetching tracking data:", error);
      } finally {
        setLoading(false); // Set loading state to false regardless of success or error
        // console.log(loading);
      }
    };
    fetchData();
  }, [orderId, token]);

  // // Monitor changes in trackingData and log them
  // useEffect(() => {
  //   console.log("Updated trackingData:", trackingData);
  // }, [trackingData]);

  return (
    <div className="modal-content">
      <div className="see-all-updates">
        <h3>Delivery by Shopnmac</h3>
        {trackingData?.data && trackingData.data?.length > 0 ? (
          <React.Fragment>
            <div className="tracking">
              Tracking ID: {trackingData.data[0]?.verify_code}
            </div>
            <div className="date">
              <Moment format="YYYY-MM-DD">
                {trackingData.data[0]?.created_at}
              </Moment>
            </div>
            <ul>
              <li>
                <strong>
                  <Moment format="HH:mm A">
                    {trackingData.data[0]?.created_at}
                  </Moment>
                </strong>
                <p style={{ marginLeft: "5rem", textTransform: "capitalize" }}>
                  {trackingData.data[0]?.status}{" "}
                  <span>{trackingData.data[0]?.notes}</span>
                </p>
              </li>
            </ul>
            <div className="date">
              <Moment format="YYYY-MM-DD">
                {trackingData.data[1]?.created_at}
              </Moment>
            </div>
            <ul>
              <li>
                <strong>
                  <Moment format="HH:mm A">
                    {trackingData.data[1]?.created_at}
                  </Moment>
                </strong>
                <p style={{ marginLeft: "5rem", textTransform: "capitalize" }}>
                  {trackingData.data[1]?.status}{" "}
                  <span>{trackingData.data[1]?.notes}</span>
                </p>
              </li>
            </ul>
            <div className="date">
              <Moment format="YYYY-MM-DD">
                {trackingData.data[2]?.created_at}
              </Moment>
            </div>
            <ul>
              <li>
                <strong>
                  <Moment format="HH:mm A">
                    {trackingData.data[2]?.created_at}
                  </Moment>
                </strong>
                <p style={{ marginLeft: "5rem", textTransform: "capitalize" }}>
                  {trackingData.data[2]?.status}{" "}
                  <span>{trackingData.data[2]?.notes}</span>
                </p>
              </li>
            </ul>
          </React.Fragment>
        ) : (
          <p>No data available...</p>
        )}
      </div>
    </div>
  );
};

export default TrackPackagePopup;

// import React, {useState, useEffect} from "react";
// import { authToken } from "../../auth/auth";
// import axios from "axios";

// const TrackPackagePopup = () => {

//   const [trackingData, setTrackingData] = useState([]);

//   useEffect(() => {
//     const trackingId = "289";

//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `https://snapi.quicsy.com/api/customer/order/process/${trackingId}`,
//           {
//             headers: {
//               Authorization: `Bearer ${authToken}`,
//             },
//           }
//         );

//         if (response.status === 200) {
//           setTrackingData(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching tracking data:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Monitor changes in trackingData and log them
//   useEffect(() => {
//     console.log("Updated trackingData:", trackingData);
//   }, [trackingData]);

//   return (
//     <div className="modal-content">
//       <div class="see-all-updates">
//         <h3>Delivery by Shopnmac</h3>
//         <div class="tracking">Tracking ID: {trackingData.verify_code}</div>
//         <div class="date">Monday, 10 August</div>
//         <ul>
//           <li>
//             <strong>10:06 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Delivered <span>FARIDABAD, IN</span>
//             </p>
//           </li>
//           <li>
//             <strong>07:09 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Out for delivery <span>FARIDABAD, IN</span>
//             </p>
//           </li>
//           <li>
//             <strong>06:38 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Package arrived at final delivery station
//               <span>FARIDABAD, IN</span>
//             </p>
//           </li>
//           <li>
//             <strong>03:36 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Package has left Amazon facility
//               <span>GURUGRAM, HARYANA IN</span>
//             </p>
//           </li>
//         </ul>
//         <div class="date">Sunday, 9 August</div>
//         <ul>
//           <li>
//             <strong>11:44 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Package arrived at an Amazon facility
//               <span>TAORU, HARYANA IN</span>
//             </p>
//           </li>
//           <li>
//             <strong>03:11 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Package has left an Amazon facility
//               <span>GURGAON, HARYANA IN</span>
//             </p>
//           </li>
//           <li>
//             <strong>02:01 AM</strong>
//             <p style={{ marginLeft: "5rem" }}>
//               Package arrived at an Amazon facility
//               <span>GURGAON, HARYANA IN</span>
//             </p>
//           </li>
//         </ul>
//         <div class="date">Saturday, 8 August</div>
//         <ul>
//           <li style={{ marginLeft: "5rem" }}>
//             <p>Package has shipped</p>
//           </li>
//         </ul>
//       </div>
//     </div>
//   );
// };

// export default TrackPackagePopup;
