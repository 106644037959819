import React, { useState, useEffect } from "react";
import "./DefaultAddress.css";
import { Link } from "react-router-dom";
import { BsPlusLg } from "react-icons/bs";
import authApi from "../../../../utils/authApi";

const DefaultAddress = () => {
  const [defaultAddress, setDefaultAddress] = useState(null);

  useEffect(() => {
    const id = 5; // Replace ****////****Important */
    // Axios GET request with authorization header
    authApi
      .get(`/customer/get-address-by-id/${id}`)
      .then((response) => {
        setDefaultAddress(response.data.data); // Set the address data
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  }, []);

  return (
    <>
      {defaultAddress && (
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
          <div className="default-address mt-xl-4 mt-0">
            <h3>Default Shipping Address</h3>
            <p className="mb-1">
              {defaultAddress.first_name} {defaultAddress.middle_name}{" "}
              {defaultAddress.last_name}
            </p>
            <p>
              {defaultAddress.flat_apartment}, {defaultAddress.address},{" "}
              {defaultAddress.street}, {defaultAddress.city},{" "}
              {defaultAddress.state} {defaultAddress.zipcode}
            </p>
            <p>{defaultAddress.country}</p>

            <Link to="#" className="btn theme-btn mt-3">
              <BsPlusLg /> Add New Address
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default DefaultAddress;

// import React from 'react';
// import './DefaultAddress.css';
// import {Link} from "react-router-dom";
// import {BsPlusLg} from 'react-icons/bs'

// const DefaultAddress = () => {

//   return (
//     <>
//        <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
//         <div className='default-address mt-xl-4 mt-0'>
//           <h3>Deafult Shipping Address</h3>
//           <p className='mb-1'>Jyotirmoy Sinha
//   </p>
//           <p>Dda Flat, Pocket 13, Dwarka Ph 1, Manglapuri, Palam <br></br>
//           New Delhi 110045
//   </p>
//           <p>Bermuda</p>

//           <Link to="#" className="btn theme-btn mt-3"><BsPlusLg/> Add New Address</Link>
//         </div>
//       </div>
//     </>
//   )
// }

// export default DefaultAddress;
