import React from 'react';
import './CardCompnent.css';
import { Link, useNavigate } from "react-router-dom";



function CardComponent({ backgroundColor, title, totalvalue, linkText, link, className, icon: Icon, righticon: RightIcon, color }) {

  const nagivate = useNavigate();

  const handleGoTo = () => {
    nagivate(link)
  }

  return (
    <>
      <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6' onClick={() => handleGoTo()}>
        <div className={`dashboard-card mb-3 mb-xl-0 ${className}`} style={{ backgroundColor: `${backgroundColor}` }}>
          <div className='d-flex align-items-center'>
            <div className="card-icon mr-3" style={{ color: `${color}` }}>
              <Icon />
            </div>
            <div className='card-title' style={{ color: `${color}` }}>
              <h4>{title}</h4>
              <h3>{totalvalue}</h3>
            </div>
          </div>
          <Link to="#" className='card-link' style={{ color: `${color}` }}>{linkText} {RightIcon && <RightIcon />}</Link>
        </div>
      </div>
    </>
  )
}

export default CardComponent
