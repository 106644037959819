import { Link } from "react-router-dom";
import "./Contactus.css";
import ContactImage from "../../assets/Contact-us.jpg";
import { useEffect, useState } from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import { toast } from "react-toastify";
import axios from "axios";
import { APIBASE } from "../../constants";
const initialState = { name: "", email: "", phone: "", message: "" };
export default function ContactUs() {
  const [state, setState] = useState(initialState);
  const [code, setCode] = useState(generateOTP());
  const [inputCode, setInputCode] = useState("");
  const [codeVerified, setCodeVerified] = useState(false);

  function generateOTP() {
    const digits = "0123456789";
    let otp = "";

    for (let i = 0; i < 4; i++) {
      const randomIndex = Math.floor(Math.random() * digits?.length);
      otp += digits[randomIndex];
    }
    return otp;
  }

  useEffect(() => {
    if (inputCode === code && inputCode?.length === 4) {
      setCodeVerified(true);
    } else if (inputCode !== code && inputCode?.length === 4) {
      setCode(generateOTP());
      setInputCode("");
      setCodeVerified(false);
    } else {
      setCodeVerified(false);
    }
  }, [inputCode]);

  const handleFormSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.post(`${APIBASE}contacts`, state);
      toast.success("Message sent successfully.")
      setState(initialState)
      setInputCode('')
      generateOTP()
    } catch (error) {
      toast.error("Error!");
    }
  };

  return (
    <main>
      <section>
        <div className="container-side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form">
              <div className="heading pb-4">
                <h3>Do you encounter any problems purchasing your goods?</h3>
                <p>
                  Email us at{" "}
                  <Link className="ancher-text" to="mailto:info@shopnmac.com">
                    info@nmac.bm
                  </Link>{" "}
                  or fill in the form below. We'll get back to you within 24
                  hours (typically sooner!) to arrange a time to speak.
                </p>
              </div>
              <div className="form-body">
                <div className="form-inner">
                  <form onSubmit={(e) => handleFormSubmit(e)}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="form-control"
                        required=""
                        value={state.name}
                        onChange={(e) =>
                          setState({ ...state, name: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        name="email"
                        placeholder="Email"
                        className="form-control "
                        required=""
                        value={state.email}
                        onChange={(e) =>
                          setState({ ...state, email: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="number"
                        name="phone"
                        placeholder="Phone"
                        className="form-control"
                        required=""
                        value={state.phone}
                        onChange={(e) =>
                          setState({ ...state, phone: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <textarea
                        type="text"
                        style={{ height: "80px", resize: "vertical" }}
                        name="message"
                        placeholder="Message"
                        className="form-control "
                        value={state.message}
                        onChange={(e) =>
                          setState({ ...state, message: e.target.value })
                        }
                      />
                    </div>

                    <div className="input-group mb-3">
                      <input
                        type="text"
                        value={inputCode}
                        className="form-control border-right-0"
                        placeholder="Enter Code →"
                        onChange={(e) => setInputCode(e.target.value)}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text border-left-0 border-custom">
                          {code}
                        </span>
                      </div>
                      {codeVerified && (
                        <div
                          style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            right: "100px",
                            top: "10px",
                          }}
                        >
                          <BsPatchCheckFill
                            style={{ color: "green", fontSize: "24px" }}
                          />
                        </div>
                      )}
                    </div>

                    <div className="form-group">
                      <div className="text-center pt-3">
                        <input
                          type="submit"
                          value="Send Message"
                          className="btn btn-theme"
                          disabled={inputCode !== code}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="header-contact-information">
              <div className="information-cells">
                <div className="information-cell information-cell--green">
                  <div className="information-cell__icon">
                    <span className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </span>
                  </div>

                  <div className="information-cell__content">
                    <div className="information-cell__title">Call us</div>
                    <Link
                      to="tel:01225580037"
                      className="information-cell__link"
                    >
                      293-5476
                    </Link>
                    {/* <div className="information-cell__text">Mon - Fri | 9am - 5pm</div> */}
                  </div>
                </div>

                <div className="information-cell information-cell--blue">
                  <div className="information-cell__icon">
                    <span className="icon">
                      <i className="far fa-envelope"></i>
                    </span>
                  </div>

                  <div className="information-cell__content">
                    <div className="information-cell__title">Email us</div>
                    <Link
                      to="mailto:info@blubolt.com"
                      className="information-cell__link"
                    >
                      info@nmac.bm
                    </Link>
                  </div>
                </div>

                <div className="information-cell information-cell--purple">
                  <div className="information-cell__icon">
                    <span className="icon">
                      <i className="fab fa-whatsapp"></i>
                    </span>
                  </div>

                  <div className="information-cell__content">
                    <div className="information-cell__title">Chat to us</div>
                    <Link to="#" className="information-cell__link">
                      Click the message icon on <br></br>
                      any page to live chat!
                    </Link>
                  </div>
                </div>

                <div className="information-cell information-cell--yellow">
                  <div className="information-cell__icon">
                    <span className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>

                  <div className="information-cell__content">
                    <div className="information-cell__title">Visit us</div>
                    <Link to="#" className="information-cell__link">
                      NorthShore Medical &amp; Aesthetics <br></br> Center Ltd.
                      P.O. Box HM1839 <br></br> Hamilton HMGX, Bermuda
                    </Link>
                  </div>
                </div>

                <div className="information-cell information-cell--wide">
                  <img src={ContactImage} className="img-fluid" alt="contact" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
