import authApi from "../utils/authApi";
import api from "../utils/api";

// comsole.log(error);("authApi", authApi);

export const getShippingCharge = async ({ country, totalAmount }) => {
  try {
    // Make the API call to get the shipping charges
    const response = await api.get(`shipping-charge/${country}/${totalAmount}`);
    // // comsole.log(error);(response?.data);
    // Return the received shippingCharge value
    return parseFloat(response?.data?.amount);
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getTaxCharge = async ({ amount }) => {
  try {
    const response = await api.get(`calculate-tax/${amount}`);
    // // comsole.log(error);(response?.data?.tax_amount);
    return response?.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

export const getCouponDiscount = async ({ couponCode, amount }) => {
  try {
    const response = await api.get(`coupon-discount/${couponCode}/${amount}`);
    // comsole.log(error);("cou", response.data);
    return response?.data;

  } catch (error) {
    throw error.response.data.message;
  }
};

export const checkoutOrder = async (data) => {
  try {
    const response = await authApi.post("checkout/payment", data);
    // comsole.log(error);("checkoutOrder", response.data);
    return response?.data;
  } catch (error) {
    throw error.response.data.message;
  }
};

// export const checkoutSubscription = async (data) => {
//   try {
//     const response = await api.post("checkout/subscription", data);
//     // // comsole.log(error);(response.data);
//     return response?.data;
//   } catch (error) {
//     throw error.response.data.message;
//   }
// };
