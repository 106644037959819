// import React, { useState, useEffect } from "react";
// import axios from "axios";
import "./MyProfile.css";
import AddNewComponent from "./AddNewAddress";
import { AiOutlinePlus } from "react-icons/ai";
import AddressComponent from "./AddressComponent";

import UserProfile from "./UserProfile";
const MyProfile = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading py-2">
            <h3>My Profile</h3>
          </div>
          {/* Divider Below heading is here */}
          <div className="divider" />

          {/* user profile component */}
          <div className="user-profile">
            <UserProfile />
          </div>
          {/* user profile component */}

          <div className="divider" />
          <div className="row pt-5 pb-5">
            {/*start  AddressComponent */}
            <AddressComponent />
            {/*end  AddressComponent */}

            {/* AddNewComponent start*/}

            <AddNewComponent
              title="Add New Address"
              Icon={AiOutlinePlus}
              className="AiOutlinePlus"
            />

            {/* AddNewComponent start*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
