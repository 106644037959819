import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Layout from "./modules/Layout";
// import Home from "./modules/Home";
import Shop from "./modules/Shop";
import Product from "./modules/Product";
import Cart from "./modules/Cart";
// import Shipping from "./modules/Shipping";
import Login from "./modules/Auth/Login";
import Signup from "./modules/Auth/Signup";
import ForgetPassword from "./modules/Auth/ForgetPassword";
import Contactus from "./modules/ContactUs";
import Payment from "./modules/Payment/Payment";
import Brands from "./modules/Brands";
import ProtectedRoute from "./modules/Layout/Protected";
import Success from "./modules/Checkout/Success";
import Failed from "./modules/Checkout/Failed";
import Deals from "./modules/Deal/Deal";
import Blog from "./modules/Blog/Blog";

// customers routes
import Dashboard from "./modules/customers/Pages/Dashboard/Dashboard";
import Sidebar from "./modules/customers/Layout/Sidebar";
import Order from "./modules/customers/Pages/Order/Order";
import SubscribedProducts from "./modules/customers/Pages/Order/SubscribeProduct/SubscribedProducts";
import MyReviewRatings from "./modules/customers/Pages/MyStuff/Rating/MyReviewRatings";
import MyWishList from "./modules/customers/Pages/MyStuff/Wishlist/MyWishList";
import MyProfile from "./modules/customers/Pages/AccountSetting/MyProfile";
import ChangePassword from "./modules/customers/Pages/AccountSetting/ChangePassword";
import EditProfile from "./modules/customers/Pages/AccountSetting/EditProfile";
import Createreview from "./modules/customers/Pages/MyStuff/Rating/Createreview";
import { PopupProvider } from "./modules/customers/Pages/Order/PopupContext";
import EditAddressForm from "./modules/customers/Pages/AccountSetting/EditedAddress";
import EditReview from "./modules/customers/Pages/MyStuff/Rating/EditReview";

import TermsAndConditions from "./modules/PolicyPages/TermsAndConditions";
import PrivacyPolicy from "./modules/PolicyPages/PrivacyPolicy";
import RefundPolicy from "./modules/PolicyPages/RefundPolicy";
import BlogDetails from "./modules/Blog/BlogDetails";

import AllProducts from "./modules/allProducts";

function App() {
  const location = useLocation();
  const shouldRenderSidebarRoutes = location.pathname.startsWith("/dashboard");

  return (
    <div className="App">
      <Routes>
        {/* web routes */}
        <Route path="/" element={<Layout />}>
          <Route index element={<Login />} />
          {/* <Route index element={<Home />} /> */}
          <Route path="/category/:category/" element={<Shop />} />
          <Route path="/brand/:brand/" element={<Shop />} />
          <Route path="/shop" element={<Shop />} />
          {/* <Route path="/all-products" element={<AllProducts/>} /> */}
          <Route path="/product-detail/:slug" element={<Product />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/deals" element={<Deals />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />

          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forget-password" element={<ForgetPassword />} />

          <Route path="/" element={<ProtectedRoute />}>
            <Route path="/all-products" element={<AllProducts />} />
            {/* <Route path="/shipping" element={<Shipping />} /> */}
            <Route path="/confirm-order" element={<Payment />} />
            <Route path="/checkout/success" element={<Success />} />
            <Route path="/checkout/failed" element={<Failed />} />
          </Route>
        </Route>
      </Routes>

      {shouldRenderSidebarRoutes && (
        <Sidebar>
          <PopupProvider>
            <Routes>
              {/* <Route exact path="/" element={<Dashboard />} /> */}
              <Route path="/dashboard/" element={<Dashboard />} />
              <Route path="/dashboard/order" element={<Order />} />
              <Route path="/dashboard/subscribed-products" element={<SubscribedProducts />} />
              <Route path="/dashboard/review" element={<MyReviewRatings />} />
              <Route path="/dashboard/create-review/:id" element={<Createreview />} />
              <Route path="/dashboard/edit-review/:review_id" element={<EditReview />} />
              <Route path="/dashboard/edit-profile" element={<EditProfile />} />
              <Route path="/dashboard/edit-address/:addressId" element={<EditAddressForm />} />
              <Route path="/dashboard/wishlist" element={<MyWishList />} />
              <Route path="/dashboard/my-profile" element={<MyProfile />} />
              <Route path="/dashboard/change-password" element={<ChangePassword />} />
            </Routes>
          </PopupProvider>
        </Sidebar>
      )}
    </div>
  );
}

export default App;
