import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Product.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/thumbs";

//Product components
import ProductSlider from "./components/Slider";
import ProductContent from "./components/Content";
import ProductCart from "./components/Cart";
import ProductTab from "./components/Tab";
//Common component
import ProductCard from "../shared/Product/Card";
import api from "../../utils/api";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/userCartSlice";
import { toast } from "react-toastify";
import SkeletonCard from "../shared/Skeleton/SkeletonCard";
import SkeletonProductpage from "../shared/Skeleton/SkeletonProductDeatil";
// import { fetchWishlist } from "../../redux/productSlice";
import { IMAGEURL } from "../../constants";

// import Review from "./components/Review";
import { format } from "date-fns";
import CustomerReviews from "./components/Review/CustomerReviews";

export default function ProductDetail() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  // const productUrl = `https://sn.quicsy.com/product-detail/${item}`;
  // const productUrl = `${IMAGEURL}+${item}`;


  //Add to cart function
  const handleAddToCart = (item, qty, interval) => {

    const newItem = {
      id: item.id,
      sku: item.sku,
      name: item?.product_name,
      slug: item.slug,
      old_price: item?.selling_price.toFixed(2) || 0,
      price:
        item?.discounted_price.toFixed(2) ?? item?.selling_price.toFixed(2),
      discount: item?.discount_value || 0,
      discount_type: item?.discount_type,
      qty: qty,
      unit: item?.unit,
      avl_qty: +item?.quantity- +item?.quantity_used,
      category: item?.category,
      weight: item?.weight,
      image: item?.thumbnail,
      thumbnail_compress: item?.thumbnail_compress
    };

    if (interval > 0) {
      const filteredSubscriptions = item.subscriptions.find(
        (subscription) => subscription.months === parseInt(interval)
      );
      newItem.subscription = {
        interval: interval,
        type: "months",
        discount: filteredSubscriptions,
      };
      if (filteredSubscriptions) {
        newItem.price =
          item?.selling_price -
          (filteredSubscriptions?.discount / 100) * item?.selling_price;

        newItem.discount = filteredSubscriptions?.discount || 0;
        newItem.discount_type = filteredSubscriptions?.discount_type;
      } else {
        newItem.price = item?.selling_price;
        newItem.discount = 0;
        newItem.discount_type = "Percent";
      }
    }
    dispatch(addToCart(newItem));
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setIsLoading(true);
      setError(null);

      try {
        // fetch product by slug
        const response = await api.get(`/product/${slug}`);
        // // comsole.log(error);("Product Data:", response.data?.data); 

        //fetch related products by category
        const related = await api.get(
          `categories/${response.data?.data?.category_id}/products?limit=10`
        );

        setRelatedProducts(related.data?.data);
        setProduct(response.data?.data);
        setIsLoading(false);
        // // comsole.log(error);(related.data?.data);
      } catch (error) {
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchProduct();
  }, [slug]);

  if (isLoading) {
    return <SkeletonProductpage />;
  }

  if (error) {
    toast.error(error.message);
  }

  // // comsole.log(error);("main show product", product);

  return (
    <>
      <section>
        <div className="container-side-space">
          <div className="product-details  ptb-5 ptb-md-3">
            <div className="product-image">
              <ProductSlider
                // images={product?.images}
                images={product?.images}
                thumbnail={product?.thumbnail_compress ? JSON.parse(product?.thumbnail_compress).image_urls["800px"] : IMAGEURL + product?.thumbnail}
              />
            </div>

            <div className="product-detail-content">
              {product && (
                <ProductContent
                  product={product}
                  onAddToCart={handleAddToCart}
                />
              )}
            </div>

            <div className="product-detail-cart cart-for-desk">
              {product && (
                <ProductCart product={product} onAddToCart={handleAddToCart} />
              )}
            </div>
          </div>
          <hr></hr>
          <div className="product-tab pt-md-5 pb-md-5 pb-3 pt-3 row">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <ProductTab
                description={product?.product_desc}
                additional={product?.other_desc}
              />
            </div>
            {/* <div className="col-xl-5 col-lg-5 col-md-12">
              <Review />
           </div> */}
          </div>
          <hr></hr>
          <div className="review-content pt-3 pt-md-5">
            <h3>RATINGS & CUSTOMER REVIEWS</h3>

            <div
              className="rating-box"
              style={{ justifyContent: "space-between" }}
            >
              <div className="w-50 w-sm-100">
                <CustomerReviews reviews={product?.reviews} />
              </div>
              <div className="w-50 w-sm-100">
                <div className="scrollbar" id="customscroll">
                  <div
                    className="force-overflow"
                    style={{ paddingRight: "28px" }}
                  >
                    {product?.reviews &&
                      product?.reviews.map((review, index) => (
                        <div key={index}>
                          <div className="rating-inner">
                            <p className="rating-title">
                              <span style={{ marginRight: "15px" }}>
                                {review.name}{" "}
                              </span>
                              {format(
                                new Date(review.created_at),
                                "d MMM yyyy"
                              )}
                            </p>
                            <p className="rating-para">
                              <span className="customer-rating">
                                {review.star}
                                <i
                                  className="fa fa-star orange-color ml-1"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              &nbsp; &nbsp; {review.headline}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>{/* <Review reviews={product.reviews} item={product} /> */}</div>
      </section>
      {/* <div>
        <Review reviews={item?.reviews} item={item} />
      </div> */}
      {/* <Review /> */}
      <section className="pt-sm-5 pb-sm-5 pt-3 pb-3">
        <div className="container-side-space">
          <div className="heading">
            <h3>CUSTOMERS ALSO BROUGHT</h3>
          </div>

          <Swiper
            slidesPerView={6}
            spaceBetween={15}
            // autoplay={{
            //   delay: 2500,
            //   disableOnInteraction: false,
            // }}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 2,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              680: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
              1440: {
                slidesPerView: 5,
                spaceBetween: 15,
              },
              1500: {
                slidesPerView: 6,
                spaceBetween: 15,
              },
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="productSwiper pt-3"
          >
            {relatedProducts?.length > 0
              ? relatedProducts?.map((product, index) => (
                <SwiperSlide key={index}>
                  <ProductCard
                    product={product}
                    onAddToCart={handleAddToCart}
                  />
                </SwiperSlide>
              ))
              : Array.from({ length: 6 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <SkeletonCard />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </section>
    </>
  );
}


// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import { Autoplay, Pagination, Navigation } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "./Product.css";
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import "swiper/css/thumbs";

// //Product components
// import ProductSlider from "./components/Slider";
// import ProductContent from "./components/Content";
// import ProductCart from "./components/Cart";
// import ProductTab from "./components/Tab";
// //Common component
// import ProductCard from "../shared/Product/Card";
// import api from "../../utils/api";
// import { useDispatch } from "react-redux";
// import { addToCart } from "../../redux/userCartSlice";
// import { toast } from "react-toastify";
// import SkeletonCard from "../shared/Skeleton/SkeletonCard";
// import SkeletonProductpage from "../shared/Skeleton/SkeletonProductDeatil";
// import { fetchWishlist } from "../../redux/productSlice";
// import { APIBASE, IMAGEURL } from "../../constants";


// import Review from "./components/Review";
// import { format } from "date-fns";
// import CustomerReviews from "./components/Review/CustomerReviews";

// export default function ProductDetail() {
//   const { slug } = useParams();
//   const dispatch = useDispatch();
//   const [product, setProduct] = useState([]);
//   const [relatedProducts, setRelatedProducts] = useState([]);
//   // const productUrl = `https://sn.quicsy.com/product-detail/${item}`;
//   // const productUrl = `${IMAGEURL}+${item}`;

//   //Add to cart function
//   const handleAddToCart = (item, qty, interval) => {
//     const newItem = {
//       id: item.id,
//       sku: item.sku,
//       name: item.product_name,
//       slug: item.slug,
//       old_price: item?.selling_price.toFixed(2) || 0,
//       price:
//         item?.discounted_price.toFixed(2) ?? item?.selling_price.toFixed(2),
//       discount: item?.discount_value || 0,
//       discount_type: item?.discount_type,
//       qty: qty,
//       unit: item?.unit,
//       category: item?.category,
//       weight: item.weight,
//       image: item.thumbnail,
//     };

//     if (interval > 0) {
//       const filteredSubscriptions = item.subscriptions.find(
//         (subscription) => subscription.months === parseInt(interval)
//       );
//       newItem.subscription = {
//         interval: interval,
//         type: "months",
//         discount: filteredSubscriptions,
//       };
//       if (filteredSubscriptions) {
//         newItem.price =
//           item?.selling_price -
//           (filteredSubscriptions?.discount / 100) * item?.selling_price;

//         newItem.discount = filteredSubscriptions?.discount || 0;
//         newItem.discount_type = filteredSubscriptions?.discount_type;
//       } else {
//         newItem.price = item?.selling_price;
//         newItem.discount = 0;
//         newItem.discount_type = "Percent";
//       }
//     }
//     dispatch(addToCart(newItem));
//   };

//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       setIsLoading(true);
//       setError(null);

//       try {
//         // fetch product by slug
//         const response = await api.get(`/product/${slug}`);
//         //fetch related products by category
//         const related = await api.get(
//           `categories/${response.data?.data?.category_id}/products?limit=10`
//         );
//         window.scrollTo({ top: 0, behavior: 'smooth' });
//         setRelatedProducts(related.data?.data);
//         setProduct(response.data?.data);
//         setIsLoading(false);
//         // // comsole.log(error);(related.data?.data);
//       } catch (error) {
//         setError(error.message);
//         setIsLoading(false);
//       }
//     };

//     fetchProduct();
//   }, [slug]);

//   if (isLoading) {
//     return <SkeletonProductpage />;
//   }

//   if (error) {
//     toast.error(error.message);
//   }

//   return (
//     <>
//       <section>
//         <div className="container-side-space">
//           <div className="product-details  ptb-5 ptb-md-3">
//             <div className="product-image">
//               <ProductSlider
//                 images={product?.images}
//                 thumbnail={product?.thumbnail}
//               />
//             </div>

//             <div className="product-detail-content">
//               {product && (
//                 <ProductContent
//                   product={product}
//                   onAddToCart={handleAddToCart}
//                 />
//               )}
//             </div>

//             <div className="product-detail-cart cart-for-desk">
//               {product && (
//                 <ProductCart product={product} onAddToCart={handleAddToCart} />
//               )}
//             </div>
//           </div>
//           <hr></hr>
//           <div className="product-tab pt-md-5 pb-md-5 pb-3 pt-3 row">
//             <div className="col-xl-12 col-lg-12 col-md-12">
//               <ProductTab
//                 description={product?.product_desc}
//                 additional={product?.other_desc}
//               />
//             </div>
//             {/* <div className="col-xl-5 col-lg-5 col-md-12">
//               <Review />
//            </div> */}
//           </div>
//           <hr></hr>
//           <div className="review-content pt-3 pt-md-5">
//             <h3>RATINGS & CUSTOMER REVIEWS</h3>

//             <div
//               className="rating-box"
//               style={{ justifyContent: "space-between" }}
//             >
//               <div className="w-50 w-sm-100">
//                 <CustomerReviews reviews={product?.reviews} />
//               </div>
//               <div className="w-50 w-sm-100">
//                 <div className="scrollbar" id="customscroll">
//                   <div
//                     className="force-overflow"
//                     style={{ paddingRight: "28px" }}
//                   >
//                     {product?.reviews &&
//                       product?.reviews.map((review, index) => (
//                         <div key={index}>
//                           <div className="rating-inner">
//                             <p className="rating-title">
//                               <span style={{ marginRight: "15px" }}>
//                                 {review.name}{" "}
//                               </span>
//                               {format(
//                                 new Date(review.created_at),
//                                 "d MMM yyyy"
//                               )}
//                             </p>
//                             <p className="rating-para">
//                               <span className="customer-rating">
//                                 {review.star}
//                                 <i
//                                   className="fa fa-star orange-color ml-1"
//                                   aria-hidden="true"
//                                 ></i>
//                               </span>
//                               &nbsp; &nbsp; {review.headline}
//                             </p>
//                           </div>
//                         </div>
//                       ))}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div>{/* <Review reviews={product.reviews} item={product} /> */}</div>
//       </section>
//       {/* <div>
//         <Review reviews={item?.reviews} item={item} />
//       </div> */}
//       {/* <Review /> */}
//       <section className="pt-sm-5 pb-sm-5 pt-3 pb-3">
//         <div className="container-side-space">
//           <div className="heading">
//             <h3>CUSTOMERS ALSO BROUGHT</h3>
//           </div>

//           <Swiper
//             slidesPerView={6}
//             spaceBetween={15}
//             // autoplay={{
//             //   delay: 2500,
//             //   disableOnInteraction: false,
//             // }}
//             pagination={{
//               clickable: true,
//             }}
//             breakpoints={{
//               320: {
//                 slidesPerView: 2,
//               },
//               600: {
//                 slidesPerView: 2,
//                 spaceBetween: 10,
//               },
//               680: {
//                 slidesPerView: 3,
//                 spaceBetween: 10,
//               },
//               1024: {
//                 slidesPerView: 5,
//                 spaceBetween: 15,
//               },
//               1440: {
//                 slidesPerView: 5,
//                 spaceBetween: 15,
//               },
//               1500: {
//                 slidesPerView: 6,
//                 spaceBetween: 15,
//               },
//             }}
//             navigation={false}
//             modules={[Autoplay, Pagination, Navigation]}
//             className="productSwiper pt-3"
//           >
//             {relatedProducts?.length > 0
//               ? relatedProducts?.map((product, index) => (
//                 <SwiperSlide key={index}>
//                   <ProductCard
//                     product={product}
//                     onAddToCart={handleAddToCart}
//                   />
//                 </SwiperSlide>
//               ))
//               : Array.from({ length: 6 }).map((_, index) => (
//                 <SwiperSlide key={index}>
//                   <SkeletonCard />
//                 </SwiperSlide>
//               ))}
//           </Swiper>
//         </div>
//       </section>
//     </>
//   );
// }
