import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BiRefresh } from "react-icons/bi";
import { APIBASE } from "../../../../constants";
const initialState = {
  product_id: "",
  name: "",
  email: "",
  star: "",
  headline: "",
  review: "",
};
function ReviewNew({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);
  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [error,setError]=useState(false)
  
  const generateCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6; // You can adjust the length of the captcha here

    let captchaValue = "";
    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      captchaValue += characters[randomIndex];
    }

    setCaptcha(captchaValue); // Set the generated captcha
  };
  useEffect(() => {
    generateCaptcha();
  }, []);
  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setState({ ...state, product_id: item.id });
  }, [isOpen]);

  const handleSubmit = async () => {
    if (
      state.name == "" ||
      state.review == "" ||
      state.email == "" ||
      state.star == ""
    ) {
      toast.warn("Make sure you are providing Stars, Name, Email and Review.", {
        position: "top-center",
      });

      closeModal();
    } else {
      try {
        if(captcha==enteredCaptcha){
          await axios.post(`${APIBASE}reviews`, state);
          toast.error("Product review added successfully.");
          window.location.reload();
        }else{
          setError(true)
        }
      
      } catch (error) {
        toast.error(error.response.data.message);
        window.location.reload();
      }
    }
  };
useEffect(()=>{
  if(error){
    setTimeout(()=>{
      setError(false)
    },5000)
  }
},[error])
  return (
    <>
      <Link
        className="post-review"
        data-toggle="modal"
        data-target="#Productreview"
        onClick={openModal}
      >
        Post Your Product Review
      </Link>

      {isOpen && (
        <div
          className="modal fade"
          id="Productreview"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title modal-heading">
                  Post Your Product Review
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <div>
                    <div className="p-0 col-md-12 ">
                      <div className="rate-box">
                        <h4 className="text-left">Overall rating </h4>

                        <div className="star-rating mb-3">
                          <input
                            type="radio"
                            id="5-stars"
                            name="rating"
                            value="5"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label for="5-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="4-stars"
                            name="rating"
                            value="4"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label for="4-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="3-stars"
                            name="rating"
                            value="3"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label for="3-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="2-stars"
                            name="rating"
                            value="2"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label for="2-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="1-star"
                            name="rating"
                            value="1"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label for="1-star" className="star">
                            ★
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required=""
                          value={state.name}
                          onChange={(e) =>
                            setState({ ...state, name: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required=""
                          value={state.email}
                          onChange={(e) =>
                            setState({ ...state, email: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="headline"
                          className="form-control"
                          placeholder="Add a headline"
                          required=""
                          value={state.headline}
                          onChange={(e) =>
                            setState({ ...state, headline: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="review"
                          placeholder="Write your review"
                          value={state.review}
                          onChange={(e) =>
                            setState({ ...state, review: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        value={enteredCaptcha}
                        onChange={(e)=>setEnteredCaptcha(e.target.value)}
                        class="form-control"
                        placeholder="Enter Captcha"
                      />
                      <div class="input-group-append">
                        <button class="btn btn-secondary">{captcha}</button>
                        <button
                          class="btn btn-primary"
                          type="button"
                          onClick={() => generateCaptcha()}
                        >
                          <BiRefresh style={{ fontSize: "28px" }} />
                        </button>
                      </div>
                    </div>
                    {error && <span className="text-danger text-start">Entered Captcha is wrong!</span>}
                    {/* <input
                      type="hidden"
                      name="product_id"
                      id="product_id"
                      value="345"
                    /> */}

                    <div className="p-0 col-md-12 mt-3 text-center">
                      <button
                        type="submit"
                        name="submit"
                        value="Submit"
                        className="btn btn-theme"
                        disabled={
                          state.name == "" ||
                          state.review == "" ||
                          state.email == "" ||
                          state.star == ""
                        }
                        onClick={() => handleSubmit()}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReviewNew;
