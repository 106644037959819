import React from 'react'

const SkeletonProductpage = () => {
  return (
    <div className='container-side-space'>
      <div className='product-detail  ptb-5 ptb-md-3'>
        <div className='slider-bg'>
        </div>
        <div className='product-detail-content'>
         <p className='para-bg heading-32'></p>
         <p className='para-bg'></p>
         <p className='para-bg'></p>
         <p className='btn-bg w-50'></p>
         <p className='para-bg'></p>
         <p className='para-bg'></p>
         <p className='para-bg'></p>
         <div className="btn-bg w-50"></div>
         <p className='para-bg'></p>
         <p className='para-bg heading-32'></p>
        </div>
        <div className='product-detail-cart d-md-block d-none'>
            <div className='cart-bg'>
              <p className='para-bg heading-60'></p>
            
              <p className='btn-bg w-100'></p>
              <p className='btn-bg w-100'></p>
               <p className='para-bg heading-60'></p>
            </div>
        </div>


      </div>
    </div>
  )
}

export default SkeletonProductpage
