import React, { useState, useEffect } from "react";
import { AiTwotoneStar } from "react-icons/ai";
import Moment from "react-moment";
import authApi from "../../../../../utils/authApi";
import { IMAGEURL } from "../../../../../constants";
import { Link } from "react-router-dom";

const ReviewCard = () => {
  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    authApi
      .get("/customer/get-user-review")
      .then((response) => {
        setReviewData(response.data["Customer Reviews"]);
        // comsole.log(error);("REVIEW DATA", response.data["Customer Reviews"]);
        // comsole.log(error);("REVIEW DATA", response);
      })
      .catch((error) => {
        console.error("Error fetching review data:", error);
      });
  }, []);

  return (
    <>
      {reviewData?.length === 0 ? (
        <p style={{ marginLeft: "1rem" }}>No reviews available</p>
      ) : (
        reviewData.map((review) => {
          const review_id = review.id; //
          return (
            <div
              className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-4"
              key={review_id}
            >
              <Link to={`/dashboard/edit-review/${review_id}`}>
                <div className="review-card p-3 mb-4">
                  <div className="review-img">
                    <img
                      src={review?.thumbnail_name ?
                        `${IMAGEURL}upload/product/thumbnail/100/${review.thumbnail}` :
                        `${IMAGEURL}${review.thumbnail}`}
                      alt="Product"
                    />
                  </div>

                  <div className="review-card-text pt-3 text-center">
                    <p className="line-clamp">{review.product_name}</p>
                    <div className="Ratings">
                      {Array.from({ length: review.star }).map((_, index) => (
                        <AiTwotoneStar key={index} />
                      ))}
                    </div>
                    <p>
                      <Moment format="DD-MM-YYYY">{review.created_at}</Moment>
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          );
        })
      )}
    </>
  );
};

export default ReviewCard;

// import React, { useState, useEffect } from "react";
// import { AiTwotoneStar } from "react-icons/ai";
// import Moment from "react-moment";
// import authApi from "../../../../../utils/authApi";
// import { IMAGEURL } from "../../../../../constants";
// import { Link } from "react-router-dom";
// const ReviewCard = () => {
//   const [reviewData, setReviewData] = useState([]);

//   useEffect(() => {
//     authApi
//       .get("/customer/get-user-review")
//       .then((response) => {
//         setReviewData(response.data["Customer Reviews"]);
//         // comsole.log(error);("REVIEW DATA", response.data["Customer Reviews"]);
//         // comsole.log(error);("REVIEW DATA", response);
//       })
//       .catch((error) => {
//         console.error("Error fetching review data:", error);
//       });
//   }, []);
//   // // comsole.log(error);("reviewData",reviewData);
//   return (
//     <>
//       {reviewData?.length === 0 ? (
//         <p style={{ marginLeft: "1rem" }}>No reviews available</p>
//       ) : (
//         reviewData.map((review) => (
//           <div
//             className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-4"
//             key={review.id}

//           ><Link to="">
//               <div className="review-card p-3 mb-4">
//                 <div className="review-img">
//                   {/* <img
//                   src={`${IMAGEURL}${review.thumbnail}`}
//                   alt="Product"
//                 /> */}
//                   <img
//                     src={review?.thumbnail_name ?
//                       `upload/product/thumbnail/100/${review.thumbnail}` :
//                       `${IMAGEURL}${review.thumbnail}`}
//                     alt="Product"
//                   />

//                 </div>

//                 <div className="review-card-text pt-3 text-center">
//                   <p className="line-clamp">{review.product_name}</p>
//                   <div className="Ratings">
//                     {Array.from({ length: review.star }).map((_, index) => (
//                       <AiTwotoneStar key={index} />
//                     ))}
//                   </div>
//                   <p>
//                     {/* Use Moment component for formatting date */}
//                     <Moment format="DD-MM-YYYY">{review.created_at}</Moment>
//                   </p>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         ))
//       )}
//     </>
//   );
// };

// export default ReviewCard;


// import React, { useState, useEffect } from "react";
// import { AiTwotoneStar } from "react-icons/ai";
// import Moment from "react-moment";
// import authApi from "../../../../../utils/authApi";
// import { IMAGEURL } from "../../../../../constants";

// const ReviewCard = () => {
//   const [reviewData, setReviewData] = useState([]);

//   useEffect(() => {
//     authApi
//       .get("/customer/get-user-review")
//       .then((response) => {
//         setReviewData(response.data["Customer Reviews"]);
//         // // comsole.log(error);("REVIEW DATA", response);
//       })
//       .catch((error) => {
//         console.error("Error fetching review data:", error);
//       });
//   }, []);

//   return (
//     <>
//       {reviewData.map((review) => (
//         <div
//           className="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-xs-4"
//           key={review.id}
//         >
//           <div className="review-card p-3 mb-4">
//             <div className="review-img">
//               <img
//                 src={`${IMAGEURL}${review.thumbnail}`}
//                 alt="Product"
//               />
//             </div>

//             <div className="review-card-text pt-3 text-center">
//               <p className="line-clamp">{review.product_name}</p>
//               <div className="Ratings">
//                 {Array.from({ length: review.star }).map((_, index) => (
//                   <AiTwotoneStar key={index} />
//                 ))}
//               </div>
//               <p>
//                 {/* Use Moment component for formatting date */}
//                 <Moment format="DD-MM-YYYY">{review.created_at}</Moment>
//               </p>
//             </div>
//           </div>
//         </div>
//       ))}
//     </>
//   );
// };

// export default ReviewCard;

// import React from "react";
// import ProductImage from "../../../../assets/product-image.jpg";
// import { AiTwotoneStar } from "react-icons/ai";

// const ReviewCard = () => {
//   return (
//     <>
//       <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
//         <div className="review-card p-3 mb-4">
//           <div className="review-img">
//             <img src={ProductImage} alt="Product" />
//           </div>

//           <div className="review-card-text pt-3 text-center">
//             <p className="line-clamp">MultiVitamin Power Serum </p>
//             <div className="Ratings">
//               <AiTwotoneStar />
//               <AiTwotoneStar />
//               <AiTwotoneStar />
//               <AiTwotoneStar />
//               <AiTwotoneStar />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default ReviewCard;
