import React from "react";
import { Link } from "react-router-dom";
import BlogImg from "../../assets/Contact-us.jpg";


const BlogDetails = () => {
  const bannerStyle = {
    backgroundImage: `url(${BlogImg})`,
  };

  return (
    <>
      <section
        className="p-20 first-section bg-gray blog-detail-banner"
        style={bannerStyle}
      >
        <div className="container ">
          <div className="row">
            <div className="col-md-12 m-auto">
              <nav
                aria-label="breadcrumb"
                className="w-100 d-sm-flex d-block justify-content-center align-items-center h-60vh w-100"
              >
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item">
                    <Link to="" className="text-blue">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    TROUSER
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    New Blog
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb-5">
        <div className="container-side-space">
          <div className="blog-details-container">
            <div className="blog-details blog-detail-content">
              <p>JULY 08, 2022</p>
              <h3>
                 Hair Restoration Response To Will & Jada Smith & Chris Rock Award Show Drama
              </h3>

              <img src={BlogImg} alt="blog" className="img-fluid my-2 w-100%" />

              <p>
                Northshore Medical Center is highlighting Salon B and its hair
                restoration offerings, including the treatment of alopecia. A
                spokesperson said, “We can learn many lessons from this
                weekend’s award events with actors Will and Jada Smith and
                comedian Chris Rock reactions, but for us at Northshore Medical
                Center. we have partnered with Dr. Brown’s Laboratories to
                ensure Bermuda residents and visitors know that we have a full
                Hair & Scalp Clinic with hair restoration product line on-island
                to support and treat your alopecia and other hair loss
                conditions. There is no need to have alopecia effect your life
                and for you to suffer in silence.
              </p>

              <p>
                Northshore Medical Center is highlighting Salon B and its hair
                restoration offerings, including the treatment of alopecia. A
                spokesperson said, “We can learn many lessons from this
                weekend’s award events with actors Will and Jada Smith and
                comedian Chris Rock reactions, but for us at Northshore Medical
                Center. we have partnered with Dr. Brown’s Laboratories to
                ensure Bermuda residents and visitors know that we have a full
                Hair & Scalp Clinic with hair restoration product line on-island
                to support and treat your alopecia and other hair loss
                conditions. There is no need to have alopecia effect your life
                and for you to suffer in silence.
              </p>

              <p>
                Northshore Medical Center is highlighting Salon B and its hair
                restoration offerings, including the treatment of alopecia. A
                spokesperson said, “We can learn many lessons from this
                weekend’s award events with actors Will and Jada Smith and
                comedian Chris Rock reactions, but for us at Northshore Medical
                Center. we have partnered with Dr. Brown’s Laboratories to
                ensure Bermuda residents and visitors know that we have a full
                Hair & Scalp Clinic with hair restoration product line on-island
                to support and treat your alopecia and other hair loss
                conditions. There is no need to have alopecia effect your life
                and for you to suffer in silence.
              </p>
            </div>

            <div className="blog-sidebar blog-details">
              <div className="heading">
                <h3>Recent Blog</h3>
              </div>

              <div className="side-blog">
                <div className="blog-card">
                  <Link to="/blog-details" className="d-flex justify-content-between align-items-start">
                    <div className="sideblog-img">
                      <img src={BlogImg} className="img-fluid" alt="blog" />
                    </div>
                    <div className="blog-content px-3">
                      <p className="mb-0">Tips & Trick</p>
                      <h3 className="line-clamp-2 mb-0">
                      Hair Restoration Response To Will & Jada Smith & Chris Rock Award Show Drama
                      </h3>
                      <p className="mb-0">May 26, 2022 </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
