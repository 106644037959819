
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// import ReactPaginate from "react-paginate";
// import { useLocation, useParams } from "react-router-dom";
// import { upperFirst } from "lodash";
import { addToCart } from "../../redux/userCartSlice";

import "../Shop/Shop.css";
//common component
import ProductCard from "../shared/Product/Card";
// import { toast } from "react-toastify";
// import SkeletonCard from "../shared/Skeleton/SkeletonCard";
import api from "../../utils/api";
import SkeletonCard from "../shared/Skeleton/SkeletonCard";

const Deals = () => {
    const dispatch = useDispatch();
    const [dealProducts, setDealProducts] = useState([]);
    const [isloading, setIsloading] = useState(true);

    //Add to cart function
    const handleAddToCart = (item) => {
        const newItem = {
            id: item.id,
            sku: item.sku,
            name: item.product_name,
            slug: item.slug,
            // price: item?.selling_price || 0,
            old_price: item?.selling_price || 0,
            avl_qty: +item?.quantity- +item?.quantity_used,
            price: item?.discounted_price ?? item?.selling_price,
            discount: item?.discount_value || 0,
            discount_type: item?.discount_type,
            qty: 1,
            unit: item?.unit,
            category: item?.category,
            weight: item.weight,
            image: item.thumbnail,
            thumbnail_comprees: item?.thumbnail_comprees,
        };
        dispatch(addToCart(newItem));
        // toast.success("Product added to cart!", {
        //   autoClose: 500,
        //   position: toast.POSITION.TOP_RIGHT,
        // });
    };

    const fetchTodaysDeal = async () => {
        try {
            const response = await api.get("/todays-deals");
            setDealProducts(response?.data?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchTodaysDeal();
    }, []);

    // comsole.log(error);("dealProducts", dealProducts);

    if (isloading) {
        return (
            <div className="container-side-space">
                <div className="shop-product-list ptb-5 ptb-md-3">
                    {Array.from({ length: 18 }).map((_, index) => (
                        <div key={index}>
                            <SkeletonCard />
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <section>
            <div className="container-side-space">
                <div className="shop ptb-5 ptb-md-3">
                    <div className="shop-filter">
                        <div className="show-result">
                            <p>
                                Showing Best Deals Items
                            </p>
                        </div>


                    </div>
                    <div className="shop-product-list pt-3">
                        {dealProducts[0]?.products.map((product, index) => (
                            <ProductCard
                                key={index}
                                product={product}
                                onAddToCart={handleAddToCart}
                            />
                        ))}
                    </div>
                    {/* <ReactPaginate
                        previousLabel={"<<"}
                        nextLabel={">>"}
                        breakLabel={<span className="ellipsis">...</span>}
                        pageCount={totalPages || 0}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageChange}
                        forcePage={currentPage - 1}
                        containerClassName="pagination justify-content-center pt-5"
                        activeClassName="active"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                    /> */}
                </div>
            </div>
        </section>
    );
};

export default Deals;
