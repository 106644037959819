import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../utils/api";

// Async thunks for fetching coupon discount, subscription discount, and shipping charges
// ... (code remains the same as before)
// Async thunk for calculating shipping charges
export const calculateShippingCharge = createAsyncThunk(
  "checkout/calculateShippingCharge",
  async ({ country, totalAmount }, thunkAPI) => {
    try {
      // Make the API call to get the shipping charges
      const response = await api.get(
        `shipping-charge/${country}/${totalAmount}`
      );

      // Return the received shippingCharge value
      return response?.data;
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error fetching shipping charges:", error);
      throw error;
    }
  }
);

// Async thunk for coupon discount
export const fetchCouponDiscount = createAsyncThunk(
  "checkout/fetchCouponDiscount",
  async ({ couponCode, amount }) => {
    try {
      const response = await api.get(`coupon-discount/${couponCode}/${amount}`);
      // // comsole.log(error);(response.data);
      return response?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

// Async thunk for tax
export const fetchcalculatedTax = createAsyncThunk(
  "checkout/fetchcalculatedTax",
  async ({ amount }) => {
    try {
      const response = await api.get(`calculate-tax/${amount}`);
      // // comsole.log(error);(response.data);
      return response?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
);

const checkoutSlice = createSlice({
  name: "checkout",
  initialState: {
    shippingAddress: null,
    billingAddress: null,
    pickupOrder: false,
    pickupAddress: null,
    couponDiscount: null,
    subscriptionDiscount: null,
    taxAmount: 0,
    shippingCharge: 0,
    // cardDetails: null, // Added field for card details
    loading: false,
    error: null,
  },
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    updateShippingAddress: (state, action) => {
      state.shippingAddress = action.payload;
    },
    updateBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    updatePickupAddress: (state, action) => {
      state.pickupOrder = !state.pickupOrder;
      state.pickupAddress = action.payload;
    },
    updateCardDetails: (state, action) => {
      // Added action to update card details
      state.cardDetails = action.payload;
    },
    clearCardDetails: (state) => {
      // Added action to clear card details
      state.cardDetails = null;
    },
    removeCoupon: (state) => {
      state.couponDiscount = null;
    },
    clearCheckout: (state) => {
      state.shippingAddress = null;
      state.billingAddress = null;
      state.pickupOrder = false;
      state.pickupAddress = null;
      state.couponDiscount = null;
      state.subscriptionDiscount = null;
      state.taxAmount = 0;
      state.shippingCharge = 0;
      // state.cardDetails = null; // Added field for card details
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // ... (code remains the same as before)
    // Handle the fulfilled state of calculateShippingCharge
    builder
      .addCase(calculateShippingCharge.fulfilled, (state, action) => {
        state.shippingCharge = parseFloat(action.payload.amount);
      })
      .addCase(fetchCouponDiscount.fulfilled, (state, action) => {
        state.couponDiscount = action.payload;
      })
      .addCase(fetchcalculatedTax.fulfilled, (state, action) => {
        state.taxAmount = parseFloat(action.payload.tax_amount);
      });
  },
});

export const {
  addItem,
  removeItem,
  updateShippingAddress,
  updateBillingAddress,
  updatePickupAddress,
  updateCardDetails, // Added action creator for card details
  clearCardDetails, // Added action creator to clear card details
  removeCoupon,
  clearCheckout,
  applySubscriptionDiscount,
} = checkoutSlice.actions;

export default checkoutSlice.reducer;
