import ProductImage from "../../assets/product-image.jpg";
import { IMAGEURL } from "../../constants";

export default function CheckoutProduct({
  items,
  totalCount,
  subTotal,
  couponDiscount,
  // shippingCharge,
  // taxAmount,
}) {
  var total =
    parseFloat(subTotal)
  // -
  // parseFloat(couponDiscount) +
  // parseFloat(shippingCharge);
  // // comsole.log(error);("item", items);
  return (
    <>
      <div className="table-responsive">
        <table
          className="table table-borderd table-border border-1"
          width="100%"
        >
          <thead>
            <tr className="bg-dark text-white">
              <th>Product</th>
              <th>Price ($)</th>
              <th>Quantity</th>
              <th className="text-right">Total($)</th>
            </tr>
          </thead>
          <tbody>
            {items?.length === 0 ? (
              <tr>
                <td colSpan={5}>Your cart is empty.</td>
              </tr>
            ) : (
              <>
                {items?.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div
                        className="d-flex align-items-center w-small-100"
                        style={{ gap: "12px" }}
                      >
                        {/* <img
                          src={item.image || ProductImage}
                          alt="Product"
                          className="w-15" style={{ border: '1px solid #33333326' }}
                        /> */}
                        <img
                          src={
                            item?.image
                              ? `${IMAGEURL}${item?.image}`
                              : ProductImage
                          }
                          alt={item.name}
                          className="w-15"
                          style={{
                            border: "1px solid #33333326",
                            height: "55px",
                            width: "auto",
                          }}
                        />
                        <span>
                          {item.name ? item.name : item.slug} <br /> Item Code :{" "}
                          <b>{item.sku}</b> <br /> {item?.category}{" "}
                        </span>
                      </div>
                    </td>
                    <td>
                      {item.price}(
                      {-item?.subscription?.discount?.discount ||
                        item?.discount}
                      %)
                      <br />{" "}
                      {item.subscription?.interval &&
                        item?.subscription?.interval + " month"}{" "}
                    </td>
                    <td>{item.qty}</td>
                    <td className="text-right">
                      US ${(item.price * item.qty).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </>
            )}

            <tr className="bg-light">
              <td
                colSpan="3"
                className="text-right fa-18x fa-mdd-16 fa-sm-14 theme-text-color"
              >
                <b>Cart Total({totalCount} items)</b>
              </td>
              <td className="text-right">US ${subTotal}</td>
            </tr>

            {couponDiscount > 0 && (
              <tr className="bg-light">
                <td
                  colSpan="3"
                  className="text-right fa-18x fa-mdd-16 fa-sm-14 theme-text-color"
                >
                  <b>Coupon Discount</b>
                </td>
                <td className="text-right">US ${couponDiscount.toFixed(2)}</td>
              </tr>
            )}

            {/* <tr className="bg-light">
              <td
                colSpan="3"
                className="text-right fa-18x fa-mdd-16 fa-sm-14 theme-text-color"
              >
                <b>Shipping Charge</b>
              </td>
              <td className="text-right">US ${shippingCharge}</td>
            </tr> */}

            <tr className="bg-light">
              <td
                colSpan="3"
                className="text-right fa-18x fa-mdd-16 fa-sm-14 theme-text-color"
              >
                <b>Total Pay</b>
              </td>

              <td className="text-right">
                <b>US ${total?.toFixed(2)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
