import React from "react";
import { Link } from 'react-router-dom';
import { CiCirclePlus } from 'react-icons/ci';

const AddNewSubscribeProduct = () => {
  return (
    <>
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
        <div className="new-subsciption ">
          <Link to="/shop">
            <CiCirclePlus className="CiCirclePlus mb-2" />
            <p>Add new subscriptions</p>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AddNewSubscribeProduct;
