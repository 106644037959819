import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./MyProfile.css";
import authApi from "../../../../utils/authApi";
import countries from "./Countries";
const MyProfile = () => {
  // const [userData, setUserData] = useState({});
  const [userData, setUserData] = useState(null);
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // comsole.log(error);("Fetching user data...");
    authApi
      .get("/customer/get-user")
      .then((response) => {
        // comsole.log(error);("User data fetched:", response.data.data);
        setUserData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setUpdatedUserData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "dob") {
      // Convert date to ISO string
      const isoDate = new Date(value).toISOString().split("T")[0];
      setUpdatedUserData((prevData) => ({
        ...prevData,
        [name]: isoDate,
      }));
    } else {
      setUpdatedUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await authApi.put(
        "/customer/update-user",
        updatedUserData
      );

      if (response?.status === 200) {
        // Show success toast
        toast.success("User data updated successfully!");
      } else {
        // Show failure toast
        toast.error("Failed to update user data");
      }
    } catch (error) {
      console.error("Error updating user data:", error);
      // Handle error scenarios here
      toast.error("An error occurred while updating user data");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading py-2">
            <h3>My Edit Profile</h3>
          </div>
          {/* Divider Below heading is here */}
          <div className="divider" />

          <div className="profile-form py-4">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      className="form-control"
                      placeholder="Enter First Name"
                      value={updatedUserData.first_name || userData.first_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Middle Name</label>
                    <input
                      type="text"
                      name="middle_name"
                      className="form-control"
                      placeholder="Middle Name"
                      value={
                        updatedUserData.middle_name || userData.middle_name
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      placeholder="Last Name"
                      value={updatedUserData.last_name || userData.last_name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Cell Phone</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Cell Phone"
                      maxLength="13"
                      value={updatedUserData?.phone || userData?.phone}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Work Phone</label>
                    <input
                      type="text"
                      name="work_phone"
                      className="form-control"
                      placeholder="Work Phone"
                      value={updatedUserData?.work_phone || userData?.work_phone}
                      maxLength="13"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Home Phone</label>
                    <input
                      type="text"
                      name="home_phone"
                      className="form-control"
                      placeholder="Home Phone"
                      value={updatedUserData.home_phone || userData.home_phone}
                      maxLength="13"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Email"
                      value={updatedUserData.emai || userData.email}
                      onChange={handleInputChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      name="gender"
                      className="form-control"
                      value={updatedUserData.gender || userData.gender}
                      onChange={handleInputChange}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Date of Birth</label>
                    <input
                      type="date"
                      name="dob"
                      className="form-control"
                      value={
                        updatedUserData.dob || userData.dob
                          ? new Date(updatedUserData.dob || userData.dob)
                            .toISOString()
                            .split("T")[0]
                          : ""
                      }
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Address Line 1</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Address Line 1"
                      value={updatedUserData?.address || userData?.address}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Address Line 2</label>
                    <input
                      type="text"
                      name="street"
                      className="form-control"
                      placeholder="Address Line 2"
                      value={updatedUserData?.street || userData?.street}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Apartment/Flat</label>
                    <input
                      type="text"
                      name="flat_apartment"
                      className="form-control"
                      placeholder="Apartment/Flat"
                      value={updatedUserData?.flat_apartment || userData?.flat_apartment}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>



                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>City / Parish</label>
                    <input
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="City / Parish"
                      value={updatedUserData.city || userData.city}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Postal Code</label>
                    <input
                      type="text"
                      name="zipcode"
                      className="form-control"
                      placeholder="Postal Code"
                      value={updatedUserData.zipcode || userData.zipcode}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder="State"
                      value={updatedUserData.state || userData.state}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      name="country"
                      className="form-control"
                      value={updatedUserData.country || userData.country}
                      onChange={handleInputChange}
                    >
                      {countries.map((country) => (
                        <option key={country.label} value={country.label}>
                          {country.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-12 text-center">
                  <button type="submit" className="btn theme-btn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;


// import React from "react";

// import "./MyProfile.css";

// const MyProfile = () => {
//   return (
//     <>
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading py-2">
//             <h3>My Edit Profile</h3>
//           </div>
//           {/* Divider Below heading is here */}
//           <div className="divider" />

//           <div className="profile-form py-4">
//             <form >
//               <div className="row">
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>First Name</label>
//                     <input
//                       type="text"
//                       name="first_name"
//                       className="form-control"
//                       placeholder="Enter First Name"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Middle Name</label>
//                     <input
//                       type="text"
//                       name="middle_name"
//                       className="form-control"
//                       placeholder="Middle Name"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Last Name</label>
//                     <input
//                       type="text"
//                       name="last_name"
//                       className="form-control"
//                       placeholder="Last Name"

//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Cell Phone</label>
//                     <input
//                       type="text"
//                       name="phone"
//                       className="form-control"
//                       placeholder="Cell Phone"
//                       maxLength="13"
//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Work Phone</label>
//                     <input
//                       type="text"
//                       name="work_phone"
//                       className="form-control"
//                       placeholder="Work Phone"

//                       maxLength="13"
//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Home Phone</label>
//                     <input
//                       type="text"
//                       name="home_phone"
//                       className="form-control"
//                       placeholder="Home Phone"

//                       maxLength="13"
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Email</label>
//                     <input
//                       type="text"
//                       name="email"
//                       className="form-control"
//                       placeholder="Email"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Gender</label>
//                     <select
//                       name="gender"
//                       className="form-control"

//                     >
//                       <option value="Male">Male</option>
//                       <option value="Female">Female</option>
//                     </select>
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Date of Birth</label>
//                     <input
//                       type="date"
//                       name="dob"
//                       className="form-control"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Address Line 1</label>
//                     <input
//                       type="text"
//                       name="address"
//                       className="form-control"
//                       placeholder="Address Line 1"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Address Line 2</label>
//                     <input
//                       type="text"
//                       name="address_2"
//                       className="form-control"
//                       placeholder="Address Line 2"

//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Apartment/Flat</label>
//                     <input
//                       type="text"
//                       name="appartment_flat"
//                       className="form-control"
//                       placeholder="Apartment/Flat"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>City / Parish</label>
//                     <input
//                       type="text"
//                       name="city"
//                       className="form-control"
//                       placeholder="City / Parish"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Postal Code</label>
//                     <input
//                       type="text"
//                       name="zipcode"
//                       className="form-control"
//                       placeholder="Postal Code"

//                     />
//                   </div>
//                 </div>
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>State</label>
//                     <input
//                       type="text"
//                       name="state"
//                       className="form-control"
//                       placeholder="State"

//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>Country</label>
//                     <select
//                       name="country"
//                       className="form-control"

//                     >
//                       <option value="Bermuda">Bermuda</option>
//                       {/* Add other country options */}
//                     </select>
//                   </div>
//                 </div>

//               <div className="col-12 text-center">
//                 <button type="submit" className="btn theme-btn">
//                     Submit
//                 </button>
//               </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default MyProfile;