import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useLocation, useParams } from "react-router-dom";
import { upperFirst } from "lodash";
import {
  fetchProducts,
  // fetchProductsByCategory,
} from "../../redux/productSlice";
import { addToCart } from "../../redux/userCartSlice";

import "./Shop.css";
//common component
import ProductCard from "../shared/Product/Card";
// import { toast } from "react-toastify";
import SkeletonCard from "../shared/Skeleton/SkeletonCard";

const Shop = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search") ?? "";

  const dispatch = useDispatch();
  const {
    products,
    currentPage,
    indexFrom,
    indexTo,
    totalPages,
    totalItems,
    loading,
    error,
  } = useSelector((state) => state.products);

  const { category, brand } = useParams();
  const [sort, setSort] = useState("asc");

  //set sorting parameter
  const handleChange = (event) => {
    setSort(event.target?.value);
  };

  //Fetch product on page number change
  const handlePageChange = (selectedPage) => {
    dispatch(
      fetchProducts({
        page: selectedPage.selected + 1,
        sort,
        category,
        brand,
        searchQuery,
      })
    );
  };

  //Add to cart function
  const handleAddToCart = (item) => {
    const newItem = {
      id: item.id,
      sku: item.sku,
      name: item.product_name,
      slug: item.slug,
      old_price: item?.selling_price || 0,
      price: item?.discounted_price ?? item?.selling_price,
      discount: item?.discount_value || 0,
      discount_type: item?.discount_type,
      avl_qty: +item?.quantity- +item?.quantity_used,
      qty: 1,
      unit: item?.unit,
      category: item?.category,
      weight: item.weight,
      image: item.thumbnail,
      thumbnail_compress: item?.thumbnail_compress,
    };
    dispatch(addToCart(newItem));
    // toast.success("Product added to cart!", {
    //   autoClose: 500,
    //   position: toast.POSITION.TOP_RIGHT,
    // });
  };

  useEffect(() => {
    dispatch(fetchProducts({ page: 1, sort, category, brand, searchQuery }));
  }, [dispatch, category, sort, brand, searchQuery]);

  if (loading) {
    return (
      <div className="container-side-space">
        <div className="shop-product-list ptb-5 ptb-md-3">
          {Array.from({ length: 18 }).map((_, index) => (
            <div key={index}>
              <SkeletonCard />
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        {error}
      </div>
    );
  }

  return (
    <section>
      <div className="container-side-space">
        <div className="shop ptb-5 ptb-md-3">
          <div className="shop-filter">
            <div className="show-result">
              <p>
                Showing <b>{upperFirst(category)}</b> {indexFrom}-{indexTo} of
                {totalItems} results
              </p>
            </div>

            <div className="price-filter">
              <select
                className="form-control"
                value={sort}
                onChange={handleChange}
              >
                {/* <option defaultValue="">Sort By Price</option> */}
                <option value="asc"> Price: Low to high</option>
                <option value="desc"> Price: High to low</option>
              </select>
            </div>
          </div>
          <div className="shop-product-list pt-3">
            {products?.map((product, index) => (
              <ProductCard
                key={index}
                product={product}
                onAddToCart={handleAddToCart}
              />
            ))}
          </div>
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={<span className="ellipsis">...</span>}
            pageCount={totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            forcePage={currentPage - 1}
            containerClassName="pagination justify-content-center pt-5"
            activeClassName="active"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          
          />
        </div>
      </div>
    </section>
  );
};

export default Shop;
