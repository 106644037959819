import React, { useState, useEffect } from "react";
import "./Order.css";
import OrdersComp from "./OrdersComp";
import authApi from "../../../../utils/authApi";
import { Link } from "react-router-dom";
const clickedButtonStyle = {
  fontWeight: "550",
  borderBottom: "3px solid #EF233C",
};

const Order = () => {
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const [showButtonResults, setShowButtonResults] = useState([
    true,
    false,
    false,
  ]);
  const [showButtonStyles, setShowButtonStyles] = useState([
    clickedButtonStyle,
    {},
    {},
  ]);

  useEffect(() => {
    // Axios GET request with authorization header
    authApi
      .get("customer/get-total-orders")
      .then((response) => {
        const totalValue = response.data["Total Orders"];
        setTotalOrderValue(totalValue);
        // // comsole.log(error);(totalValue);
      })
      .catch((error) => {
        console.error("Error fetching order value:", error);
      });
  }, []);

  // with this handler onClick button Style will change
  const orderButtonsHandler = (buttonText) => {
    if (buttonText === "Orders") {
      setShowButtonResults([true, false, false]);
      setShowButtonStyles([clickedButtonStyle, {}, {}]);
    }
    // if (buttonText === "Buy Again") {
    //   setShowButtonResults([false, true, false]);
    //   setShowButtonStyles([{}, clickedButtonStyle, {}]);
    // }
    // if (buttonText === "Open Orders") {
    //   setShowButtonResults([false, false, true]);
    //   setShowButtonStyles([{}, {}, clickedButtonStyle]);
    // }
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading py-2">
            <h3>My Orders</h3>
          </div>
          {/* Divider Below heading is here */}
          <div className="divider" />

          {/* Three buttons below divider is here */}
          <div className="py-4 d-flex d-flex justify-content-start align-items-center">
            {["Orders",
              // "Buy Again", "Open Orders"
            ].map((ele, index) => {
              return (
                <Link
                  to="#"
                  key={index}
                  onClick={(e) => orderButtonsHandler(ele)}
                  className="order-tabs"
                  style={showButtonStyles[index]}
                >
                  {ele}
                </Link>
              );
            })}
          </div>
          {/* Three buttons below divider ends here  */}

          {/* Divider Below buttons is here */}
          <div className="divider" />

          {/* select year below button starts here  */}
          <div className="row">
            <div className="col-lg-5 col-md-6">
              <div className="form-inline py-4">
                <label htmlFor="year">{totalOrderValue} Orders Placed</label>
                <select className="form-control ml-0 ml-sm-3" id="year">
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                </select>
              </div>
            </div>
          </div>
          {/* select year below button ends here  */}

          {/* On button click different layout strutures are here */}

          {/* order button click result */}
          {showButtonResults[0] && (
            <>
              <OrdersComp />
            </>
          )}

          {/* Buy Again button click result */}
          {/* {showButtonResults[1] && (
            <>
              <OrdersComp />
              <OrdersComp />
            </>
          )} */}

          {/* Open Orders button click result */}
          {/* {showButtonResults[2] && (
            <>
              <OrdersComp />
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Order;
