import React, { useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import "./Sidebar.css";
import "../../../assets/Member.css";
import { FaHome, FaBars } from "react-icons/fa";
import { BsBoxSeam } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { Si1Password } from "react-icons/si";
import { MdReviews } from "react-icons/md";
import { BsBagHeartFill } from "react-icons/bs";
import { AiOutlineLogout } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";

import UserImage from "../../../assets/user.jpg";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import { IMAGEURL } from "../../../constants";
import { logout } from "../../../redux/userAuthSlice";

const menulist = [
  {
    path: "/dashboard/order",
    name: "Orders",
    icon: <BsBoxSeam />,
  },
  // {
  //   path: "/dashboard/subscribed-products",
  //   name: "Subscribed Products",
  //   icon: <BsBoxSeam />,
  // },
  {
    path: "/dashboard/my-profile",
    name: "My Profile",
    icon: <FaRegUserCircle />,
  },
  {
    path: "/dashboard/change-password",
    name: "Change Password",
    icon: <Si1Password />,
  },
  {
    path: "/dashboard/review",
    name: "My Review & Ratings",
    icon: <MdReviews />,
  },
  {
    path: "/dashboard/wishlist",
    name: "My Wish List",
    icon: <BsBagHeartFill />,
  },
  // {
  //   path: "/",
  //   name: "Logout",
  //   icon: <AiOutlineLogout />,
  // },
];

function Sidebar({ children }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const userData = useSelector((state) => state.userAuth.user.data);
  const dispatch = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const authenticated = useSelector((state) => state.userAuth.isLoggedIn);

  if (!authenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" />;
  }

  const handleLogout = () => {
    dispatch(logout()); // Dispatch the logout action
    // You can redirect the user to the login page after logout
    // navigate("/login"); // Uncomment this line if you have a navigation function
  };

  return (
    <>
      <Header />
      <div className="pt-md-5 pt-3 pb-md-5 pb-3 member-bg">
        <div className="container-side-space ">
          <div className="member-wrapper ptb-6">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12">
                <div className="menubar mb-3">
                  <b>Menu</b>  <FaBars onClick={toggle} className="fabars" />
                </div>
                <div className={`sidebar ${isOpen ? "open" : ""}`}>
                  <div className="left-menu rounded">
                    <Link to="#" className="closebtn" onClick={toggle}>
                      <RxCross2 className="closeicon" />
                    </Link>
                    <div className="p-4 text-center mb-4 border-bottom bg-red text-white position-relative">
                      <span className="profile-img profile-md mb-3">
                        {userData?.thumbnail ? (
                          <img
                            src={`${IMAGEURL}${userData.thumbnail}`}
                            alt={userData.fullName}
                          />
                        ) : (
                          <div className="default-user-image">
                            <img src={UserImage} alt="Default User" />
                          </div>
                        )}
                      </span>
                      <h4 className="p-2 h5 fs-16 text-white m-0">
                        {userData?.first_name} {userData?.middle_name}{" "}
                        {userData?.last_name}
                      </h4>
                      <p className="m-0">{userData.phone}</p>
                    </div>
                    <div className="menu-list">
                      <h4>
                        <Link to="/dashboard" title="Dashboard" onClick={toggle}
                          style={{
                            gap: "10px",
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <FaHome /> Dashboard
                        </Link>
                      </h4>
                      <ul>
                        {menulist.map((menu, index) => (
                          <li
                            className={`mx-2 ${location.pathname === menu.path ? "active" : ""
                              }`}
                            key={index}
                          >
                            <Link to={menu.path} title={menu.name} onClick={toggle}>
                              <span className="mr-1">{menu.icon}</span>{" "}
                              {menu.name}
                            </Link>
                          </li>
                        ))}
                        <li className={`mx-2`} key={menulist.length}>
                          <Link to="#" title="Logout" onClick={handleLogout}>
                            <span className="mr-2"><AiOutlineLogout /></span>
                            Logout
                          </Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                <div className="right-side">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Sidebar;
