import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../../../../../utils/authApi";
import "./Createreview.css";
import { APIBASE, IMAGEURL } from "../../../../../constants";
import { useSelector } from "react-redux";

const EditReview = () => {
  const { review_id } = useParams();
  const user_id = useSelector((state) => state.userAuth.user.data.id);
  const [rating, setRating] = useState(0);
  const [formData, setFormData] = useState({
    headline: "",
    review: "",
  });
  const [productData, setProductData] = useState(null);

  const getReviewById = () => {
    authApi
      .get(`/customer/get-review-by-id/${review_id}`)
      .then((response) => {
        if (response.data["Review"]) {
          setRating(response.data["Review"].star);
          setFormData({
            headline: response.data["Review"].headline,
            review: response.data["Review"].review,
            product_id: response.data["Review"].product_id,
          });

          authApi
            .get(
              `${APIBASE}customer/get-product-by-id/${response.data["Review"].product_id}`
            )
            .then((productResponse) => {
              setProductData(productResponse.data);
            })
            .catch((error) => {
              console.error("Error fetching product data:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching existing review:", error);
      });
  };

  useEffect(() => {
    getReviewById();
  }, [review_id]);

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const reviewData = {
      headline: formData.headline,
      review: formData.review,
      star: parseInt(rating),
      user_id: user_id,
      product_id: formData.product_id,
    };

    try {
      let response;
      if (review_id) {
        response = await authApi.put(
          `${APIBASE}customer/update-review-by-id/${review_id}`,
          reviewData
        );
      } else {
        response = await authApi.post(`${APIBASE}customer/reviews`, reviewData);
      }

      if (response?.status === 201 || response?.status === 200) {
        toast.success("Review submitted successfully");
        setFormData({
          headline: "",
          review: "",
        });
        setRating(0);
        getReviewById();
      } else {
        toast.error("Failed to submit review");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("An error occurred while submitting the review");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading py-2">
            <h3>Edit Review</h3>
          </div>
          {/* Divider Below heading is here */}
          <div className="divider" />
          <div className="review-feedback">
            <div className="list-box d-flex flex-wrap py-3">
              <div className="review-product">
                {/* <img
                  // src={ProductImage}
                  src={`${IMAGEURL}${productData?.thumbnail}`}
                  alt="img"
                /> */}
                <img
                  src={
                    productData?.thumbnail_name
                      ? `${IMAGEURL}upload/product/thumbnail/100/${productData?.thumbnail}`
                      : `${IMAGEURL}${productData?.thumbnail}`
                  }
                  alt="img"
                  style={{ width: "50px" }}
                />
              </div>
              <div className="ml-2">
                <p>{productData?.product_name || "Product Name"}</p>
              </div>
            </div>
            <div className="divider" />
            <form
              method="post"
              onSubmit={handleSubmit}
              enctype="multipart/htmlform-data"
              className="py-4"
            >
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="rate-box">
                    <h4>
                      Overall rating <span className="text-danger">*</span>
                    </h4>

                    <div className="rate">
                      <input
                        type="radio"
                        id="star5"
                        name="rating"
                        value={5}
                        checked={rating === "5"}
                        onChange={handleRatingChange}
                      />
                      <label htmlFor="star5" title="text">
                        ★★★★★
                      </label>

                      <input
                        type="radio"
                        id="star4"
                        name="rating"
                        value={4}
                        checked={rating === "4"}
                        onChange={handleRatingChange}
                      />
                      <label htmlFor="star4" title="text">
                        ★★★★
                      </label>

                      <input
                        type="radio"
                        id="star3"
                        name="rating"
                        value={3}
                        checked={rating === "3"}
                        onChange={handleRatingChange}
                      />
                      <label htmlFor="star3" title="text">
                        ★★★
                      </label>

                      <input
                        type="radio"
                        id="star2"
                        name="rating"
                        value={2}
                        checked={rating === "2"}
                        onChange={handleRatingChange}
                      />
                      <label htmlFor="star2" title="text">
                        ★★
                      </label>

                      <input
                        type="radio"
                        id="star1"
                        name="rating"
                        value={1}
                        checked={rating === "1"}
                        onChange={handleRatingChange}
                      />
                      <label htmlFor="star1" title="text">
                        ★
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>
                      Add a headline <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="headline"
                      className="form-control"
                      required=""
                      value={formData.headline}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label>
                      Write your review <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="review"
                      required=""
                      value={formData.review}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="text-center mt-3">
                    <input
                      type="submit"
                      name="submit"
                      value="Submit"
                      className="btn theme-btn"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReview;

// import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import authApi from "../../../../../utils/authApi";
// import "./Createreview.css";
// import { APIBASE, IMAGEURL } from "../../../../../constants";

// const Createreview = () => {
//   const { id } = useParams();
//   const [rating, setRating] = useState(null);
//   const [formData, setFormData] = useState({
//     headline: "",
//     review: "",
//   });

//   const [productData, setProductData] = useState(null);
//   // comsole.log(error);("reviewing product id", id);

//   useEffect(() => {
//     // // comsole.log(error);("Fetching product review...");
//     authApi
//       .get(`${APIBASE}api/customer/get-review-by-id/${id}`)
//       .then((response) => {
//         setProductData(response.data["Review"]);
//         // comsole.log(error);("reviewing product", productData);
//       })
//       .catch((error) => {
//         console.error("Error fetching review data:", error);
//       });
//   }, [id]);

//   const handleRatingChange = (event) => {
//     setRating(event.target.value);
//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const reviewData = {
//       headline: formData.headline,
//       review: formData.review,
//       rating: rating,
//     };

//     try {
//       const response = await authApi.put(
//         `${APIBASE}customer/update-review-by-id/${id}`,
//         reviewData
//       );

//       if (response?.status === 200) {
//         toast.success("Review submitted successfully");
//         setFormData({
//           headline: "",
//           review: "",
//         });
//         setRating(null);
//       } else {
//         toast.error("Failed to submit review");
//       }
//     } catch (error) {
//       console.error("Error submitting review:", error);
//       toast.error("An error occurred while submitting the review");
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading py-2">
//             <h3>Create Review</h3>
//           </div>
//           {/* Divider Below heading is here */}
//           <div className="divider" />
//           <div className="review-feedback">
//             <div className="list-box d-flex flex-wrap py-3">
//               <div className="review-product">
//                 <img
//                   // src={ProductImage}
//                   src={`${IMAGEURL}${productData?.thumbnail}`}
//                   alt="img"
//                 />
//               </div>
//               <div className="ml-2">
//                 <p>{productData?.product_name || "Product Name"}</p>
//               </div>
//             </div>
//             <div className="divider" />
//             <form
//               method="post"
//               onSubmit={handleSubmit}
//               enctype="multipart/htmlform-data"
//               className="py-4"
//             >
//               <div className="row">
//                 <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
//                   <div className="rate-box">
//                     <h4>
//                       Overall rating <span className="text-danger">*</span>
//                     </h4>

//                     <div className="rate">
//                       <input
//                         type="radio"
//                         id="star5"
//                         name="rate"
//                         value="5"
//                         checked={rating === "5"}
//                         onChange={handleRatingChange}
//                       />
//                       <label htmlFor="star5" title="text">
//                         ★★★★★
//                       </label>

//                       <input
//                         type="radio"
//                         id="star4"
//                         name="rate"
//                         value="4"
//                         checked={rating === "4"}
//                         onChange={handleRatingChange}
//                       />
//                       <label htmlFor="star4" title="text">
//                         ★★★★
//                       </label>

//                       <input
//                         type="radio"
//                         id="star3"
//                         name="rate"
//                         value="3"
//                         checked={rating === "3"}
//                         onChange={handleRatingChange}
//                       />
//                       <label htmlFor="star3" title="text">
//                         ★★★
//                       </label>

//                       <input
//                         type="radio"
//                         id="star2"
//                         name="rate"
//                         value="2"
//                         checked={rating === "2"}
//                         onChange={handleRatingChange}
//                       />
//                       <label htmlFor="star2" title="text">
//                         ★★
//                       </label>

//                       <input
//                         type="radio"
//                         id="star1"
//                         name="rate"
//                         value="1"
//                         checked={rating === "1"}
//                         onChange={handleRatingChange}
//                       />
//                       <label htmlFor="star1" title="text">
//                         ★
//                       </label>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>
//                       Add a headline <span className="text-danger">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="headline"
//                       className="form-control"
//                       required=""
//                       value={formData.headline}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label>
//                       Write your review <span className="text-danger">*</span>
//                     </label>
//                     <textarea
//                       className="form-control"
//                       name="review"
//                       required=""
//                       value={formData.review}
//                       onChange={handleInputChange}
//                     ></textarea>
//                   </div>
//                 </div>

//                 <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
//                   <div className="text-center mt-3">
//                     <input
//                       type="submit"
//                       name="submit"
//                       value="Submit"
//                       className="btn theme-btn"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Createreview;
