import React, { useState } from 'react';
import { Link } from "react-router-dom";

import './AddressComponent.css';
import AddressForm from './AddressForm';

const AddNewAddress = ({ title, Icon, className }) => {

  const [showAddAddress, setShowAddAddress] = useState(false);
  const toggleAddAddress = () => {
    setShowAddAddress(!showAddAddress);
  };

  return (
    <>

      <div className='col-xl-4 col-lg-6 col-md-6 col-sm-6'>
        <div className='add-new-address h-100 mt-3 mt-sm-0'>
          <Link to="" className='toggle-slide' onClick={toggleAddAddress}>
            <Icon className={`${className}`} />
            <p>{title}</p>
          </Link>
        </div>
      </div>

      {showAddAddress && (
        <AddressForm />
      )}
    </>
  )
}

export default AddNewAddress;
