import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const EditAddressForm = () => {
  const navigate = useNavigate();
  const { addressId } = useParams();
  // comsole.log(error);("addressId", addressId);
  const user_id = useSelector((state) => state.userAuth.user.data.id);

  const [formData, setFormData] = useState({
    user_id: user_id,
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    flat_apartment: "",
    address: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });

  useEffect(() => {
    async function fetchExistingAddress() {
      try {
        const response = await authApi.get(
          `customer/get-address-by-id/${addressId}`
        );
        if (response?.status === 200) {
          const existingAddress = response.data.data;
          // // comsole.log(error);("existingAddress", response);
          setFormData(existingAddress);
        } else {
          toast.error("Failed to fetch existing address");
        }
      } catch (error) {
        console.error("Error fetching existing address:", error);
        toast.error("An error occurred while fetching existing address");
      }
    }

    fetchExistingAddress();
  }, []);

  // comsole.log(error);("existingAddress formdata", formData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await authApi.put(
        `customer/update-user-address/${addressId}`,
        formData
      );

      if (response?.status === 200) {
        toast.success("Address updated successfully!");
        navigate("/dashboard/my-profile");
      } else {
        toast.error("Failed to update address");
      }
    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("An error occurred while updating address");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="w-100 ">
        <div className="col-lg-12">
          <div className="add-address ">
            <div className="main-heading pb-3">
              <h3>Edit Addresses</h3>
            </div>
            <div className="divider mb-4" />
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      name="first_name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Middle Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Middle Name"
                      value={formData.middle_name}
                      onChange={handleInputChange}
                      name="middle_name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      name="last_name"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      name="phone"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      name="email"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Flat/Apartment</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Flat/Apartment"
                      value={formData.flat_apartment}
                      onChange={handleInputChange}
                      name="flat_apartment"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Street</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Street"
                      value={formData.street}
                      onChange={handleInputChange}
                      name="street"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleInputChange}
                      name="city"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      value={formData.state}
                      onChange={handleInputChange}
                      name="state"
                      // required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ZIP Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ZIP Code"
                      value={formData.zipcode}
                      onChange={handleInputChange}
                      name="zipcode"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      value={formData.country}
                      onChange={handleInputChange}
                      name="country"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn theme-btn">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddressForm;
