import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiChevronDown } from "react-icons/fi";
import Moment from "react-moment";
import TrackPackagePopup from "./TrackPackagePopup";
import OrderDetailsPopup from "./OrderDetailsPopup";
import { usePopup } from "./PopupContext";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import authApi from "../../../../utils/authApi";
import { IMAGEURL } from "../../../../constants";
import ReactPaginate from "react-paginate";

const OrdersComp = () => {
  const { showPopup, openPopup, closePopup } = usePopup();

  const [orderData, setOrderData] = useState([]);
  const [track, setTrack] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState(null);
  const [myHoverId, setMyHoverId] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [shippingAddress, setShippingAddress] = useState({});
  const [hoverEffect, setHoverEffect] = useState(false); // Initially set to false
  const [isLoading, setIsLoading] = useState(false);

  const displayNone = {
    display: "none",
  };

  const displayInherit = {
    display: "inherit",
  };

  const handlePageChange = (selectedPage) => {
    const newPage = selectedPage.selected + 1;
    fetchOrders({ page: newPage });
  };

  const handleMouseHover = (id) => {
    setMyHoverId(id);
    setHoverEffect(true); // Show the dropdown
  };

  const handleMouseRemove = () => {
    setMyHoverId("");
    setHoverEffect(false); // Hide the dropdown
  };

  const fetchOrders = async ({ page }) => {
    try {
      setIsLoading(true);
      const response = await authApi.get(`customer/get-orders?page=${page}`);
      const data = response.data.data;
      setOrderData(data.data);
      setTotalPages(Math.ceil(data.total / data.per_page));
      setCurrentPage(data.current_page);
    } catch (error) {
      // console.log("Error fetching orders:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders({ page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    if (orderData.length > 0) {
      const firstOrder = orderData[0];
      const parsedAddress = JSON.parse(firstOrder?.shipping_address);
      setShippingAddress(parsedAddress);
    }
  }, [orderData]);

  const handleOrdDetailClick = (id) => {
    openPopup();
    setCurrentOrderId(id);
  };

  const handleTrackPackageClick = (id) => {
    setTrack(true);
    setCurrentOrderId(id);
  };

  return (
    <>
      <div>
        <Modal isOpen={track} toggle={() => setTrack(!track)}>
          <ModalHeader toggle={() => setTrack(!track)}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              Track Order
            </div>
          </ModalHeader>
          <ModalBody>
            {currentOrderId !== null && (
              <TrackPackagePopup orderId={currentOrderId} />
            )}
          </ModalBody>
        </Modal>
      </div>

      {isLoading ? (
        <div className="loader-container" style={{ display: "flex" }}>
          <h6>Loading...</h6>
        </div>
      ) : orderData.length === 0 ? (
        <div className="no-orders">
          <p>No orders available.</p>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-12 col-md-12">
            {orderData.map((order) => (
              <div className="order-list" key={order.id}>
                {showPopup && currentOrderId === order.id && (
                  <OrderDetailsPopup
                    orderId={currentOrderId}
                    onClose={closePopup}
                  />
                )}

                <div className="order-header p-3">
                  <div className="row">
                    <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
                      {/* Order header content */}
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <div className="order-header-text">
                          <ul>
                            <li>ORDER PLACED ON</li>
                            <Moment format="DD MMM-YYYY">
                              {order?.created_at}
                            </Moment>
                            <li>
                              <strong>Order Status:</strong> {order?.order_status}
                            </li>
                          </ul>
                        </div>
                        <div className="order-header-text">
                          <ul>
                            <li>SHIP TO</li>
                            <strong>
                              {shippingAddress?.first_name}{" "}
                              {shippingAddress?.last_name}
                            </strong>
                            <li
                              onMouseEnter={() => handleMouseHover(order.id)}
                              onMouseLeave={handleMouseRemove}
                            >
                              <Link
                                to="#"
                                className="link-text"
                                style={{ fontWeight: "500" }}
                              >
                                <FiChevronDown />
                              </Link>
                              {order?.shipping_address && order.id === myHoverId && (
                                <div className="ship-to-dropdown" style={hoverEffect ? displayInherit : displayNone}>
                                  <h4>
                                    {shippingAddress?.first_name}{" "}
                                    {shippingAddress?.last_name}
                                  </h4>
                                  <p>{shippingAddress?.address}</p>
                                  <p>
                                    {shippingAddress?.city},{" "}
                                    {shippingAddress?.zipcode},{" "}
                                    {shippingAddress?.country}
                                  </p>
                                  <p>Phone: {shippingAddress?.phone}</p>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                        <div className="order-header-text">
                          <ul>
                            <li>TOTAL</li>
                            <li>${order?.grand_total}</li>
                          </ul>
                        </div>
                        <div
                          className="order-header-text"
                          onClick={() => {
                            handleTrackPackageClick(order.id);
                          }}
                        >
                          {/* <Link to="#" className="tracking-Btn">
                            TRACK PACKAGE
                          </Link> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-12 col-md-12">
                      <div className="d-block d-sm-flex justify-content-xl-end align-items-center mt-2 mt-xl-0">
                        <div className="order-header-right">
                          <div className="para-text">
                            <p>
                              <b>ORDER</b> #{order?.order_no}
                            </p>
                            <p className="text-left text-xl-right">
                              <Link
                                to="#"
                                className="link-text"
                                onClick={() => handleOrdDetailClick(order.id)}
                              >
                                Order Details
                              </Link>
                              <span className="mr-2 ml-2">|</span>
                              <a
                                href={`${IMAGEURL}${order.invoice_pdf}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-text"
                              >
                                Invoice
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="order-content p-3">
                  <div className="row">
                    <div className="col-xl-12">
                      {/* Order content items */}
                      {order.items.map((item) => (
                        <div className="order-product-list" key={item.id}>
                          <div className="row">
                            <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
                              <div className="d-sm-flex d-block">
                                <div className="product-img">
                                  <img
                                    src={
                                      item.product?.thumbnail_name
                                        // ? `${IMAGEURL}upload/product/thumbnail/100/${item.product.thumbnail}`
                                        ? `${IMAGEURL}${item.product.thumbnail}`
                                        : `${IMAGEURL}${item.product?.thumbnail}`
                                    }
                                    alt="product"
                                    className="img-fluid"
                                  />
                                </div>
                                <div className="product-info ml-md-3 ml-0 mt-md-0 mt-3">
                                  <p className="mb-2">{item.product?.product_name}</p>
                                  <p className="mb-2">
                                    <b>Quantity: </b>
                                    {item.quantity}
                                  </p>
                                  <p className="mb-2">
                                    <b>Product Code: </b>
                                    {item.product?.product_code}
                                  </p>
                                  <p className="mb-2">
                                    <b>Sku: </b>
                                    {item.product?.sku}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12">
                              <div className="product-review-part text-right float-left float-lg-right mt-3 mt-lg-0">
                                <Link
                                  to={`/dashboard/create-review/${item.product_id}`}
                                  className="theme-btn d-block"
                                >
                                  Write A Product Review
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* Pagination */}
            <div style={{ float: "right" }}>
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={<span className="ellipsis">...</span>}
                pageCount={totalPages || 0}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                forcePage={currentPage - 1}
                containerClassName="pagination justify-content-center pt-5"
                activeClassName="active"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OrdersComp;






// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { FiChevronDown } from "react-icons/fi";
// import Moment from "react-moment";
// import TrackPackagePopup from "./TrackPackagePopup";
// import OrderDetailsPopup from "./OrderDetailsPopup";
// import { usePopup } from "./PopupContext";
// import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import authApi from "../../../../utils/authApi";
// // import { useDispatch } from "react-redux";
// import { IMAGEURL } from "../../../../constants";
// import ReactPaginate from "react-paginate";

// const displayNone = {
//   display: "none",
// };

// const displayInherit = {
//   display: "inherit",
// };

// const OrdersComp = () => {
//   const [hoverEffect, setHoverEffect] = useState(displayNone);
//   const [orderData, setOrderData] = useState([]);
//   // const [userDetails, setUserDetails] = useState({});
//   const { showPopup, openPopup, closePopup } = usePopup();
//   const [track, setTrack] = useState(false);


//   const [curreOrdId, setCurrOrdId] = useState([]);
//   const [currentOrderId, setCurrentOrderId] = useState(null);
//   const [myHoverId, setmyHoverId] = useState("");
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurretPage] = useState(1);
//   // const dispatch = useDispatch();

//   const handleOrdDetailClick = (id) => {
//     openPopup()
//     setCurrOrdId(id)
//   }

//   const handleTrackPackageClick = (id) => {
//     setTrack(true);
//     setCurrentOrderId(id)
//     // console.log("track", id, currentOrderId);

//   }

//   const handleMouseHover = (id) => {
//     setHoverEffect(displayInherit)
//     setmyHoverId(id)

//   }

//   const handleMouseRemove = (id) => {
//     setHoverEffect(displayNone)
//     setmyHoverId("")
//   }

//   let [deliveredToFname, setDeliveredToFname] = useState("");
//   let [deliveredToLname, setDeliveredToLname] = useState("");

//   useEffect(() => {
//     // Fetch orders and set deliveredToFname and deliveredToLname here
//     fetchUserOrders({ page: currentPage });

//     // Set deliveredToFname and deliveredToLname here
//     if (orderData.length > 0) {
//       const shippingAddress = JSON.parse(orderData[0].shipping_address);
//       setDeliveredToLname(shippingAddress.last_name);
//       setDeliveredToFname(shippingAddress.first_name);
//     }
//   }, [currentPage, orderData]);

//   const fetchUserOrders = async ({ page }) => {

//     try {
//       const response = await authApi.get(
//         `customer/get-orders?page=${page}`

//       );
//       setOrderData(response.data.data.data);
//       setTotalPages(Math.ceil(response.data.data.total / response.data.data.per_page))
//       setCurretPage(response.data.data.current_page)
//       // console.log("current page", response.data.data.current_page);

//     } catch (error) {
//       console.log("Error fetching orders:", error);
//     }

//   };

//   // console.log(currentPage);

//   useEffect(() => {
//     fetchUserOrders({ page: currentPage });
//   }, [currentPage]);


//   const handlePageChange = (selectedPage) => {
//     console.log(selectedPage.selected + 1);
//     fetchUserOrders({ page: selectedPage.selected + 1 })
//   };

//   // console.log(deliveredToFname, deliveredToLname);

//   return (
//     <>


//       <div >
//         <Modal
//           // size='lg'
//           isOpen={track}
//           toggle={() => setTrack(!track)}

//         >
//           {/* <ModalHeader toggle={() => setTrack(!track)} close="✘"> */}
//           <ModalHeader toggle={() => setTrack(!track)}>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               Track Order
//             </div>
//           </ModalHeader>
//           <ModalBody>
//             {currentOrderId !== null && (
//               <TrackPackagePopup orderId={currentOrderId} />

//             )}
//           </ModalBody>

//         </Modal>
//       </div>


//       <div className="row">
//         <div className="col-lg-12 col-md-12 ">
//           {orderData && (
//             orderData.map((row) => (
//               <div className="order-list">
//                 {showPopup && curreOrdId == row.id && <OrderDetailsPopup
//                   orderId={curreOrdId}
//                   onClose={closePopup} />}

//                 <div className="order-header p-3">
//                   <div className="row">
//                     <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
//                       <div className="d-block d-sm-flex justify-content-between align-items-center">
//                         <div className="order-header-text">
//                           <ul>
//                             <li>ORDER PLACED ON</li>
//                             <Moment format="DD MMM-YYYY">
//                               {row?.created_at}
//                             </Moment>
//                             <li><strong>Order Status:</strong> {row?.order_status}</li>
//                           </ul>
//                         </div>

//                         <div className="order-header-text">
//                           <ul>
//                             <li>SHIP TO</li>
//                             <strong >{deliveredToFname}{" "}{deliveredToLname}</strong>

//                             <li
//                               onMouseEnter={() => handleMouseHover(row.id)}
//                               onMouseLeave={() => handleMouseRemove("")}
//                             >
//                               <Link
//                                 to="#"
//                                 className="link-text"
//                                 style={{ fontWeight: "500" }}
//                               >
//                                 <FiChevronDown />
//                               </Link>
//                               {row?.shipping_address && row.id === myHoverId && (
//                                 <div className="ship-to-dropdown" style={hoverEffect}>
//                                   {(() => {
//                                     const shippingAddress = JSON.parse(row.shipping_address);

//                                     return (
//                                       <>
//                                         <h4>
//                                           {deliveredToFname} {deliveredToLname}
//                                         </h4>
//                                         <p>{shippingAddress.address}</p>
//                                         <p>
//                                           {shippingAddress.city}, {shippingAddress.zipcode},{" "}
//                                           {shippingAddress.country}
//                                         </p>
//                                         <p>Phone: {shippingAddress.phone}</p>
//                                       </>
//                                     );
//                                   })()}
//                                 </div>
//                               )}
//                             </li>
//                           </ul>
//                         </div>


//                         <div className="order-header-text">
//                           <ul>
//                             <li>TOTAL</li>
//                             <li>${row?.grand_total}</li>
//                           </ul>
//                         </div>

//                         <div
//                           className="order-header-text"
//                           onClick={() => {
//                             handleTrackPackageClick(row.id);
//                           }}
//                         >
//                           <Link to="#" className="tracking-Btn">
//                             TRACK PACKAGE
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-xl-4 col-lg-12 col-md-12">
//                       <div className="d-block d-sm-flex justify-content-xl-end align-items-center mt-2 mt-xl-0">
//                         <div className="order-header-right">
//                           <div className="para-text">
//                             <p>
//                               <b>ORDER</b> #{row?.order_no}
//                             </p>
//                             <p className="text-left text-xl-right">
//                               <Link
//                                 to="#"
//                                 className="link-text"
//                                 onClick={() =>

//                                   handleOrdDetailClick(row.id)
//                                 }

//                               >
//                                 Order Details
//                               </Link>
//                               <span className="mr-2 ml-2">|</span>
//                               <a href={`${IMAGEURL}${row.invoice_pdf}`} target="_blank" className="link-text">
//                                 Invoice
//                               </a>
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="order-content p-3">
//                   <div className="row">
//                     <div className="col-xl-12">
//                       {row.items.map((item) => (
//                         <div className="order-product-list" key={item.id}>
//                           <div className="row">
//                             <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
//                               <div className="d-sm-flex d-block">
//                                 <div className="product-img">
//                                   {/* <img
//                                     src={`${IMAGEURL}${item.product?.thumbnail}`}
//                                     alt="product"
//                                     className="img-fluid"
//                                   /> */}
//                                   <img
//                                     src={item.product?.thumbnail_name ?
//                                       `${IMAGEURL}upload/product/thumbnail/100/${item.product.thumbnail}` :
//                                       `${IMAGEURL}${item.product?.thumbnail}`}
//                                     alt="product"
//                                     className="img-fluid"
//                                   />

//                                 </div>
//                                 <div className="product-info ml-md-3 ml-0 mt-md-0 mt-3">
//                                   <p className="mb-2">{item.product?.product_name}</p>
//                                   <p className="mb-2">
//                                     <b>Quantity: </b>
//                                     {item.quantity}
//                                   </p>
//                                   <p className="mb-2">
//                                     <b>Product Code: </b>
//                                     {item.product?.product_code}
//                                   </p>
//                                   <p className="mb-2">
//                                     <b>Sku: </b>
//                                     {item.product?.sku}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12">
//                               <div className="product-review-part text-right float-left float-lg-right mt-3 mt-lg-0">
//                                 <Link
//                                   to={`/dashboard/create-review/${item.product_id}`}
//                                   className="theme-btn d-block"
//                                 >
//                                   Write A Product Review
//                                 </Link>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>

//               </div>
//             ))

//           )}
//           <div style={{ float: "right" }}>
//             <ReactPaginate
//               previousLabel={"<<"}
//               nextLabel={">>"}
//               breakLabel={<span className="ellipsis">...</span>}
//               pageCount={totalPages || 0}
//               marginPagesDisplayed={2}
//               pageRangeDisplayed={2}
//               onPageChange={handlePageChange}
//               forcePage={currentPage - 1}
//               containerClassName="pagination justify-content-center pt-5"
//               activeClassName="active"
//               pageClassName="page-item"
//               pageLinkClassName="page-link"
//               previousClassName="page-item"
//               previousLinkClassName="page-link"
//               nextClassName="page-item"
//               nextLinkClassName="page-link"
//               breakClassName="page-item"
//               breakLinkClassName="page-link"
//             />
//           </div>
//         </div >

//       </div>
//     </>
//   );
// };

// export default OrdersComp;


