import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/shopnmac-logo.png";
import FailedImg from "../../assets/icon_cancel.png";
import Facebook from "../../assets/facebook.png";
import Twitter from "../../assets/twitter.png";

const Failed = () => {
  const location = useLocation();
  const response = location.state?.error;
  return (
    <>
      <div className="ptb-5 ptb-md-3">
        <table
          cellpadding="0"
          cellspacing="0"
          width="570px"
          style={{
            background: "#fff",
            borderRadius: "5px",
            padding: "30px 0",
            fontFamily: "'Libre Franklin', sans-serif",
            fontSize: "16px",
            fontWeight: 300,
            color: "#000",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <tr>
            <td
              style={{
                borderBottom: "4px solid #818181",
              }}
            >
              <img
                src={Logo}
                width="221"
                height="74"
                alt="ProductImage"
                style={{
                  width: "auto",
                  height: "54px",
                }}
              />
              <h1
                style={{
                  fontSize: "24px",
                  color: "#525252",
                  fontWeight: 400,
                  lineHeight: "normal",
                  padding: "30px 0 24px 0",
                  margin: 0,
                }}
              >
                Disapproved
              </h1>
            </td>
          </tr>
          <tr>
            <td
              style={{
                borderBottom: "4px solid #818181",
                padding: "40px 0",
              }}
            >
              <table
                cellpadding="0"
                cellspacing="0"
                width="460px"
                style={{
                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <tr>
                  <td>
                    <img
                      src={FailedImg}
                      width="150"
                      height="150"
                      alt="rejected"
                      style={{
                        width: "auto",
                        height: "150px",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                    <p
                      style={{
                        padding: "30px 0",
                        margin: 0,
                        lineHeight: "24px",
                      }}
                    >
                      {/* Your request has been disapproved due to inappropriate
                      product
                      <br />
                      Your ticket ID: ON000123 */}
                      {response && (
                        <div>
                          <p>Your request has been disapproved due to</p>
                          <pre>{JSON.stringify(response, null, 2)}</pre>
                        </div>
                      )}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      borderTop: "4px solid #d5d5d5",
                      padding: "30px 0",
                      margin: "0 auto",
                      textAlign:"center"
                    }}
                  >
                    <p>CHECK THE ERROR ABOVE AND</p>
                    <Link
                      to="/confirm-order"
                      title="BACK TO SHOP"
                      style={{
                        background: "#516f39",
                        maxWidth: "300px",
                        lineHeight: "60px",
                        margin: "0 auto",
                        display: "block",
                        borderRadius: "30px",
                        textAlign: "center",
                        fontSize: "18px",
                        color: "#fff",
                        fontWeight: 400,
                        textDecoration: "none",
                        marginBottom: "24px",
                      }}
                    >
                      TRY AGAIN
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: "50px" }}>
                    <h4
                      style={{
                        padding: "0 0 5px 0",
                        margin: 0,
                        fontSize: "16px",
                        fontWeight: 700,
                      }}
                    >
                      NEED HELP?
                    </h4>
                    <p
                      style={{
                        lineHeight: "24px",
                        fontSize: "14px",
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Please send any Feedback or Bug Report to
                      <br />
                      <Link
                        to="mailto:info@shopnmac.com"
                        style={{
                          color: "#000",
                          textDecoration: "none",
                        }}
                      >
                        info@shopnmac.com
                      </Link>
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            {/* <td style={{ padding: "30px 0 10px 0" }}>
              <Link to="#" target="_blank" title="facebook">
                <img
                  src={Facebook}
                  width="44"
                  height="44"
                  alt="facebook"
                  style={{ marginRight: "8px" }}
                />
              </Link>
              <Link to="#" target="_blank" title="twitter">
                <img src={Twitter} width="44" height="44" alt="twitter" />
              </Link>
              <p
                style={{
                  lineHeight: "24px",
                  fontSize: "14px",
                  padding: "20px 0 0 0",
                  margin: 0,
                }}
              >
                Copyright ©2023
                <br />
                NorthShore Medical & Aesthetics Center Ltd.
              </p>
            </td> */}
          </tr>
        </table>
      </div>
    </>
  );
};

export default Failed;
