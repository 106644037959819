import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { login } from "../../redux/userAuthSlice";
import { toast } from "react-toastify";
import "./Login.css";
import axios from "axios";
import { APIBASE } from "../../constants";
import LoginImage from "../../assets/login-bdagpo.jpg"
export default function Login() {
  const dispatch = useDispatch();
  const nagivate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectURL = queryParams.get("redirectTo");

  const [isWrong, setIsWrong] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const [isloading, setIsloading] = useState(false);

  // const loading = useSelector((state) => state.userAuth.loading);
  const error = useSelector((state) => state.userAuth.error);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();

    dispatch(login({ email, password }))
      .then((response) => {

        console.log("response is ",response)

        // Check if the login was successful
        // comsole.log(error);("response is", response)
        setIsloading(false);
        if (response.payload._token) {
          // Navigate to the dashboard route
          // nagivate(redirectURL ? `/${redirectURL}` : "/");
          nagivate(redirectURL ? `/${redirectURL}` : "/all-products");
          toast.success(response.payload.message);
        } else {
          if (response.payload?.error) {
            toast.error(response.payload.error[0]);
          } else {
            toast.error(response.payload);
          }

        }
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          console.error(error.response.data.errors);
        } else {
          // Handle other types of errors (e.g., server error, network error)

        }
      });
  };

  //https://snapi.quicsy.com/api/check-email/user@example.com
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };
  const handleInputChange = (event) => {
    const val = event.target.value;
    if (val?.trim() !== "" && val?.length >= 4) {
      setEmail(val);
    } else {
      setEmail(val);
      // Clear searchResults when searchQuery is empty
    }
    // Perform your logic here with the updated value
    // This function will be called after the specified delay (debounce time)
  };
  const handleInput = debounce(handleInputChange, 1000);
  useEffect(() => {
    if (validateEmail(email)) {
      checkUser(email);
    }
  }, [email]);
  const checkUser = async (val) => {
    setIsChecking(true);
    try {
      const res = await axios.get(
        `${APIBASE}check-email/${val}`
      );
      setIsChecking(false);
      setIsWrong(false);
      setIsRight(true);
    } catch (error) {
      // comsole.log(error);(error);
      setIsWrong(true);
      setIsRight(false);
      setIsChecking(false);
    }
  };

  function validateEmail(email) {
    // Get the input value
    var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    // Test the input against the pattern
    if (emailPattern.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (email == "") {
      setIsRight(false);
      setIsWrong(false);
      setIsChecking(false);
    }
  }, [email]);

  return (
    <section>
      <div className="container-side-space">
        {/* <div className="signup-form ptb-5 ptb-md-3"> */}
        <div className="form-box">
          <div className="container-login login-section mb-100">

            <div className="row bg-gary2">
              <div className="col-lg-6 col-md-8 col-sm-12 m-auto pt-3 pb-3">
                <div className="bg-gary-login" >
                  <div className="heading text-center pb-4">
                    <h3 className="border-0">Log In </h3>
                  </div>
                  <div className="form-layer-bg">
                    {error && (
                      <div className="alert alert-danger" role="alert">
                        Error occurred. Please check your details.
                      </div>
                    )}
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email">Email :</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          // value={email}
                          onChange={handleInput}
                        />
                        {isChecking && <div>Checking...</div>}

                        {isWrong && !isChecking && <div> user doesn't exist!</div>}
                        {/* {!validateEmail(email) && email?.length > 5 && (
                    <label style={{ color: "red !important" }}>
                      Invalid email address!
                    </label>
                  )} */}
                      </div>

                      <div className="form-group">
                        <label htmlFor="pwd">Password :</label>
                        <input
                          type="password"
                          className="form-control"
                          id="pwd"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <div className="text-center pt-2">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          disabled={
                            isloading ||
                            password == "" ||
                            email == ""
                          }
                        >
                          {isloading ? "Logging in..." : "Login"}
                        </button>
                      </div>
                    </form>

                    <p className="login-text pt-3">
                      You Don't Have An Account? &nbsp;
                      {/* <Link className="ancher-text" to="/Signup">
                        Register
                      </Link> */}
                    </p>
                    <p className="login-text pt-1">
                      <Link className="ancher-text" to="/forget-password">
                        Forget Password?
                      </Link>
                    </p>
                  </div>

                </div>
              </div>

              <div className="col-md-6 col-ms-12 p-0 d-flex">
                <img src={LoginImage} className="img-responsive img-mob" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
