import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../utils/api";
import authApi from "../utils/authApi";

const initialState = {
  products: [],
  currentPage: 1,
  indexFrom: 0,
  indexTo: 0,
  totalPages: 1,
  totalItems: 0,
  perPage: 15,
  loading: false,
  error: null,
  wishlist: [],
};

//Define the async thunk for fetching wishlist
export const fetchWishlist = createAsyncThunk("wishlist/fetch", async () => {


  try {
    const response = await authApi.get(`customer/get-user-wishlist`);
    return response?.data.data;
  } catch (error) {
    console.error("Failed to products:", error);
  }
});

fetchWishlist()


// Define the async thunk for fetching products
export const fetchProducts = createAsyncThunk(
  "products/fetch",
  async ({ page, sort, category, brand, searchQuery }) => {
    try {
      const response = await api.get(`/products`, {
        params: {
          page: page,
          sort_order: sort,
          category: category,
          brand: brand,
          search: searchQuery,
          // filter: {
          //   product: search,
          // },
        },
      });
      // // comsole.log(error);(response.data);
      return response?.data;
    } catch (error) {
      console.error("Failed to products:", error);
    }
  }
);

// Define the async thunk for fetching products by category
export const fetchProductsByCategory = createAsyncThunk(
  "products/fetchByCategory",
  async ({ category, search, page, sort }) => {
    try {
      const response = await api.get(`category/${category}/products`, {
        params: {
          page: page,
          sort_order: sort,
          // filter: {
          //   product: search,
          //   // brand: search,
          // },
        },
      });
      // // comsole.log(error);(response.data);
      return response?.data;
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  }
);

// Define the async thunk for fetching products by brand
export const fetchProductsByBrand = createAsyncThunk(
  "products/fetchByBrand",
  async ({ brand, page, sort }) => {
    try {
      const response = await api.get(`brand/${brand}/products`, {
        params: {
          page: page,
          sort: sort,
        },
      });
      // // comsole.log(error);(response.data);
      return response?.data;
    } catch (error) {
      console.error("Failed to fetch products:", error);
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = action.payload?.data;
        state.currentPage = action.payload?.meta.current_page;
        state.indexFrom = action.payload?.meta.from;
        state.indexTo = action.payload?.meta.to;
        state.perPage = action.payload?.meta.per_page;
        state.totalItems = action.payload?.meta.total;
        state.totalPages = Math.ceil(
          action.payload?.meta.total / action.payload?.meta.per_page
        );
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // fetch products by category
    builder
      .addCase(fetchProductsByCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = action.payload?.data;
        state.currentPage = action.payload?.meta.current_page;
        state.indexFrom = action.payload?.meta.from;
        state.indexTo = action.payload?.meta.to;
        state.perPage = action.payload?.meta.per_page;
        state.totalItems = action.payload?.meta.total;
        state.totalPages = Math.ceil(
          action.payload?.meta.total / action.payload?.meta.per_page
        );
      })
      .addCase(fetchProductsByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // fetch products by brand
    builder
      .addCase(fetchProductsByBrand.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductsByBrand.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.products = action.payload?.data;
        state.currentPage = action.payload?.meta.current_page;
        state.indexFrom = action.payload?.meta.from;
        state.indexTo = action.payload?.meta.to;
        state.perPage = action.payload?.meta.per_page;
        state.totalItems = action.payload?.meta.total;
        state.totalPages = Math.ceil(
          action.payload?.meta.total / action.payload?.meta.per_page
        );
      })
      .addCase(fetchProductsByBrand.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder.addCase(fetchWishlist.fulfilled, (state, action) => {
      state.wishlist = action.payload;
    });
  },
});

export default productSlice.reducer;
