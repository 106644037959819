import React from 'react';
import './blog.css';
import BlogImg from '../../../assets/Contact-us.jpg';
import { Link } from 'react-router-dom';


const Blog = ({customclass, imgclass}) => {
  return (
    <>
      <div className='blog-card'>
        <Link to='/blog-details' className={customclass}>
        <div className={`blog-img ${imgclass}`}>
        <img src={BlogImg} className="img-fluid" alt="blog" />
        </div>
        <div className='blog-content pt-3 px-3'>
          <p>Tips & Trick</p>
          <h3 className='line-clamp-2'>Hair Restoration Response To Will & Jada Smith & Chris Rock Award Show Drama</h3>
          <p>May 26, 2022 </p>
        </div>
        </Link>
      </div>
    </>
  )
}

export default Blog
 