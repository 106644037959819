import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ChangePassword.css";
import authApi from "../../../../utils/authApi";

function ChangePassword() {
  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirmPassword: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (passwordData.new_password !== passwordData.confirmPassword) {
      toast.error("New password and confirm password do not match.");
      return;
    }

    try {
      const response = await authApi.put(
        "/customer/update-password",
        {
          old_password: passwordData.old_password,
          new_password: passwordData.new_password,
        }
      );

      if (response?.status === 200) {
        // Show success toast
        toast.success("Password changed successfully!");
      } else {
        // Show failure toast
        toast.error("Failed to change password");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      // Handle error scenarios here
      toast.error("An error occurred while changing password");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading">
            <h3>Change Password</h3>
          </div>

          {/* Divider Below heading is here */}
          <div className="divider" />

          <div className="change-password py-4">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="old_password">Old Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Old Password"
                      id="old_password"
                      name="old_password"
                      value={passwordData.old_password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="new_password">New Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter New Password"
                      id="new_password"
                      name="new_password"
                      value={passwordData.new_password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Enter Confirm Password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={passwordData.confirmPassword}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 text-center">
                <button type="submit" className="btn theme-btn">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;


// import React, { useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./ChangePassword.css";
// import authApi from "../../../../utils/authApi";

// function ChangePassword() {
//   const [passwordData, setPasswordData] = useState({
//     old_password: "",
//     new_password: "",
//     confirmPassword: "",
//   });

//   // const [isold_passwordCorrect, setIsold_passwordCorrect] = useState(false);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setPasswordData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     // Check old password validity whenever input changes
//     // if (name === "old_password") {
//     //   checkold_password(value);
//     // }
//   };

//   // const checkold_password = async (value) => {
//   //   try {
//   //     const response = await authApi.post("/customer/check-old-password", {
//   //       old_password: value,
//   //     });

//   //     setIsold_passwordCorrect(
//   //       response?.status === 200 && response?.data?.isValid
//   //     );
//   //   } catch (error) {
//   //     console.error("Error checking old password:", error);
//   //     setIsold_passwordCorrect(false);
//   //   }
//   // };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Check if the old password is correct before proceeding
//     // await checkold_password();

//     // if (!isold_passwordCorrect) {
//     //   toast.error("Old password is incorrect.");
//     //   return;
//     // }

//     try {
//       const response = await authApi.put(
//         "/customer/update-password",
//         passwordData
//       );

//       if (response?.status === 200) {
//         // Show success toast
//         toast.success("Password changed successfully!");
//       } else {
//         // Show failure toast
//         toast.error("Failed to change password");
//       }
//     } catch (error) {
//       console.error("Error changing password:", error);
//       // Handle error scenarios here
//       toast.error("An error occurred while changing password");
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading">
//             <h3>Change Password</h3>
//           </div>

//           {/* Divider Below heading is here */}
//           <div className="divider" />

//           <div className="change-password py-4">
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label for="pwd">Old Pasword</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Old Password"
//                       id="pwd"
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="new_password">New Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter New Password"
//                       id="new_password"
//                       name="new_password"
//                       value={passwordData.new_password}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="confirmPassword">Confirm Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Confirm Password"
//                       id="confirmPassword"
//                       name="confirmPassword"
//                       value={passwordData.confirmPassword}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 text-center">
//                 <button type="submit" className="btn theme-btn">
//                   SUBMIT
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChangePassword;


// import React, { useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import "./ChangePassword.css";
// import authApi from "../../../../utils/authApi";

// function ChangePassword() {
//   const [passwordData, setPasswordData] = useState({
//     old_password: "",
//     new_password: "",
//     confirmPassword: "",
//   });

//   const [isold_passwordCorrect, setIsold_passwordCorrect] = useState(false);

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setPasswordData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     // Check old password validity whenever input changes
//     if (name === "old_password") {
//       checkold_password(value);
//     }
//   };

//   const checkold_password = async (value) => {
//     try {
//       const response = await authApi.post("/customer/check-old-password", {
//         old_password: value,
//       });

//       setIsold_passwordCorrect(
//         response?.status === 200 && response?.data?.isValid
//       );
//     } catch (error) {
//       console.error("Error checking old password:", error);
//       setIsold_passwordCorrect(false);
//     }
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Check if the old password is correct before proceeding
//     await checkold_password();

//     if (!isold_passwordCorrect) {
//       toast.error("Old password is incorrect.");
//       return;
//     }

//     try {
//       const response = await authApi.put(
//         "/customer/update-password",
//         passwordData
//       );

//       if (response?.status === 200) {
//         // Show success toast
//         toast.success("Password changed successfully!");
//       } else {
//         // Show failure toast
//         toast.error("Failed to change password");
//       }
//     } catch (error) {
//       console.error("Error changing password:", error);
//       // Handle error scenarios here
//       toast.error("An error occurred while changing password");
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading">
//             <h3>Change Password</h3>
//           </div>

//           {/* Divider Below heading is here */}
//           <div className="divider" />

//           <div className="change-password py-4">
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="old_password">Old Password</label>
//                     <div className="password-input">
//                       <input
//                         type="password"
//                         className={`form-control ${
//                           passwordData.old_password && !isold_passwordCorrect
//                             ? "is-invalid"
//                             : isold_passwordCorrect
//                             ? "is-valid"
//                             : ""
//                         }`}
//                         placeholder="Enter Old Password"
//                         id="old_password"
//                         name="old_password"
//                         value={passwordData.old_password}
//                         onChange={handleInputChange}
//                       />
//                       {passwordData.old_password && !isold_passwordCorrect ? (
//                         <span className="password-icon invalid">
//                           {/* SVG icon for cross */}
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="16"
//                             height="16"
//                             fill="red"
//                             className="bi bi-x-circle-fill"
//                             viewBox="0 0 16 16"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm.146 5.146a.5.5 0 0 1 .708 0L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 9l2.647 2.646a.5.5 0 0 1-.708.708L8 9.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 9 4.647 6.354a.5.5 0 0 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 9z"
//                             />
//                           </svg>
//                         </span>
//                       ) : (
//                         <span className="password-icon">
//                           {/* Empty span for alignment */}
//                         </span>
//                       )}
//                       {isold_passwordCorrect ? (
//                         <span className="password-icon valid">
//                           {/* SVG icon for check */}
//                           <svg
//                             xmlns="http://www.w3.org/2000/svg"
//                             width="16"
//                             height="16"
//                             fill="green"
//                             className="bi bi-check-circle-fill"
//                             viewBox="0 0 16 16"
//                           >
//                             <path
//                               fillRule="evenodd"
//                               d="M7.773.342a.5.5 0 0 1 .454.312l7.5 15a.5.5 0 0 1-.865.5L7.5 1.327.628 15.154a.5.5 0 0 1-.865-.5l7.5-15a.5.5 0 0 1 .454-.313z"
//                             />
//                           </svg>
//                         </span>
//                       ) : (
//                         <span className="password-icon">
//                           {/* Empty span for alignment */}
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="new_password">New Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter New Password"
//                       id="new_password"
//                       name="new_password"
//                       value={passwordData.new_password}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="confirmPassword">Confirm Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Confirm Password"
//                       id="confirmPassword"
//                       name="confirmPassword"
//                       value={passwordData.confirmPassword}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 text-center">
//                 <button type="submit" className="btn theme-btn">
//                   SUBMIT
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChangePassword;

// import React, { useState } from "react";
// import axios from "axios";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import "./ChangePassword.css";
// import { authToken } from "../../auth/auth";

// function ChangePassword() {
//   const [passwordData, setPasswordData] = useState({
//     old_password: "",
//     new_password: "",
//     confirmPassword: "",
//   });

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     setPasswordData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       const response = await axios.put('https://snapi.quicsy.com/api/customer/update-password', passwordData, {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       });

//       if (response?.status === 200) {
//         // Show success toast
//         toast.success('Password changed successfully!');
//       } else {
//         // Show failure toast
//         toast.error('Failed to change password');
//       }
//     } catch (error) {
//       console.error('Error changing password:', error);
//       // Handle error scenarios here
//       toast.error('An error occurred while changing password');
//     }
//   };

//   return (
//     <>
//       <ToastContainer />
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading">
//             <h3>Change Password</h3>
//           </div>

//           {/* Divider Below heading is here */}
//           <div className="divider" />

//           <div className="change-password py-4">
//             <form onSubmit={handleSubmit}>
//               <div className="row">
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="old_password">Old Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Old Password"
//                       id="old_password"
//                       name="old_password"
//                       value={passwordData.old_password}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="new_password">New Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter New Password"
//                       id="new_password"
//                       name="new_password"
//                       value={passwordData.new_password}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label htmlFor="confirmPassword">Confirm Password</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Confirm Password"
//                       id="confirmPassword"
//                       name="confirmPassword"
//                       value={passwordData.confirmPassword}
//                       onChange={handleInputChange}
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 text-center">
//                 <button type="submit" className="btn theme-btn">
//                   SUBMIT
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChangePassword;

// import React from "react";
// import "./ChangePassword.css";

// function ChangePassword() {
//   return (
//     <>
//       <div className="row">
//         <div className="col-md-12">
//           <div className="main-heading">
//             <h3>Change Password</h3>
//           </div>

//           {/* Divider Below heading is here */}
//           <div className="divider" />

//           <div className="change-password py-4">
//             <form>
//               <div className="row">
//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label for="pwd">Old Pasword</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Old Password"
//                       id="pwd"
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label for="pwd">New Pasword</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter New Password"
//                       id="pwd"
//                     />
//                   </div>
//                 </div>

//                 <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12">
//                   <div className="form-group">
//                     <label for="pwd">Confirm Pasword</label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       placeholder="Enter Confirm Password"
//                       id="pwd"
//                     />
//                   </div>
//                 </div>
//               </div>

//               <div className="col-12 text-center">
//                 <button type="submit" className="btn theme-btn">
//                   SUBMIT
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default ChangePassword;
