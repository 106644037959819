import React, { useEffect, useState } from "react";

const CustomerReviews = ({ reviews }) => {
  const [starRatings, setStarRatings] = useState([
    { stars: 5, percentage: 0, percentSymbol: "%" },
    { stars: 4, percentage: 0, percentSymbol: "%" },
    { stars: 3, percentage: 0, percentSymbol: "%" },
    { stars: 2, percentage: 0, percentSymbol: "%" },
    { stars: 1, percentage: 0, percentSymbol: "%" },
  ]);
const [overallReviews,setOverAllReviews]=useState(0)


  useEffect(() => {
    if (reviews?.length > 0) {
      setStarRatings([
        {
          stars: 5,
          percentage:
            (
              reviews?.filter((elem) => elem?.star == 5)?.length /
              reviews?.length
            ).toFixed(2) * 100,
          percentSymbol: "%",
        },
        {
          stars: 4,
          percentage:
            (
              reviews?.filter((elem) => elem?.star == 4)?.length /
              reviews?.length
            ).toFixed(2) * 100,
          percentSymbol: "%",
        },
        {
          stars: 3,
          percentage:
            (
              reviews?.filter((elem) => elem?.star == 3)?.length /
              reviews?.length
            ).toFixed(2) * 100,
          percentSymbol: "%",
        },
        {
          stars: 2,
          percentage:
            (
              reviews?.filter((elem) => elem?.star == 2)?.length /
              reviews?.length
            ).toFixed(2) * 100,
          percentSymbol: "%",
        },
        {
          stars: 1,
          percentage:
            (
              reviews?.filter((elem) => elem?.star == 1)?.length /
              reviews?.length
            ).toFixed(2) * 100,
          percentSymbol: "%",
        },
      ]);

      const rSum=reviews?.reduce((a,b)=>{return a+ (b.star>0?b.star:0) },0)

setOverAllReviews(((rSum/(reviews.length)).toFixed(1)))

    }
  }, [reviews]);

  return (
    <>
      <div className="rating-container">
        <div className="stars">
          {Array.from({ length: 5 }, (_, index) => (
            <span key={index} className="star">
              {index < Math.floor(overallReviews) ? "★" : "☆"}
            </span>
          ))}
          <span className="fw-400 text-gray fa-14 ml-3"> ( {overallReviews} out of 5 )</span>
        </div>

        <div className="progress-container">
          {starRatings.map((rating) => (
            <div key={rating.stars} className="overall-rating">
              <span>Star {rating.stars} </span>
              <div className="progress" style={{ height: "15px" }}>
                <div
                  className="progress-bar bg-warning"
                  style={{ width: `${rating.percentage}%`, height: "20px" }}
                ></div>
              </div>
              <span>
                {"(" + rating.percentage + rating.percentSymbol + ")"}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default CustomerReviews;
