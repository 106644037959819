import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { AiFillStar } from "react-icons/ai";
import authApi from "../../../../utils/authApi";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { APIBASE, IMAGEURL } from "../../../../constants";
import { fetchWishlist } from "../../../../redux/productSlice";

const CancelProductCard = () => {
  const [productData, setProductData] = useState([]);
  const user_id = useSelector((state) => state.userAuth.user.data?.id);
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.products.wishlist);


  const handleAddToWishList = async (id) => {
    const payload = { user_id: user_id ? user_id : null, product_id: id };
    if (user_id && id) {
      try {
        await axios.post(`${APIBASE}wishlists`, payload);
        toast.success("Product added to wishlist successfully.", { autoClose: 300 });
        dispatch(fetchWishlist());
      } catch (error) {
        // comsole.log(error);(error);
        toast.error("Error!", { autoClose: 300 });
      }
    }
  };

  const handleDeleteWishlist = async (id) => {
    try {
      await authApi.delete(`/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      dispatch(fetchWishlist());
    } catch (error) {
      // comsole.log(error);(error);
      toast.error("Error");
    }
  };


  const SubscribedProducts = async () => {
    authApi
      .get("customer/get-cancelled-subscription")
      .then((response) => {
        const res = response.data.data.data;
        setProductData(res);
        // comsole.log(error);("active", res);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }
  useEffect(() => {
    SubscribedProducts()
  }, []);

  // comsole.log(error);("products to get", productData);

  // const handleCancelSubscription = async (subscriptionId) => {
  //   try {
  //     const payload = {
  //       subscription_id: subscriptionId,
  //     };

  //     const response = await authApi.post("customer/cancel-subscription", payload);

  //     // comsole.log(error);(response);

  //     // Show a success toast message
  //     toast.success("Subscription canceled successfully");

  //     SubscribedProducts();
  //     // comsole.log(error);(productData);
  //   } catch (error) {
  //     // Show an error toast message
  //     toast.error("Failed to cancel subscription");
  //     console.error("Error canceling subscription:", error);
  //   }
  // };


  const checkWish = (id) => {
    const filter = wishlist?.filter((elem) => elem?.product?.id === id);
    return filter?.length > 0;
  };

  // comsole.log(error);("cancelled-products", productData);

  return (
    <div className="">
      {productData.map((product, index) => {
        const items = JSON.parse(product.items);
        return (
          <div className="custom-subscribed-products">
            <div className="row">

              {items.map((item, itemIndex) => {
                const itemProductID = item.product_id;
                const isWishForItem = checkWish(itemProductID);
                return (
                  <div key={itemIndex} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
                    <div className="product-card">


                      <div className="prod-img">
                        {/* <img
                          className="image-main"
                          src={item.thumbnail_name ?
                            `${IMAGEURL}upload/product/thumbnail/300/${item.image}` :
                            `${IMAGEURL}${item.image}`}
                          alt="ProductImage"
                        /> */}
                        <img
                          className="image-main"
                          src={
                            item?.thumbnail_compress
                              ? JSON.parse(item.thumbnail_compress).image_urls["300px"]
                              : `${IMAGEURL}${item?.thumbnail}`
                          }
                          alt={item?.name}
                        />
                      </div>

                      <div className="over-layer">
                        <div className="pr-3 pt-3">
                          <div className="pt-2 pb-1">
                            {isWishForItem ? (
                              <Link onClick={() => handleDeleteWishlist(itemProductID)}>
                                <i className="fas fa-heart red-color"></i>
                              </Link>
                            ) : (
                              <Link onClick={() => handleAddToWishList(itemProductID)}>
                                <i className="far fa-heart gray-color"></i>
                              </Link>
                            )}
                          </div>
                          <div className="pt-2 pb-1">
                            <Link to={`/product-detail/${items[0].slug}`}>
                              <i className="far fa-eye gray-color"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="prod-content">
                        <p className="price-text mt-3 mb-2">
                          <span>US ${item.price}</span>
                        </p>
                        <p className="prod-description pb-1">{item.name}</p>
                        <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                          Quantity <strong>{item.qty}</strong>
                        </p>

                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <hr />

            <div className="subs-details-cancel">

              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                Subscription Cancelled On <strong>{product.next_date}</strong>
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                <strong>{product.month} </strong> month's subscription
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                Subscription No <strong>{product.subscription_no}</strong>
              </p>

              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                Total Subscription Amount <strong>{product.grand_total}</strong>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CancelProductCard;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { AiFillStar } from "react-icons/ai";
// import authApi from "../../../../utils/authApi";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { fetchWishlist } from "../../../../redux/productSlice";

// const CancelProductCard = () => {
//   const [productData, setProductData] = useState([]);
//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const dispatch = useDispatch();
//   const wishlist = useSelector((state) => state.products.wishlist);


//   const handleAddToWishList = async (id) => {
//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("Product added to wishlist successfully.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     }
//   };

//   const handleDeleteWishlist = async (id) => {
//     if (id && user_id) {
//       try {
//         await axios.delete(`${APIBASE}wishlists/${id}`);
//         toast.success("Item deleted from wishlist.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error", { autoClose: 300 });
//       }
//     }
//   };


//   const SubscribedProducts = async () => {
//     authApi
//       .get("customer/get-cancelled-subscription")
//       .then((response) => {
//         const res = response.data.data.data;
//         setProductData(res);
//         // comsole.log(error);("active", res);
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   }
//   useEffect(() => {
//     SubscribedProducts()
//   }, []);

//   // comsole.log(error);("products to get", productData);

//   // const handleCancelSubscription = async (subscriptionId) => {
//   //   try {
//   //     const payload = {
//   //       subscription_id: subscriptionId,
//   //     };

//   //     const response = await authApi.post("customer/cancel-subscription", payload);

//   //     // comsole.log(error);(response);

//   //     // Show a success toast message
//   //     toast.success("Subscription canceled successfully");

//   //     SubscribedProducts();
//   //     // comsole.log(error);(productData);
//   //   } catch (error) {
//   //     // Show an error toast message
//   //     toast.error("Failed to cancel subscription");
//   //     console.error("Error canceling subscription:", error);
//   //   }
//   // };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem.product_id === id);

//     return filter?.length > 0 ? filter[0]?.id : "";
//   };

//   return (
//     <div className="">
//       {productData.map((product, index) => {
//         const items = JSON.parse(product.items);
//         return (
//           <div className="custom-subscribed-products">
//             <div className="row">

//               {items.map((item, itemIndex) => {
//                 const itemProductID = item.product_id;
//                 const isWishForItem = checkWish(itemProductID);
//                 return (
//                   <div key={itemIndex} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
//                     <div className="product-card">


//                       <div className="prod-img">
//                         <img
//                           className="image-main"
//                           src={item.thumbnail_name ?
//                             `${IMAGEURL}upload/product/thumbnail/400/${item.image}` :
//                             `${IMAGEURL}${item.image}`}
//                           alt="ProductImage"
//                         />
//                       </div>

//                       <div className="over-layer">
//                         <div className="pr-3 pt-3">
//                           <div className="pt-2 pb-1">
//                             {isWishForItem ? (
//                               <Link onClick={() => handleDeleteWishlist(isWishForItem)}>
//                                 <i className="fas fa-heart red-color"></i>
//                               </Link>
//                             ) : (
//                               <Link onClick={() => handleAddToWishList(itemProductID)}>
//                                 <i className="far fa-heart gray-color"></i>
//                               </Link>
//                             )}
//                           </div>
//                           <div className="pt-2 pb-1">
//                             <Link to={`/product-detail/${items[0].slug}`}>
//                               <i className="far fa-eye gray-color"></i>
//                             </Link>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="prod-content">
//                         <p className="price-text mt-3 mb-2">
//                           <span>US ${item.price}</span>
//                         </p>
//                         <p className="prod-description pb-1">{item.name}</p>
//                         <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                           Quantity <strong>{item.qty}</strong>
//                         </p>

//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>

//             <hr />

//             <div className="subs-details-cancel">
//               {/* <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product.subscription_no)}>
//                 <Link to="" className="addCart">
//                   Cancel Subscription
//                 </Link>
//               </button> */}
//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 Subscription Cancelled On <strong>{product.next_date}</strong>
//               </p>
//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 <strong>{product.month} </strong> month's subscription
//               </p>
//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 Subscription No <strong>{product.subscription_no}</strong>
//               </p>

//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 Total Subscription Amount <strong>{product.grand_total}</strong>
//               </p>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default CancelProductCard;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { AiFillStar } from "react-icons/ai";
// import authApi from "../../../../utils/authApi";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { fetchWishlist } from "../../../../redux/productSlice";
// import Moment from 'react-moment';
// const CancelProductCard = () => {
//   const [productData, setProductData] = useState([]);
//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const dispatch = useDispatch();
//   const wishlist = useSelector((state) => state.products.wishlist);


//   const handleAddToWishList = async (id) => {
//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("Product added to wishlist successfully.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     }
//   };

//   const handleDeleteWishlist = async (id) => {
//     if (id && user_id) {
//       try {
//         await axios.delete(`${APIBASE}wishlists/${id}`);
//         toast.success("Item deleted from wishlist.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error", { autoClose: 300 });
//       }
//     }
//   };


//   const UnSubscribedProducts = async () => {
//     authApi
//       .get("customer/get-cancelled-subscription")
//       .then((response) => {
//         const res = response.data.data.data;
//         setProductData(res);
//         // comsole.log(error);("cancelled", res);
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   }
//   useEffect(() => {
//     UnSubscribedProducts()
//   }, []);

//   // comsole.log(error);("products to get", productData);

//   // const handleCancelSubscription = async (subscriptionId) => {
//   //   try {
//   //     const payload = {
//   //       subscription_id: subscriptionId,
//   //     };

//   //     const response = await authApi.post("customer/cancel-subscription", payload);

//   //     // comsole.log(error);(response);

//   //     // Show a success toast message
//   //     toast.success("Subscription canceled successfully");

//   //     UnSubscribedProducts();
//   //     // comsole.log(error);(productData);
//   //   } catch (error) {
//   //     // Show an error toast message
//   //     toast.error("Failed to cancel subscription");
//   //     console.error("Error canceling subscription:", error);
//   //   }
//   // };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem.product_id === id);

//     return filter?.length > 0 ? filter[0]?.id : "";
//   };

//   return (
//     <div className="row">
//       {productData.map((product, index) => {
//         const items = JSON.parse(product.items);
//         return (
//           <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
//             {items.map((item, itemIndex) => {
//               const itemProductID = item.product_id;
//               const isWishForItem = checkWish(itemProductID);
//               return (
//                 <div key={itemIndex} className="product-card">
//                   <div className="prod-img">
//                     <img
//                       className="image-main"
//                       src={item.thumbnail_name ?
//                         `upload/product/thumbnail/100/${item.image}` :
//                         `${IMAGEURL}${item.image}`}
//                       alt="ProductImage"
//                     />
//                   </div>
//                   <div className="over-layer">
//                     <div className="pr-3 pt-3">
//                       <div className="pt-2 pb-1">
//                         {/* <Link to="">
//                       <i className="far fa-heart gray-color"></i>
//                     </Link> */}
//                         {isWishForItem ? (
//                           <Link onClick={() => handleDeleteWishlist(isWishForItem)}>
//                             <i className="fas fa-heart red-color"></i>
//                           </Link>
//                         ) : (
//                           <Link onClick={() => handleAddToWishList(itemProductID)}>
//                             <i className="far fa-heart gray-color"></i>
//                           </Link>
//                         )}
//                       </div>
//                       <div className="pt-2 pb-1">
//                         <Link to={`/product-detail/${items[0].slug}`}>
//                           <i className="far fa-eye gray-color"></i>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="prod-content">
//                     {/* <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product.subscription_no)}>
//                       <Link to="" className="addCart">
//                         Cancel Subscription
//                       </Link>
//                     </button> */}
//                     <p className="price-text mt-3 mb-2">
//                       <span>US ${item.price}</span>
//                     </p>
//                     <p className="prod-description pb-1">{item.name}</p>
//                     <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                       Subscription Cancelled on
//                     </p>
//                     <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                       <strong><Moment format="YYYY-MM-DD">{product.updated_at}</Moment></strong>
//                     </p>
//                     <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                       <strong>{product.month} </strong> month's subscription
//                     </p>
//                     <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                       Subscription No <strong>{product.subscription_no}</strong>
//                     </p>
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default CancelProductCard;


// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { AiFillStar } from "react-icons/ai";
// import authApi from "../../../../utils/authApi";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { toast } from "react-toastify";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchWishlist } from "../../../../redux/productSlice";
// import axios from "axios";

// const CancelProductCard = () => {
//   const [productData, setProductData] = useState([]);
//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const dispatch = useDispatch();
//   const wishlist = useSelector((state) => state.products.wishlist);


//   useEffect(() => {
//     authApi
//       .get("customer/get-cancelled-subscription")
//       .then((response) => {
//         const res = response.data.data.data;
//         setProductData(res);
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   }, []);

//   // const handleCancelSubscription = async (subscriptionId) => {
//   //   try {
//   //     const payload = {
//   //       subscription_id: subscriptionId,
//   //     };

//   //     const response = await authApi.post("customer/cancel-subscription", payload);

//   //     // comsole.log(error);(response);

//   //     // Show a success toast message
//   //     toast.success("Subscription canceled successfully");
//   //     productData()
//   //   } catch (error) {
//   //     // Show an error toast message
//   //     toast.error("Failed to cancel subscription");
//   //     console.error("Error canceling subscription:", error);
//   //   }
//   // };

//   const handleAddToWishList = async (id) => {
//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("Product added to wishlist successfully.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     }
//   };

//   const handleDeleteWishlist = async (id) => {
//     if (id && user_id) {
//       try {
//         await axios.delete(`${APIBASE}wishlists/${id}`);
//         toast.success("Item deleted from wishlist.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error", { autoClose: 300 });
//       }
//     }
//   };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem.product_id === id);

//     return filter?.length > 0 ? filter[0]?.id : "";
//   };

//   return (
//     <div className="row">
//       {productData.map((product, index) => {
//         const items = JSON.parse(product.items);
//         const itemProductID = items[0]?.product_id;
//         const isWishForItem = checkWish(itemProductID);

//         return (
//           <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
//             <div className="product-card ">
//               <div className="prod-img">
//                 {/* <img
//                   className="image-main"
//                   src={`${IMAGEURL}${items[0].image}`}
//                   alt="ProductImage"
//                 /> */}
//                 <img
//                   className="image-main"
//                   src={items[0]?.thumbnail_name ?
//                     `upload/product/thumbnail/300/${items[0].image}` :
//                     `${IMAGEURL}${items[0].image}`}
//                   alt="ProductImage"
//                 />

//               </div>
//               <div className="over-layer">
//                 <div className="pr-3 pt-3">
//                   <div className="pt-2 pb-1">
//                     {/* <Link to="">
//                       <i className="far fa-heart gray-color"></i>
//                     </Link> */}
//                     {isWishForItem ? (
//                       <Link onClick={() => handleDeleteWishlist(isWishForItem)}>
//                         <i className="fas fa-heart red-color"></i>
//                       </Link>
//                     ) : (
//                       <Link onClick={() => handleAddToWishList(itemProductID)}>
//                         <i className="far fa-heart gray-color"></i>
//                       </Link>
//                     )}
//                   </div>
//                   <div className="pt-2 pb-1">
//                     <Link to={`/product-detail/${items[0].slug}`}>
//                       <i className="far fa-eye gray-color"></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="prod-content">
//                 {/* <button className="prod-cart mt-2 mb-2"
//                 // onClick={() => handleCancelSubscription(product.subscription_no)}
//                 >
//                   <Link to="" className="addCart">
//                     Cancel Subscription
//                   </Link>
//                 </button> */}

//                 <p className="price-text mt-3 mb-2">
//                   <span>US ${items[0].price}</span>
//                 </p>

//                 <p className="prod-description pb-1">{items[0].name}</p>

//                 <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                   Subscription Cancelled on
//                 </p>
//                 <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                   <strong>{product.next_date}</strong>
//                 </p>


//                 <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                   <strong>{product.month} </strong> month's subscription
//                 </p>

//                 {/* <div className="avail-text">
//                   <div className="text-left prod-code">
//                     <p>{product?.sku}</p>
//                   </div>

//                   <div className="text-right">
//                     <span className="rating-star">
//                       0 <AiFillStar /> | 1
//                     </span>
//                   </div>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default CancelProductCard;
