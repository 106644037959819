// // import React, { useState } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import { register } from "../../redux/userAuthSlice";
// // import { useDispatch } from "react-redux";
// // import "./Login.css";

// // export default function Signup() {
// //   const navigate = useNavigate();
// //   const dispatch = useDispatch();
// //   // const loading = useSelector((state) => state.userAuth.loading);
// //   // const error = useSelector((state) => state.userAuth.error);

// //   const [formData, setFormData] = useState({});
// //   const handleChange = (event) => {
// //     setFormData({ ...formData, [event.target.name]: event.target.value });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     dispatch(register(formData))
// //       .then((response) => {
// //         if (response.payload?.message) {
// //           navigate("/login");
// //         }
// //       })
// //       .catch((err) => comsole.log(err));
// //   };

// //   return (
// //     <section>
// //       <div className="container-side-space">
// //         <div className="signup-form ptb-5 ptb-md-3">
// //           <div className="heading text-center pb-4">
// //             <h3 className="border-0">Register Here </h3>
// //           </div>

// //           <div className="col-lg-6 col-md-10 col-sm-12 m-auto bg-gary">
// //             <div className="form-layer-bg">
// //               <form onSubmit={handleSubmit}>
// //                 <div className="form-row-3">
// //                   <div className="form-group">
// //                     <label htmlFor="first_name">
// //                       First Name <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="first_name"
// //                       name="first_name"
// //                       value={formData?.first_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="middle_name">Middle Name :</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="middle_name"
// //                       name="middle_name"
// //                       value={formData?.middle_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="last_name">
// //                       Last Name <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="last_name"
// //                       name="last_name"
// //                       value={formData?.last_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="phone">
// //                     Contact No. <span className="text-danger">*</span>:
// //                   </label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="phone"
// //                     name="phone"
// //                     value={formData?.phone || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="email">
// //                     Email <span className="text-danger">*</span>:
// //                   </label>
// //                   <input
// //                     type="email"
// //                     className="form-control"
// //                     id="email"
// //                     name="email"
// //                     value={formData?.email || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 {/* <div className="form-group">
// //                   <label htmlFor="confirm_email">Confirm Email :</label>
// //                   <input
// //                     type="email"
// //                     className="form-control"
// //                     id="confirm_email"
// //                     name="confirm_email"
// //                     value={formData?.confirm_email || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div> */}

// //                 <div className="form-group">
// //                   <label htmlFor="address">Phone :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>
// //                 <div
// //                   className="form-row-3"
// //                   style={{ gridTemplateColumns: "1fr 1fr" }}
// //                 >
// //                   <div className="form-group">
// //                     <label htmlFor="first_name">
// //                       Flat/Apartment <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="first_name"
// //                       name="first_name"
// //                       value={formData?.first_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="middle_name">City :</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="middle_name"
// //                       name="middle_name"
// //                       value={formData?.middle_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>
// //                 {/* <div className="form-group">
// //                   <label htmlFor="address">Flat/Apartment :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>
// //                 <div className="form-group">
// //                   <label htmlFor="address">Address :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div> */}
// //                 <div className="form-group">
// //                   <label htmlFor="address">Address :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 {/* <div className="form-group">
// //                   <label htmlFor="address">Street :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div> */}
// //                 <div className="form-row-3">
// //                   <div className="form-group">
// //                     <label htmlFor="first_name">
// //                       Street <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="first_name"
// //                       name="first_name"
// //                       value={formData?.first_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="middle_name">Zip :</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="middle_name"
// //                       name="middle_name"
// //                       value={formData?.middle_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="last_name">
// //                       Country <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="last_name"
// //                       name="last_name"
// //                       value={formData?.last_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 {/* <div className="form-group">
// //                   <label htmlFor="address">Zip :</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData?.address || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>
// //                 <div className="form-group">
// //                   <label htmlFor="country">Country :</label>
// //                   <select
// //                     className="form-control"
// //                     id="country"
// //                     name="country"
// //                     value={formData?.country || ""}
// //                     onChange={handleChange}
// //                   >
// //                     <option value="">Select Country</option>

// //                     <option value="Bermuda">Bermuda</option>
// //                   </select>
// //                 </div> */}
// //                 <div
// //                   className="form-row-3"
// //                   style={{ gridTemplateColumns: "1fr 1fr" }}
// //                 >
// //                   <div className="form-group">
// //                     <label htmlFor="first_name" style={{ fontWeight: "500" }}>
// //                       Password <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="first_name"
// //                       name="first_name"
// //                       value={formData?.first_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="last_name" style={{ fontWeight: "500" }}>
// //                       Confirm Password <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="last_name"
// //                       name="last_name"
// //                       value={formData?.last_name || ""}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>
// //                 {/* <div className="form-group">
// //                   <label htmlFor="password" style={{fontWeight:"500"}}>Password <span className="text-danger">*</span>:</label>
// //                   <input
// //                     type="password"
// //                     className="form-control"
// //                     id="password"
// //                     name="password"
// //                     value={formData?.password || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="confirm_password" style={{fontWeight:"500"}}>Confirm Password <span className="text-danger">*</span>:</label>
// //                   <input
// //                     type="password"
// //                     className="form-control"
// //                     id="password_confirmation"
// //                     name="password_confirmation"
// //                     value={formData?.password_confirmation || ""}
// //                     onChange={handleChange}
// //                   />
// //                 </div> */}

// //                 <div className="text-center pt-2">
// //                   <button type="submit" className="btn btn-theme">
// //                     Register
// //                   </button>
// //                 </div>
// //               </form>

// //               <p className="login-text pt-3">
// //                 Do You Have Already An Account? &nbsp;
// //                 <Link className="ancher-text" to="/Login">
// //                   Login
// //                 </Link>
// //               </p>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // }

// // import React, { useState } from "react";
// // import { Link, useNavigate } from "react-router-dom";
// // import { register } from "../../redux/userAuthSlice";
// // import { useDispatch } from "react-redux";
// // import "./Login.css";

// // export default function Signup() {
// //   const navigate = useNavigate();
// //   const dispatch = useDispatch();

// //   const initialFormData = {
// //     first_name: "",
// //     middle_name: "",
// //     last_name: "",
// //     phone: "",
// //     email: "",
// //     address: "",
// //     flat_apartment: "",
// //     city: "",
// //     street: "",
// //     zip: "",
// //     country: "",
// //     password: "",
// //     confirm_password: "",
// //   };

// //   const [formData, setFormData] = useState(initialFormData);
// //   const [formErrors, setFormErrors] = useState({});
// //   const validateForm = () => {
// //     const errors = {};
// //     if (!formData.first_name) errors.first_name = "First Name is required";
// //     if (!formData.last_name) errors.last_name = "Last Name is required";
// //     if (!formData.email) errors.email = "Email is required";
// //     if (!formData.city) errors.city = "City is required";
// //     if (!formData.phone) errors.phone = "Phone is required";
// //     if (!formData.country) errors.country = "Country is required";
// //     if (!formData.zip) errors.zip = "ZIP is required";
// //     if (!formData.password) errors.password = "Password is required";
// //     if (!formData.confirm_password) errors.confirm_password = "Confirm Password is required";

// //     setFormErrors(errors);
// //     return Object.keys(errors)?.length === 0;
// //   };
// //   const handleChange = (event) => {
// //     const { name, value } = event.target;
// //     setFormData({ ...formData, [name]: value });
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();
// //     comsole.log("formData",formData)
// //     if (validateForm()) {
// //       // Dispatch your registration logic here
// //       comsole.log("Form submitted:", formData);
// //       // dispatch(register(formData))
// //       //   .then((response) => {
// //       //     if (response.payload?.message) {
// //       //       navigate("/login");
// //       //     }
// //       //   })
// //       //   .catch((err) => comsole.log(err));
// //     }
// //   };

// //   return (
// //     <section>
// //       <div className="container-side-space">
// //         <div className="signup-form ptb-5 ptb-md-3">
// //           <div className="heading text-center pb-4">
// //             <h3 className="border-0">Register Here</h3>
// //           </div>
// //           <div className="error-messages">
// //             {/* Display validation errors here */}
// //             {Object.keys(formErrors).map((key) => (
// //               <p key={key} className="error-message">{formErrors[key]}</p>
// //             ))}
// //           </div>
// //           <div className="col-lg-6 col-md-10 col-sm-12 m-auto bg-gary">
// //             <div className="form-layer-bg">
// //               <form onSubmit={handleSubmit}>
// //                 <div className="form-row-3">
// //                   <div className="form-group">
// //                     <label htmlFor="first_name">
// //                       First Name <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="first_name"
// //                       name="first_name"
// //                       value={formData.first_name}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="middle_name">Middle Name:</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="middle_name"
// //                       name="middle_name"
// //                       value={formData.middle_name}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="last_name">
// //                       Last Name <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="last_name"
// //                       name="last_name"
// //                       value={formData.last_name}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="phone">
// //                     Contact No. <span className="text-danger">*</span>:
// //                   </label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="phone"
// //                     name="phone"
// //                     value={formData.phone}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="email">
// //                     Email <span className="text-danger">*</span>:
// //                   </label>
// //                   <input
// //                     type="email"
// //                     className="form-control"
// //                     id="email"
// //                     name="email"
// //                     value={formData.email}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="form-group">
// //                   <label htmlFor="address">Address:</label>
// //                   <input
// //                     type="text"
// //                     className="form-control"
// //                     id="address"
// //                     name="address"
// //                     value={formData.address}
// //                     onChange={handleChange}
// //                   />
// //                 </div>

// //                 <div className="form-row-3"  style={{ gridTemplateColumns: "1fr 1fr" }}>
// //                   <div className="form-group">
// //                     <label htmlFor="flat_apartment">
// //                       Flat/Apartment <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="flat_apartment"
// //                       name="flat_apartment"
// //                       value={formData.flat_apartment}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="city">City:</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="city"
// //                       name="city"
// //                       value={formData.city}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 <div className="form-row-3">
// //                   <div className="form-group">
// //                     <label htmlFor="street">
// //                       Street <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="street"
// //                       name="street"
// //                       value={formData.street}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="zip">Zip:</label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="zip"
// //                       name="zip"
// //                       value={formData.zip}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="country">
// //                       Country <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="text"
// //                       className="form-control"
// //                       id="country"
// //                       name="country"
// //                       value={formData.country}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 <div className="form-row-3"  style={{ gridTemplateColumns: "1fr 1fr" }}>
// //                   <div className="form-group">
// //                     <label htmlFor="password">
// //                       Password <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="password"
// //                       className="form-control"
// //                       id="password"
// //                       name="password"
// //                       value={formData.password}
// //                       onChange={handleChange}
// //                     />
// //                   </div>

// //                   <div className="form-group">
// //                     <label htmlFor="confirm_password">
// //                       Confirm Password{" "}
// //                       <span className="text-danger">*</span>:
// //                     </label>
// //                     <input
// //                       type="password"
// //                       className="form-control"
// //                       id="confirm_password"
// //                       name="confirm_password"
// //                       value={formData.confirm_password}
// //                       onChange={handleChange}
// //                     />
// //                   </div>
// //                 </div>

// //                 <div className="text-center pt-2">
// //                   <button type="submit" className="btn btn-theme">
// //                     Register
// //                   </button>
// //                 </div>
// //               </form>

// //               <p className="login-text pt-3">
// //                 Do You Have Already An Account? &nbsp;
// //                 <Link className="ancher-text" to="/Login">
// //                   Login
// //                 </Link>
// //               </p>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </section>
// //   );
// // }

// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { register } from "../../redux/userAuthSlice"; // Uncomment this when you have the actual register function
// import { useDispatch } from "react-redux";
// import "./Login.css";
// import { toast } from "react-toastify";

// export default function Signup() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   const initialFormData = {
//     first_name: "",
//     middle_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     address: "",

//     city: "",

//     zip: "",
//     country: "",
//     password: "",
//     confirm_password: "",
//   };

//   const [formData, setFormData] = useState(initialFormData);
//   const [formErrors, setFormErrors] = useState({});

//   const validateForm = () => {
//     const errors = {};
//     if (!formData.first_name) errors.first_name = "First Name is required";
//     if (!formData.last_name) errors.last_name = "Last Name is required";
//     if (!formData.email) errors.email = "Email is required";
//     if (!formData.city) errors.city = "City is required";
//     if (!formData.phone) errors.phone = "Phone is required";
//     if (!formData.country) errors.country = "Country is required";
//     if (!formData.address) errors.address = "Address is required";
//     if (!formData.zip) errors.zip = "ZIP is required";
//     if (!formData.password) errors.password = "Password is required";
//     if (!formData.confirm_password)
//       errors.confirm_password = "Confirm Password is required";

//     setFormErrors(errors);
//     return Object.keys(errors)?.length === 0;
//   };

//   const handleChange = (event) => {
//     const { name, value } = event.target;

//     if (name === "zip" && value.length <= 6) {
//       setFormData({ ...formData, [name]: value });
//     } else {
//       setFormData({ ...formData, [name]: value });
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (validateForm()) {
//       // Dispatch your registration logic here
//       comsole.log("Form submitted:", formData);
//       dispatch(register(formData))
//         .then((response) => {
//           if (response.payload?.message) {
//             toast.success("User registered successfully.");
//             navigate("/login");
//           }
//         })
//         .catch((err) => comsole.log(err));
//     }
//   };

//   return (
//     <section>
//       <div className="container-side-space">
//         <div className="signup-form ptb-5 ptb-md-3">
//           <div className="heading text-center pb-4">
//             <h3 className="border-0">Register Here</h3>
//           </div>

//           <div className="col-lg-6 col-md-10 col-sm-12 m-auto bg-gary">
//             <div className="form-layer-bg">
//               <form onSubmit={handleSubmit}>
//                 <div className="form-row-3">
//                   <div className="form-group">
//                     <label htmlFor="first_name">
//                       First Name <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="first_name"
//                       name="first_name"
//                       value={formData.first_name}
//                       onChange={handleChange}
//                     />
//                     {formErrors.first_name && (
//                       <p className="error-message">{formErrors.first_name}</p>
//                     )}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="middle_name">Middle Name:</label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="middle_name"
//                       name="middle_name"
//                       value={formData.middle_name}
//                       onChange={handleChange}
//                     />
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="last_name">
//                       Last Name <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="last_name"
//                       name="last_name"
//                       value={formData.last_name}
//                       onChange={handleChange}
//                     />
//                     {formErrors.last_name && (
//                       <p className="error-message">{formErrors.last_name}</p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="phone">
//                     Contact No. <span className="text-danger">*</span>:
//                   </label>
//                   <input
//                     type="number"
//                     className="form-control"
//                     id="phone"
//                     name="phone"
//                     value={formData?.phone}
//                     onChange={handleChange}
//                   />
//                   {formErrors?.phone && (
//                     <p className="error-message">{formErrors?.phone}</p>
//                   )}
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="email">
//                     Email <span className="text-danger">*</span>:
//                   </label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     id="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                   {formErrors.email && (
//                     <p className="error-message">{formErrors.email}</p>
//                   )}
//                 </div>

//                 <div className="form-group">
//                   <label htmlFor="address">Address: <span className="text-danger">*</span></label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     id="address"
//                     name="address"
//                     value={formData.address}
//                     onChange={handleChange}
//                   />
//                   {formErrors.address && (
//                     <p className="error-message">{formErrors.address}</p>
//                   )}
//                 </div>

//                 <div className="form-row-3">
//                   {/* <div className="form-group">
//                     <label htmlFor="flat_apartment">
//                       Flat/Apartment :
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="flat_apartment"
//                       name="flat_apartment"
//                       value={formData.flat_apartment}
//                       onChange={handleChange}
//                     />
//                     {formErrors.flat_apartment && <p className="error-message">{formErrors.flat_apartment}</p>}
//                   </div> */}

//                   <div className="form-group">
//                     <label htmlFor="city">
//                       City <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="city"
//                       name="city"
//                       value={formData.city}
//                       onChange={handleChange}
//                     />
//                     {formErrors.city && (
//                       <p className="error-message">{formErrors.city}</p>
//                     )}
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="zip">
//                       Zip <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="zip"
//                       name="zip"
//                       value={formData.zip}
//                       onChange={(e) => {
//                         if(e.target.value.length<=6){
//                           handleChange(e);
//                         }
                      
//                       }}
//                     />
//                     {formErrors.zip && (
//                       <p className="error-message">{formErrors.zip}</p>
//                     )}
//                   </div>
//                   <div className="form-group">
//                     <label htmlFor="country">
//                       Country <span className="text-danger">*</span>:
//                     </label>
//                     <select
//                       name="country"
//                       className="form-control"
//                       onChange={handleChange}
//                       id="country"
//                       value={formData.country}
//                     >
//                       <option value="">Select Country</option>
//                       <option value="Bermuda">Bermuda</option>
//                     </select>
//                     {/* <input
//                       type="text"
//                       className="form-control"
//                       id="country"
//                       name="country"
//                       value={formData.country}
//                       onChange={handleChange}
//                     /> */}
//                     {formErrors.country && (
//                       <p className="error-message">{formErrors.country}</p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="form-row-2">
//                   <div className="form-group">
//                     <label htmlFor="password">
//                       Password <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       id="password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleChange}
//                     />
//                     {formErrors.password && (
//                       <p className="error-message">{formErrors.password}</p>
//                     )}
//                   </div>

//                   <div className="form-group">
//                     <label htmlFor="confirm_password">
//                       Confirm Password <span className="text-danger">*</span>:
//                     </label>
//                     <input
//                       type="password"
//                       className="form-control"
//                       id="confirm_password"
//                       name="confirm_password"
//                       value={formData.confirm_password}
//                       onChange={handleChange}
//                     />
//                     {formErrors.confirm_password && (
//                       <p className="error-message">
//                         {formErrors.confirm_password}
//                       </p>
//                     )}
//                   </div>
//                 </div>

//                 <div className="text-center pt-2">
//                   <button type="submit" className="btn btn-theme">
//                     Register
//                   </button>
//                 </div>

//                 {/* <div className="error-messages">
//                   {Object.keys(formErrors).map((key) => (
//                     <p key={key} className="error-message">{formErrors[key]}</p>
//                   ))}
//                 </div> */}
//               </form>

//               <p className="login-text pt-3">
//                 Do You Have Already An Account? &nbsp;
//                 <Link className="ancher-text" to="/Login">
//                   Login
//                 </Link>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { register } from "../../redux/userAuthSlice"; // Uncomment this when you have the actual register function
import { useDispatch } from "react-redux";
import "./Login.css";
import { toast } from "react-toastify";

export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialFormData = {
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",

    city: "",

    zipcode: "",
    country: "",
    password: "",
    confirm_password: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});

  const validateForm = () => {
    const errors = {};
    if (!formData.first_name) errors.first_name = "First Name is required";
    if (!formData.last_name) errors.last_name = "Last Name is required";
    if (!formData.email) errors.email = "Email is required";
    if (!formData.city) errors.city = "City is required";
    if (!formData.phone) errors.phone = "Phone is required";
    if (!formData.country) errors.country = "Country is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.zipcode) errors.zipcode = "zipcode is required";
    if (!formData.password) errors.password = "Password is required";
    if (!formData.confirm_password)
      errors.confirm_password = "Confirm Password is required";

    setFormErrors(errors);
    return Object.keys(errors)?.length === 0;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "zipcode" && value.length <= 6) {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  //   const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     // Dispatch your registration logic here
  //     comsole.log("Form submitted:", formData);
  //     dispatch(register(formData))
  //       .then((response) => {
  //         if (response.payload?.message) {
  //           toast.success("User registered successfully.");
  //           navigate("/login");
  //         }
  //         comsole.log("register resp", response);
  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.data && err.response.data.errors) {
  //           const errors = err.response.data.errors;

  //           for (const field in errors) {
  //             if (errors.hasOwnProperty(field)) {
  //               errors[field].forEach((errorMessage) => {
  //                 toast.error(`${field}: ${errorMessage}`);
  //               });
  //             }
  //           }
  //         } else if (err.response || err.response.data || err.response.data.message) {
  //           // If there is a single error message in the response, display it as a toast
  //           toast.error(err.response?.data?.payload.errors);
  //         } else {
  //           toast.error("An error occurred.");
  //           console.error("Registration error:", err);
  //         }
  //       });
  //   }
  // };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     dispatch(register(formData))
  //       .then((response) => {
  //         if (response.payload?.message) {
  //           toast.success("User registered successfully.");
  //           navigate("/login");
  //         }
  //         comsole.log("register response", response);
  //       })
  //       .catch((err) => {
  //         if (err.payload || err.payload.errors) {
  //           const errors = err.payload.errors;

  //           for (const field in errors) {
  //             if (errors.hasOwnProperty(field)) {
  //               errors[field].forEach((errorMessage) => {
  //                 toast.error(`${field}: ${errorMessage}`);
  //               });
  //             }
  //           }
  //         } else {
  //           toast.error("An error occurred.");
  //           console.error("Registration error:", err);
  //         }
  //       });
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     // Dispatch your registration logic here
  //     comsole.log("Form submitted:", formData);
  //     dispatch(register(formData))
  //       .then((response) => {
  //         if (response.payload?.message) {
  //           toast.success("User registered successfully.");
  //           navigate("/login");
  //         }
  //       })
  //       .catch((err) => comsole.log(err));
  //   }
  // };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (validateForm()) {
  //     // Dispatch your registration logic here
  //     comsole.log("Form submitted:", formData);
  //     dispatch(register(formData))
  //       .then((response) => {
  //         if (response.payload?.message) {
  //           toast.success("User registered successfully.");
  //           navigate("/login");
  //         }
  //         comsole.log("register resp", response);
  //       })
  //       .catch((err) => {
  //         if (err.response && err.response.data && err.response.data.errors) {
  //           const errors = err.response.data.errors;

  //           for (const field in errors) {
  //             if (errors.hasOwnProperty(field)) {
  //               errors[field].forEach((errorMessage) => {
  //                 toast.error(`${field}: ${errorMessage}`);
  //               });
  //             }
  //           }
  //         } else if (err.response || err.response.data || err.response.data.message) {
  //           // If there is a single error message in the response, display it as a toast
  //           toast.error(err.response?.data?.payload.errors);
  //         } else {
  //           toast.error("An error occurred.");
  //           console.error("Registration error:", err);
  //         }
  //       });
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Dispatch your registration logic here
      setIsLoading(true);
      dispatch(register(formData))
        .then((response) => {
          if (response.payload?.message) {
            navigate("/login");
            setIsLoading(false);
          }
          // console.log("register response", response);
        })
        .catch((err) => {
          // console.log(err);
          setIsLoading(false);
          toast.error("Please check if email or phone is not already registered")
        });
    }
  };

  return (
    <section>
      <div className="container-side-space">
        <div className="signup-form ptb-5 ptb-md-3">
          <div className="heading text-center pb-4">
            <h3 className="border-0">Register Here</h3>
          </div>

          <div className="col-lg-6 col-md-10 col-sm-12 m-auto bg-gary">
            <div className="form-layer-bg">
              <form onSubmit={handleSubmit}>
                <div className="form-row-3">
                  <div className="form-group">
                    <label htmlFor="first_name">
                      First Name <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="first_name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleChange}
                    />
                    {formErrors.first_name && (
                      <p className="error-message">{formErrors.first_name}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="middle_name">Middle Name:</label>
                    <input
                      type="text"
                      className="form-control"
                      id="middle_name"
                      name="middle_name"
                      value={formData.middle_name}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="last_name">
                      Last Name <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="last_name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleChange}
                    />
                    {formErrors.last_name && (
                      <p className="error-message">{formErrors.last_name}</p>
                    )}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="phone">
                    Contact No. <span className="text-danger">*</span>:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData?.phone}
                    onChange={handleChange}
                  />
                  {formErrors?.phone && (
                    <p className="error-message">{formErrors?.phone}</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="email">
                    Email <span className="text-danger">*</span>:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {formErrors.email && (
                    <p className="error-message">{formErrors.email}</p>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="address">
                    Address: <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                  {formErrors.address && (
                    <p className="error-message">{formErrors.address}</p>
                  )}
                </div>

                <div className="form-row-3">
                  {/* <div className="form-group">
                    <label htmlFor="flat_apartment">
                      Flat/Apartment :
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="flat_apartment"
                      name="flat_apartment"
                      value={formData.flat_apartment}
                      onChange={handleChange}
                    />
                    {formErrors.flat_apartment && <p className="error-message">{formErrors.flat_apartment}</p>}
                  </div> */}

                  <div className="form-group">
                    <label htmlFor="city">
                      City <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {formErrors.city && (
                      <p className="error-message">{formErrors.city}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="zipcode">
                      zipcode <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="zipcode"
                      name="zipcode"
                      value={formData.zipcode}
                      onChange={(e) => {
                        if (e.target.value.length <= 6) {
                          handleChange(e);
                        }
                      }}
                    />
                    {formErrors.zipcode && (
                      <p className="error-message">{formErrors.zipcode}</p>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="country">
                      Country <span className="text-danger">*</span>:
                    </label>
                    <select
                      name="country"
                      className="form-control"
                      onChange={handleChange}
                      id="country"
                      value={formData.country}
                    >
                      <option value="">Select Country</option>
                      <option value="Bermuda">Bermuda</option>
                    </select>
                    {/* <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    /> */}
                    {formErrors.country && (
                      <p className="error-message">{formErrors.country}</p>
                    )}
                  </div>
                </div>

                <div className="form-row-2">
                  <div className="form-group">
                    <label htmlFor="password">
                      Password <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {formErrors.password && (
                      <p className="error-message">{formErrors.password}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="confirm_password">
                      Confirm Password <span className="text-danger">*</span>:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirm_password"
                      name="confirm_password"
                      value={formData.confirm_password}
                      onChange={handleChange}
                    />
                    {formErrors.confirm_password && (
                      <p className="error-message">
                        {formErrors.confirm_password}
                      </p>
                    )}
                  </div>
                </div>

                <div className="text-center pt-2">
                  <button disabled={isLoading} type="submit" className="btn btn-theme">
                    {isLoading ? "Registering" : "Register"}
                  </button>
                </div>

                {/* <div className="error-messages">
                  {Object.keys(formErrors).map((key) => (
                    <p key={key} className="error-message">{formErrors[key]}</p>
                  ))}
                </div> */}
              </form>

              <p className="login-text pt-3">
                Do You Have Already An Account? &nbsp;
                <Link className="ancher-text" to="/Login">
                  Login
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
