import React from "react";
import "./ProductTab.css";

export default function ProductTab({ description, additional }) {
  return (
    <>
      <ul className="nav nav-pills" role="tablist">
        <li className="nav-item">
          <a
            className="nav-link text-capitalize active"
            data-toggle="pill"
            href="#description"
          >
            Description
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link text-capitalize"
            data-toggle="pill"
            href="#information"
          >
            Additional information
          </a>
        </li>
      </ul>

      <div className="tab-content">
        <div id="description" className="tab-pane active">
          <br />

          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        <div id="information" className=" tab-pane fade">
          <br />

          <p dangerouslySetInnerHTML={{ __html: additional }} />
        </div>
      </div>
    </>
  );
}
