import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './SubscribedProducts.css';
import SubscribedProductCard from '../../Dashboard/SubscribedProductCard';
import DelevariesProductCard from '../../Dashboard/DelevariesProductCard';
import AddNewSubscribeProduct from './AddNewSubscribeProduct';
import CancelProductCard from '../../Dashboard/CancelledProductCard';


const clickedButtonStyle = {
  fontWeight: '550',
  borderBottom: '3px solid #EF233C'
}

const SubscribedProducts = () => {

  // top buttons useState use
  const [showTopButtonResults, setShowTopButtonResults] = useState([true, false])
  const [showTopButtonStyles, setShowTopButtonStyles] = useState([clickedButtonStyle, {}])

  // bottom buttons useState use
  const [showBottomButtonResults, setShowBottomButtonResults] = useState([true, false]);
  const [showBottomButtonStyles, setShowBottomButtonStyles] = useState([clickedButtonStyle, {}])

  // with this handler onClick button Style will change
  const orderButtonsHandler = (buttonText) => {
    if (buttonText === 'Deliveries') {
      setShowTopButtonResults([true, false])
      setShowTopButtonStyles([clickedButtonStyle, {}])
    }
    if (buttonText === 'Subscription') {
      setShowTopButtonResults([false, true])
      setShowTopButtonStyles([{}, clickedButtonStyle])
    }
    if (buttonText === 'Active subscription') {
      setShowBottomButtonResults([true, false])
      setShowBottomButtonStyles([clickedButtonStyle, {}])
    }
    if (buttonText === 'Cancelled subscription') {
      setShowBottomButtonResults([false, true])
      setShowBottomButtonStyles([{}, clickedButtonStyle])
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='main-heading py-2'>
            <h3>Subscribe & Save</h3>
          </div>
          {/* Divider Below heading is here */}
          <div className='divider' />

          {/* Three buttons below divider is here */}
          <div className='py-4 d-flex justify-content-start align-items-center'>
            {['Deliveries', 'Subscription'].map((ele, index) => {
              return (
                <Link to="#"
                  key={index}
                  onClick={(e) => orderButtonsHandler(ele)}
                  className='order-tabs'
                  style={showTopButtonStyles[index]}
                >
                  {ele}
                </Link>
              )
            })}
          </div>
          {/* Three buttons below divider ends here  */}

          {/* Divider Below buttons is here */}


          {/* Subscription display below buttons is here */}
          <div className='subscibe-button-result p-4'>
            {/* Deliveries button result is here */}

            {showTopButtonResults[0] && (
              <div >

                {/*start  ProductCard */}
                <DelevariesProductCard />
                {/* <ProductCard/> */}
                {/* end ProductCard */}

                {/* AddNewSubscribeProduct */}
                <AddNewSubscribeProduct />
                {/* AddNewSubscribeProduct */}

              </div>


            )}


            {/* subscription button result is here */}
            {showTopButtonResults[1] && (
              <>
                <div className='d-flex justify-content-start align-items-center'>
                  {['Active subscription', 'Cancelled subscription'].map((ele, index) => {
                    return (
                      <Link to="#"
                        key={index}
                        onClick={(e) => orderButtonsHandler(ele)}
                        className='order-tabs'
                        style={showBottomButtonStyles[index]}
                      >
                        {ele}
                      </Link>
                    )
                  })}
                </div>

                {/* Divider Below buttons is here */}
                <div className='divider' />

                {showBottomButtonResults[0] && (
                  <div className='pt-4'>

                    {/*start  ProductCard */}
                    <SubscribedProductCard />
                    {/* end ProductCard */}

                    {/* AddNewSubscribeProduct */}
                    <div className='row'><AddNewSubscribeProduct /></div>

                    {/* AddNewSubscribeProduct */}
                  </div>
                )}

                {showBottomButtonResults[1] && (
                  <div className='pt-4'>

                    {/*start  ProductCard */}
                    <CancelProductCard />
                    {/* end ProductCard */}

                  </div>
                )}
              </>

            )}
          </div>
        </div>
      </div>

    </>
  )
}

export default SubscribedProducts;
