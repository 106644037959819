import React from "react";
import { Link } from "react-router-dom";
import { clearCart } from "../../../redux/userCartSlice";
import { useDispatch } from "react-redux";

export default function ProceedBtn({ total, count }) {
  const dispatch = useDispatch();

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  return (
    <>
      <div className="cart-details-left">
        <h3>
          Subtotal ({count} items) : <strong>${total?.toFixed(2)}</strong>
        </h3>
        <div className="continue-shopping-butotn mt-3">
          {/* <Link to="/shipping" className="btn btn-yellow"> */}
          <Link to="/confirm-order" className="btn btn-yellow">
            Proceed To Checkout
            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
          </Link>
          <button
            style={{ width: "100%" }}
            className="btn btn-theme mt-3"
            onClick={handleClearCart}
          >
            Clear Cart
          </button>
        </div>
      </div>
    </>
  );
}
