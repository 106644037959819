import BrandList from "./BrandList";
import "./Brand.css";

export default function Brands() {
  return (
    <main>
      <section>
        <div className="container-side-space">
          <div className="brand-wrapper ptb-5">
            <div className="heading text-center">
              <h3 className="border-bottom-0"> All Brand</h3>
            </div>

            <div className="all-brand d-flex flex-wrap mt-5">
              {/* BrandList Component*/}

              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>
              <BrandList></BrandList>

              {/* BrandList Component*/}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
