import React, { useState, useEffect } from "react";
import authApi from "../../../../utils/authApi";
const OrderDetails = ({ orderId }) => {
  // const orderId = 213;
  //   const userId = 1;
  const [orderData, setOrderData] = useState(null);
  //   const [userDetails, setUserDetails] = useState({});
  const [parsedShippingAddress, setParsedShippingAddress] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (orderData && orderData.shipping_address) {
      const parsedAddress = JSON.parse(orderData.shipping_address);
      setParsedShippingAddress(parsedAddress);
    }
  }, [orderData]);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await authApi.get(`/customer/order/detail/${orderId}`);

        if (response.status === 200) {
          setOrderData(response.data.data);
          if (response.data.data?.shipping_address) {
            const parsedAddress = JSON.parse(
              response.data.data.shipping_address
            );
            setParsedShippingAddress(parsedAddress);
          }
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
      } finally {
        setIsLoading(false); // Set loading to false whether request succeeds or fails
      }
    };

    fetchOrderData();
  }, []);

  // console.log("clicked curr order data", orderData);

  if (isLoading) {
    return <div className="text-center" style={{ marginBottom: "2rem" }}>Loading Order details...</div>;
  }
  return (
    <div className="order-details p-3">
      <div className="text-box">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <h3>Shipping Address</h3>
            <address>
              <strong>
                {parsedShippingAddress?.first_name} ,{" "}
                {parsedShippingAddress?.middle_name},{" "}
                {parsedShippingAddress?.last_name}
              </strong>
              <br />
              <p>
                {parsedShippingAddress?.apartment_flat},{" "}
                {parsedShippingAddress?.street},{" "}
                {parsedShippingAddress?.address},{" "}
              </p>
              {/* <br /> */}
              <p>
                {parsedShippingAddress?.city}, {parsedShippingAddress?.state},{" "}
                {parsedShippingAddress?.zipcode},{" "}
              </p>
              {/* <br /> */}
              <p>{parsedShippingAddress?.country}</p>
            </address>
          </div>
          <div className="col-md-4 col-sm-12">
            <h3>Payment Method : </h3>
            <p>
              <i className="fa fa-inr" aria-hidden="true"></i>
              <p>{orderData?.payment_method}</p>
            </p>
            <p className="status" style={{ marginTop: "10px" }}>
              <b>Order Status:</b> {orderData?.order_status}
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="pull-right pull-mob mob-mtop">
              <h3>Order Summary</h3>
              <dl>
                <dt>
                  <strong>Item(s) Subtotal:</strong>
                </dt>
                <dd>
                  <span>
                    <i className="fa fa-inr" aria-hidden="true"></i>${" "}
                    {orderData?.sub_total}
                  </span>
                </dd>
                <dt>
                  <strong>Shipping:</strong>
                </dt>
                <dd>
                  <span>
                    <i className="fa fa-inr" aria-hidden="true"></i> ${" "}
                    {orderData?.shipping_charge}
                  </span>
                </dd>
                <dt>
                  <strong>Grand Total:</strong>
                </dt>
                <dd>
                  <span>
                    <i className="fa fa-inr" aria-hidden="true"></i> ${" "}
                    {orderData?.grand_total}
                  </span>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
