import { createSlice } from "@reduxjs/toolkit";

const userCartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalCount: 0,
    subTotal: 0,
  },
  reducers: {
    addToCart: (state, action) => {
      const newItem = {
        ...action.payload,
        rowId: generateRowId(),
      };

      const existingItemIndex = state.items.findIndex(
        (item) =>
          item.id === newItem.id &&
          JSON.stringify(item.options) === JSON.stringify(newItem.options) &&
          JSON.stringify(item.subscription) ===
            JSON.stringify(newItem.subscription)
      );

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex].qty += newItem.qty;
        // if (newItem.subscription) {
        //   state.items[existingItemIndex].subscription = newItem.subscription;
        // }
      } else {
        state.items.push(newItem);
      }

      state.totalCount += newItem.qty;
      state.subTotal += newItem.qty * newItem.price;
    },
    updateQuantity: (state, action) => {
      const { rowId, quantity } = action.payload;
      const rowIndex = state.items.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        state.items[rowIndex].qty = quantity;

        recalculateTotals(state);
      }
    },
    updateSubscriptionInterval: (state, action) => {
      const { rowId, interval } = action.payload;
      const rowIndex = state.items.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        state.items[rowIndex].subscription.interval = interval;

        recalculateTotals(state);
      }
    },
    incrementQuantity: (state, action) => {
      const { rowId } = action.payload;
      const rowIndex = state.items.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        state.items[rowIndex].qty += 1;
        state.totalCount += 1;
        state.subTotal += state.items[rowIndex].price;
      }
    },
    decrementQuantity: (state, action) => {
      const { rowId } = action.payload;
      const rowIndex = state.items.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        const item = state.items[rowIndex];
        if (item.qty > 1) {
          item.qty -= 1;
          state.totalCount -= 1;
          state.subTotal -= item.price;
        }
      }
    },
    getTotalCount: (state) => state.totalCount,
    getSubtotal: (state) => state.subTotal,
    removeItem: (state, action) => {
      const { rowId } = action.payload;
      const rowIndex = state.items.findIndex((row) => row.rowId === rowId);

      if (rowIndex !== -1) {
        const item = state.items[rowIndex];
        state.totalCount -= item.qty;
        state.subTotal -= item.qty * item.price;
        state.items.splice(rowIndex, 1);
      }
    },
    clearCart: (state) => {
      state.items = [];
      state.totalCount = 0;
      state.subTotal = 0;
    },
  },
});

function generateRowId() {
  return `row-${Math.random().toString(36).substr(2, 9)}`;
}

function recalculateTotals(state) {
  let totalCount = 0;
  let subTotal = 0;

  state.items.forEach((item) => {
    totalCount += item.qty;
    subTotal += item.qty * item.price;
  });

  state.totalCount = totalCount;
  state.subTotal = subTotal;
}

export const {
  addToCart,
  updateQuantity,
  incrementQuantity,
  decrementQuantity,
  addToSubscription,
  updateSubscriptionInterval,
  getTotalCount,
  getSubtotal,
  removeItem,
  clearCart,
} = userCartSlice.actions;

export default userCartSlice.reducer;
