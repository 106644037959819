import React from 'react';
import { FaFacebookF, FaTwitter, FaWhatsapp, FaLinkedin } from 'react-icons/fa';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from 'react-share';

function ShareButtons({ shareUrl, className }) {
  return (
    <div>
      {/* Facebook Share Button */}
      <FacebookShareButton url={shareUrl}>
        <FaFacebookF  size={20}/>
      </FacebookShareButton>

      {/* Twitter Share Button */}
      <TwitterShareButton url={shareUrl} className={className}>
        <FaTwitter  size={20}/>
      </TwitterShareButton>

      {/* LinkedIn Share Button */}
      <LinkedinShareButton url={shareUrl} className={className}>
        <FaLinkedin  size={20}/>
      </LinkedinShareButton>

      {/* WhatsApp Share Button */}
      <WhatsappShareButton url={shareUrl} className={className}>
        <FaWhatsapp   size={20}/>
      </WhatsappShareButton>
    </div>
  );
}

export default ShareButtons;
