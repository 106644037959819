import { Link } from "react-router-dom";
import "./PolicyPages.css";


export default function RefundPolicy() {

    return (
        <main>
            <section>
                <div className="container-side-space">
                    <div className="header-contact-body ptb-5">
                        <div className="header-contact-form-new">
                            <div className="heading-policy pb-4">
                                <h3>Return Policy</h3>
                               
                                {/* <h5>
                                    Privacy Policy for <Link to="http://www.shopnmac.com" className="ancher-text">Shopnmac.bm</Link>
                                </h5> */}
                                
                                <p>At Shopnmac.com, we are dedicated to offering you the finest products and a seamless shopping experience. Kindly review our return policy prior to making any purchase. Your placement of an order on our website indicates your acceptance of the terms and conditions outlined below.</p>

                                <h5>1. Returns Overview:</h5>
                                <p>Once a product has been marked as "Out for Delivery" and is in transit to your designated address, it becomes ineligible for return. We do not accept any returns or process refunds for products that have reached this stage of the delivery process.</p>

                                <h5>2. Opened Packages</h5>

                                <p>We acknowledge your desire to inspect your order upon arrival. However, once a package is opened, it cannot be returned for a refund or exchange. This policy ensures the quality and safety of our products.</p>

                                <h5>3. Damaged or Defective Products:</h5>

                                <p>Should you receive a product that is damaged during transit or is discovered to be defective, please reach out to our customer service within 48 hours of receiving the item. We will assess the situation and collaborate with you to determine a satisfactory resolution, which could involve replacement, repair, or refund, as per the circumstances.</p>


                                <h5>4. Cancellations</h5>

                                <p>Should you wish to cancel an order, we urge you to do so at the earliest opportunity. Orders can only be canceled prior to the product status being updated to "Out for Delivery." Once the product is in transit, we regret to inform you that cancellations will not be feasible.
                                </p>

                                <h5>5. Non-Returnable Items:</h5>
                                <p>
                                    Please be informed that under any circumstances, we do not accept returns or offer refunds. Once a product is delivered and the delivery process has commenced, returns and refunds will not be processed.</p>

                                <h5>6. Contact Us:</h5>
                                <p>
                                    For any inquiries or concerns regarding our return policy, please do not hesitate to get in touch with our customer service team at <Link className="ancher-text" to="mailto:info@shopnmac.com">
                                        info@nmac.bm
                                    </Link>
                                    .
                                </p>
                                <hr />
                                <p>
                                    Kindly note that this return policy is subject to modification without prior notice. It is your responsibility to review the policy before making any purchase. This policy does not supersede your statutory rights.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
