import React from "react";
import { useSelector } from "react-redux";
import ViewCartProduct from "./components/Items";
import CartProceedBtn from "./components/ProccedBtn";
import "./Cart.css";

export default function Cart() {
  const items = useSelector((state) => state.userCart.items);
  const subTotal = useSelector((state) => state.userCart.subTotal);
  const totalCount = useSelector((state) => state.userCart.totalCount);

  return (
    <section>
      <div className="container-side-space">
        <div className="ptb-5 ptb-md-3">
          <div className="cart-wrapper">
            <div className="view-cart">
              <div className="heading text-left mb-sm-3 mb-0">
                <h3 className="">Shopping Cart</h3>
              </div>
              {items?.length === 0 ? (
                <p>Your cart is empty.</p>
              ) : (
                <>
                  {items?.map((item) => (
                    <ViewCartProduct
                      key={item.id}
                      item={item}
                      total={subTotal}
                      count={totalCount}
                    />
                  ))}
                </>
              )}
            </div>

            <div className="Procced-cart">
              <CartProceedBtn
                total={subTotal}
                count={totalCount}
              ></CartProceedBtn>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
