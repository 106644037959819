import { Link } from "react-router-dom";
import "./PolicyPages.css";


export default function PrivacyPolicy() {

  return (
    <main>
      <section>
        <div className="container-side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form-new">
              <div className="heading-policy pb-4">
                <h3>Privacy Policy</h3>
               
                <h5>
                  Privacy Policy for <Link to="http://www.shopnmac.com" className="ancher-text">Shopnmac.bm</Link>
                </h5>
               
                <p>At Shopnmac.bm ("we," "us," "our," or "Shopnmac"), we are committed to safeguarding the privacy and personal information of our users and customers. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide to us through our website and related services. By using our website, you agree to the terms of this Privacy Policy.</p>
              
                <h5>1. Information We Collect:</h5>
                <p>   - You must be at least 18 years old or the legal age in your jurisdiction to use our website.</p>
                <p>   - You agree to use our website for lawful purposes only and not for any illegal, fraudulent, or unauthorized activities.</p>
                <h5>2. How We Use Your Information:</h5>

                <p>We use the collected information for various purposes, including but not limited to:</p>



                <p>- Processing Orders: To process and fulfill your orders, including shipping and customer service.</p>


                <p>- Personalization: To personalize your experience on our website and recommend products based on your preferences.</p>

                <p>
                  - Communication: To send order confirmations, updates, promotions, and other relevant information via email or other communication channels.</p>

                <p>- Analytics: To analyze user behavior, improve our website, and enhance user experience.</p>

                <p>- Legal Compliance: To comply with legal obligations and resolve disputes.</p>

                <h5>3. Information Sharing:</h5>

                <p>sWe may share your information in the following circumstances:</p>

                <p> - Service Providers: We may share your information with third-party service providers who assist us in operating our website, processing payments, and providing customer support.</p>
                <p> - Legal Requirements: We may disclose information if required by law, court order, or governmental authority.</p>
                <p> - Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred to the relevant third party.</p>
                <p> - Consent: With your consent, we may share information in other circumstances.</p>

                <h5>4. Your Choices:</h5>

                <p>- You can update your personal information in your account settings.
                </p>

                <p>- You can unsubscribe from marketing communications using the provided opt-out link.</p>
                <p>- You can choose not to provide certain information, but this might limit your access to certain features or services.</p>

                <h5>5. Security:</h5>
                <p>
                  We employ reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction.</p>

                <h5>6. Children's Privacy:</h5>
                <p>
                  Our website is not intended for children under the age of 13. We do not knowingly collect personal information from individuals under 13 years of age.
                </p>

                <h5>7. Changes to the Privacy Policy:</h5>
                <p>
                  We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. The updated policy will be posted on our website, and the "Effective Date" at the beginning of the policy will be revised accordingly.
                </p>


                <h5>
                  8. Contact Us:
                </h5>

                <p>
                  - If you have any questions, concerns, or requests related to this Privacy Policy, you can contact us at{" "}
                  <Link className="ancher-text" to="mailto:info@shopnmac.com">
                    info@nmac.bm
                  </Link>
                  .
                </p>

                <hr />
                {/* <h5>GENERAL CONDITIONS</h5> */}
                <p>
                  By using Shopnmac.bm, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
