import React, { useEffect, useState } from "react";
import "./Payment.css";
import CheckoutProduct from "./CheckoutProduct";
import Coupon from "./Coupon";
import PaymentCard from "./PaymentCard";
import { useDispatch, useSelector } from "react-redux";
import {
  checkoutOrder,
  getShippingCharge,
  // getTaxCharge,
} from "../../services/checkoutService";
import { clearCart } from "../../redux/userCartSlice";
import { clearCheckout } from "../../redux/checkoutSlice";
import { useNavigate } from "react-router-dom";

export default function Payment() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [taxAmount, setTaxAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [loader, setLoader] = useState(false);

  const items = useSelector((state) => state.userCart.items);
  const subTotal = useSelector((state) => state.userCart.subTotal)?.toFixed(2);
  const totalCount = useSelector((state) => state.userCart.totalCount);
  const user = useSelector((state) => state.userAuth.user.data);
  // // comsole.log(error);("user", user);
  // const billingAddress = useSelector((state) => state.checkout.billingAddress);
  const billingAddress = useSelector((state) => state.userAuth.user.data);
  // // comsole.log(error);("billingAddress", billingAddress);
  const shippingAddress = useSelector(
    (state) => state.checkout.shippingAddress
  );
  const pickUp = useSelector((state) => state.checkout.pickupAddress);
  const pickupOrder = useSelector((state) => state.checkout.pickupOrder);

  // const [tax, setTax] = useState({
  //   name: "",
  //   rate: "",
  // });
  // const [coupon, setCoupon] = useState({
  //   code: "",
  //   type: "",
  //   amount: 0,
  // });

  // const [cardDetails, setCardDetails] = useState({
  //   cardFirstName: "",
  //   cardLastName: "",
  //   cardNumber: "",
  //   expiryMonth: "",
  //   expiryYear: "",
  //   cvv: "",
  // });

  const handlePlaceOrder = async () => {
    setLoader(true);
    const data = {
      customer_id: user?.id,
      email: user.email,
      items: items,
      subTotal: +subTotal,
      // coupon: coupon,
      couponDiscount: couponDiscount.toFixed(2),
      // tax: tax,
      // taxAmount: taxAmount,
      shippingCharge: shippingCharge,
      total: +subTotal - couponDiscount.toFixed(2) + shippingCharge,
      shippingAddress: shippingAddress,
      billingAddress: billingAddress,
      pickupOrder: pickupOrder,
      pickupAddress: pickUp,
      // cardDetails: cardDetails,
    };
    // // comsole.log(error);(data);
    const response = await checkoutOrder(data);
    if (!response.error) {
      // // comsole.log(error);(response);
      dispatch(clearCart());
      dispatch(clearCheckout());
      navigate("/checkout/success");
      navigate("/checkout/success", {
        state: { success: response.data },
      });
      setLoader(false);
    } else {
      setLoader(false);
      navigate("/checkout/error");
      navigate("/checkout/failed", {
        state: { error: response.error },
      });
    }
  };

  const fetchShippingCharge = async () => {
    try {
      const shipping = await getShippingCharge({
        country: shippingAddress?.country || billingAddress?.country,
        totalAmount: subTotal,
      });
      setShippingCharge(shipping);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchShippingCharge();
  }, [billingAddress, shippingAddress, subTotal]);

  return (
    <section>
      <div className="container-side-space">
        <div className="payment-wrapper ptb-5 ptb-md-3">
          <div className="heading text-center">
            <h3 className="border-bottom-0">CONFIRM CHECKOUT PROCESS</h3>
          </div>

          {/* <div className="payment-address-deatils mt-3 mt-mdd-5 d-block d-sm-flex justify-content-between">
            <div className="bill-deatil mb-2 mb-sm-0">
              <h3>Billing Address</h3>
              <p className="mb-1">
                <b>
                  {billingAddress?.first_name} {billingAddress?.Middle_name}
                  {billingAddress?.last_name}
                </b>
              </p>
              <p>
                {billingAddress?.address && (
                  <>
                    {billingAddress?.address},<br />
                  </>
                )}

                {billingAddress?.address_2 && (
                  <>
                    {billingAddress?.address_2},<br />
                  </>
                )}

                {billingAddress?.city}
              </p>

              {billingAddress?.phone && (
                <p>
                  <b>Phone: </b>
                  {billingAddress?.phone}
                </p>
              )}
              {billingAddress?.country && (
                <p>
                  <b>Country: </b>
                  {billingAddress?.country}
                </p>
              )}
            </div>

            <div className="bill-deatil">
              {pickUp && (
                <>
                  <h3> Pickedup Address </h3>
                  <p>
                    Dr Brown's Laboratories Ltd <br /> P.O. Box HM1839,
                    <br /> Hamilton HMGX Bermuda{" "}
                  </p>
                </>
              )}
              {!pickUp && shippingAddress && (
                <>
                  <h3>Shipping Address</h3>
                  <p className="mb-1">
                    <b>
                      {shippingAddress?.first_name}{" "}
                      {shippingAddress?.Middle_name}
                      {shippingAddress?.last_name}
                    </b>
                  </p>
                  <p>
                    {shippingAddress?.address && (
                      <>
                        {shippingAddress?.address},<br />
                      </>
                    )}

                    {shippingAddress?.address_2 && (
                      <>
                        {shippingAddress?.address_2},<br />
                      </>
                    )}

                    {shippingAddress?.city}
                  </p>

                  {shippingAddress?.phone && (
                    <p>
                      <b>Phone: </b>
                      {shippingAddress?.phone}
                    </p>
                  )}
                  {shippingAddress?.country && (
                    <p>
                      <b>Country: </b>
                      {shippingAddress?.country}
                    </p>
                  )}
                </>
              )}
            </div>
          </div> */}

          <div className="line"></div>

          <div className="billing-product-detail mt-4">
            {/* <div className="coupon"> */}
            {/* Coupon */}
            {/* <Coupon
                setCoupon={setCoupon}
                setCouponDiscount={setCouponDiscount}
              /> */}
            {/* Coupon */}
            {/* </div> */}

            <div className="checkout-product">
              {/* CheckoutProduct */}

              <CheckoutProduct
                items={items}
                totalCount={totalCount}
                subTotal={subTotal}
                couponDiscount={couponDiscount}
                shippingCharge={shippingCharge}
              // taxAmount={taxAmount}
              />

              {/* End CheckoutProduct */}

              {/* PaymentCard */}

              <div className="card-details mt-3 mt-mdd-5 border-1">
                <PaymentCard
                  toCheckoutOrder={handlePlaceOrder}
                  // cardDetails={cardDetails}
                  // setCardDetails={setCardDetails}
                  loader={loader}
                />
              </div>
              {/* PaymentCard */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
