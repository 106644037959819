import React, { useState, useEffect } from "react";
import "./AddressComponent.css";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import authApi from "../../../../utils/authApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const AddressComponent = () => {
  const user_id = useSelector((state) => state.userAuth.user.data.id);
  const [addressData, setAddressData] = useState([]);

  useEffect(() => {
    fetchAddressData();
  }, []);

  const fetchAddressData = () => {
    authApi
      .get(`customer/get-user-address`)
      .then((response) => {
        setAddressData(response.data.data);
        // comsole.log(error);("addressData response", response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const handleRemoveAddress = async (addressId) => {
    try {
      const response = await authApi.delete(`customer/address/${addressId}`);
      // comsole.log(error);(addressId);
      if (response?.status === 204) {
        toast.success("Address removed successfully");
        fetchAddressData();
      } else {
        toast.error("Failed to remove address");
      }
    } catch (error) {
      console.error("Error removing address:", error);
      toast.error("An error occurred while removing address");
    }
  };

  const handleSetDefaultAddress = async (addressId) => {
    try {
      const response = await authApi.put(`customer/set-default-address/${addressId}`, {
        user_id: user_id,
        default_address: 1,
      });

      if (response?.status === 200) {
        toast.success("Address set as default successfully");
        fetchAddressData();
      } else {
        toast.error("Failed to set default address");
      }
    } catch (error) {
      console.error("Error setting default address:", error);
      toast.error("An error occurred while setting default address");
    }
  };



  return (
    <>
      {addressData.map((address, index) => (
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3" key={index}
        >
          <div className="address-text">
            <p className="mb-1">
              <b>
                {address.first_name} {address.middle_name} {address.last_name}
              </b>
            </p>
            <p>
              {address.flat_apartment}, {address.address},{" "}
              {address.street}, {address.state} <br />
              {address.city}
            </p>
            <p>
              {address.city} {address.zipcode}
            </p>
            <div className="d-flex justify-content-end flex-column flex-wrap flex-md-row">
              <Link
                to={`/dashboard/edit-address/${address.id}`}
                className="btn theme-btn mt-3 w-sm-100"
              >
                <FaPencilAlt /> Edit
              </Link>
              <button
                className="btn edit-btn mt-3 ml-0 ml-sm-2"
                onClick={() => handleRemoveAddress(address.id)}
              >
                Remove
              </button>
              {!address.default_address && (
                <button
                  className="btn edit-btn mt-3 ml-0 ml-sm-2"
                  onClick={() => handleSetDefaultAddress(address.id)}
                >
                  Set Default Address
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default AddressComponent;


// import React, { useState, useEffect } from "react";
// import "./AddressComponent.css";
// import { Link } from "react-router-dom";
// import { FaPencilAlt } from "react-icons/fa";
// import authApi from "../../../../utils/authApi";
// import { toast } from "react-toastify";
// import { useSelector } from "react-redux";
// const AddressComponent = () => {
//   const user_id = useSelector((state) => state.userAuth.user.data.id);
//   const user_address = useSelector((state) => state.userAuth.user.data);
//   // comsole.log(error);("user_address", user_address);
//   const [addressData, setAddressData] = useState([]);

//   useEffect(() => {
//     fetchAddressData();
//   }, []);

//   const fetchAddressData = () => {
//     authApi
//       .get(`customer/get-user-address`)
//       .then((response) => {
//         setAddressData(response.data.data);
//         // comsole.log(error);("addressData response", response.data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching address:", error);
//       });
//   };

//   const handleRemoveAddress = async (addressId) => {
//     try {
//       const response = await authApi.delete(`customer/address/${addressId}`);
//       // comsole.log(error);(addressId);
//       if (response?.status === 204) {
//         toast.success("Address removed successfully");
//         fetchAddressData();
//       } else {
//         toast.error("Failed to remove address");
//       }
//     } catch (error) {
//       console.error("Error removing address:", error);
//       toast.error("An error occurred while removing address");
//     }
//   };

//   const handleSetDefaultAddress = async (addressId) => {
//     try {
//       const response = await authApi.put(`customer/set-default-address/${addressId}`, {
//         user_id: user_id,
//         default_address: 1,
//       });

//       if (response?.status === 200) {
//         toast.success("Address set as default successfully");
//         fetchAddressData();
//       } else {
//         toast.error("Failed to set default address");
//       }
//     } catch (error) {
//       console.error("Error setting default address:", error);
//       toast.error("An error occurred while setting default address");
//     }
//   };



//   return (
//     <>

//       <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3"
//       >
//         {addressData.map((address, index) => (
//           <div className="address-text" key={index}>
//             <p className="mb-1">
//               <b>
//                 {address.first_name} {address.middle_name} {address.last_name}
//               </b>
//             </p>
//             <p>
//               {address.flat_apartment}, {address.address},{" "}
//               {address.street}, {address.state} <br />
//               {address.city}
//             </p>
//             <p>
//               {address.city} {address.zipcode}
//             </p>
//             <div className="d-flex justify-content-end flex-column flex-md-row">
//               <Link
//                 to={`/dashboard/edit-address/${address.id}`}
//                 className="btn theme-btn mt-3"
//               >
//                 <FaPencilAlt /> Edit
//               </Link>
//               <button
//                 className="btn edit-btn mt-3 ml-2"
//                 onClick={() => handleRemoveAddress(address.id)}
//               >
//                 Remove
//               </button>
//               {!address.default_address && (
//                 <button
//                   className="btn edit-btn mt-3 ml-2"
//                   onClick={() => handleSetDefaultAddress(address.id)}
//                 >
//                   Set as Default Address
//                 </button>
//               )}
//             </div>
//           </div>
//         ))}
//         {/* main address comming from another api */}
//         <div className="address-text">
//           <p className="mb-1">
//             <b>
//               {user_address.first_name} {user_address.middle_name} {user_address.last_name}
//             </b>
//           </p>
//           <p>
//             {user_address.flat_apartment}, {user_address.address},{" "}
//             {user_address.street}, {user_address.state} <br />
//             {user_address.city}
//           </p>
//           <p>
//             {user_address.city} {user_address.zipcode}
//           </p>
//           <div className="d-flex justify-content-end flex-column flex-md-row">
//             <Link
//               to={`/dashboard/edit-address/${user_address.id}`}
//               className="btn theme-btn mt-3"
//             >
//               <FaPencilAlt /> Edit
//             </Link>
//             <button
//               className="btn edit-btn mt-3 ml-2"
//               onClick={() => handleRemoveAddress(user_address.id)}
//             >
//               Remove
//             </button>
//             {!user_address.default_address && (
//               <button
//                 className="btn edit-btn mt-3 ml-2"
//                 onClick={() => handleSetDefaultAddress(user_address.id)}
//               >
//                 Set as Default Address
//               </button>
//             )}
//           </div>
//         </div>
//       </div>

//     </>
//   );
// };

// export default AddressComponent;
