import React from "react";
import "./skeletonloading.css";

const SkeletonCard = ({ width, height, borderRadius }) => {
  const style = {
    width: width || "100%",
    height: height || "100%",
    borderRadius: borderRadius || "4px",
  };

  return (
    <>
      <div className="skeleton-loading w-100" style={style}>
        <div className="img-bg w-100"></div>
        <div className="btn-bg w-100 ml-auto mr-auto"></div>
        <div className="para-bg w-100"></div>
        <div className="para-bg w-100"></div>
        <div className="d-flex justify-content-between w-100">
          <div className="price-bg w-25 mr-2"></div>
          <div className="price-bg w-25 text-right ml-2"></div>
        </div>
      </div>
    </>
  );
};

export default SkeletonCard;
