import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Content.css";
import ReviewNew from "../Review/Review";
import ShareButtons from "../ShareSocial/ShareButtons";
import MobProductCart from "../Cart/mobIndex";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { APIBASE } from "../../../../constants";
import { fetchWishlist } from "../../../../redux/productSlice";


const initialState = {
  product_id: "",
  name: "",
  email: "",
  star: "",
  headline: "",
  review: "",
};
export default function ProductContent({ product, onAddToCart }) {
  const [isWish, setIsWish] = useState("");
  const wishlist = useSelector((state) => state.products.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // // comsole.log(error);(product);

  const productUrl = `${APIBASE}product-detail${product.slug}`;

  const user_id = useSelector((state) => state.userAuth.user.data?.id);

  const handleAddToWishList = async (id) => {
    const payload = { user_id: user_id ? user_id : null, product_id: id };
    if (id && user_id) {
      try {
        await axios.post(`${APIBASE}wishlists`, payload);
        toast.success("product added to wishlist successfully.", {
          autoClose: 300,
        });
        dispatch(fetchWishlist());
      } catch (error) {
        toast.error("Error!", { autoClose: 300 });
      }
    } else {
      navigate("/login");
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkWish = (id) => {
    const filter = wishlist?.filter((elem) => elem.product?.id === id);
    if (filter?.length > 0) {
      setIsWish(filter[0]?.product?.id);
    } else {
      setIsWish("");
    }
  };
  useEffect(() => {
    checkWish(product?.id);
  }, [checkWish, product?.id, wishlist]);

  const handleDeleteWishlist = async (id) => {
    if (id && user_id) {
      try {
        await axios.delete(`${APIBASE}customer/wishlists/${id}`);
        toast.success("Item deleted from wishlist.", { autoClose: 300 });
        dispatch(fetchWishlist());
      } catch (error) {
        toast.error("Error", { autoClose: 300 });
      }
    }
  };

  const [overallReviews, setOverAllReviews] = useState(0);

  useEffect(() => {
    if (product?.reviews?.length > 0) {
      const rSum = product?.reviews?.reduce((a, b) => {
        return a + (b.star > 0 ? b.star : 0);
      }, 0);

      setOverAllReviews((rSum / product?.reviews.length).toFixed(1));
    }
  }, [product?.reviews]);

  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(initialState);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setState({ ...state, product_id: product.id });
  }, [isOpen, product.id, state]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      state.name === "" ||
      state.review === "" ||
      state.email === "" ||
      state.star === ""
    ) {
      toast.warn("Make sure you are providing Stars, Name, Email and Review.", {
        position: "top-center",
      });
      closeModal();
    } else {
      try {
        await axios.post(`${APIBASE}reviews`, state);
        toast.success("Product review added successfully.");
        closeModal();
        setIsOpen(false);
      } catch (error) {
        toast.error("Error!");
        setIsOpen(false);
      }
    }
  };

  const handlePostReviewClick = () => {
    openModal();
  };
  console.log(handlePostReviewClick);

  useEffect(() => {
    dispatch(fetchWishlist());
  }, [dispatch]);

  return (
    <>
      <div className="product-content">
        <h3>{product?.product_name}</h3>
        <p>
          <b>Categories :</b> {product?.category}
        </p>
        <p>
          <b> Item Code :</b> {product?.sku}
        </p>
        {/* <div className="product-rating">
          <p>
            {overallReviews ? overallReviews : ""}{" "}
            <i className="fa fa-star orange-color"></i> |{" "}
            {product?.reviews?.length} Ratings |{" "}
          </p>
        </div> */}

        <div className="product-rating">
          <p>
            {overallReviews ? overallReviews : ""}{" "}
            <i className="fa fa-star orange-color"></i> |{" "}
            {product?.reviews?.length} Ratings |{" "}
          </p>
          <div>
            <ReviewNew item={product} />
          </div>
        </div>
        <hr></hr>

        <p>
          {/* {// comsole.log(error);(product)} */}
          <span>
            US $
            {product?.discounted_price < product?.selling_price
              ? product?.discounted_price
              : product?.selling_price}
          </span>{" "}
          {product?.discounted_price < product?.selling_price && (
            <del>US ${product?.selling_price}</del>
          )}
        </p>
        <p>
          <b>Unit :</b> {product?.unit}
        </p>
        <p>
          <b>Weight :</b> {product?.weight}
        </p>
        <p>
          <b>Quantity :</b> {product?.quantity}
        </p>
        <div className="btn-wishlist">
          {isWish ? (
            <button className="btn"
              title="Remove from wishlist"
              onClick={() => handleDeleteWishlist(isWish)}
            >
              <i className="fas fa-heart red-color"></i> Remove from Wishlist
            </button>
          ) : (
            <button className="btn"
              title="Add to wishlist"
              onClick={() => handleAddToWishList(product?.id)}
            >
              <i className="far fa-heart"></i> Add to Wishlist
            </button>
          )}
        </div>



        <div className="product-detail-cart cart-for-mobile mb-3 pt-1">
          {product && (
            <MobProductCart product={product} onAddToCart={onAddToCart} />
          )}
        </div>

        <div className="product_socials">
          <span className="label product_socials_label">
            Share this products :
          </span>
          <div className="socials-inline">
            <ShareButtons shareUrl={productUrl} />
          </div>
        </div>

        {/* <div>
        <Review reviews={product.reviews} product={product} />
      </div> */}
      </div>
      {isOpen && (
        <div
          className="modal fade"
          id="Productreview"
          style={{ display: "block" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title modal-heading">
                  Post Your Product Review
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={closeModal}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div>
                    <div className="p-0 col-md-12 ">
                      <div className="rate-box">
                        <h4>Overall rating</h4>

                        <div className="star-rating mb-2">
                          <input
                            type="radio"
                            id="5-stars"
                            name="rating"
                            value="5"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label htmlFor="5-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="4-stars"
                            name="rating"
                            value="4"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label htmlFor="4-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="3-stars"
                            name="rating"
                            value="3"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label htmlFor="3-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="2-stars"
                            name="rating"
                            value="2"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label htmlFor="2-stars" className="star">
                            ★
                          </label>
                          <input
                            type="radio"
                            id="1-star"
                            name="rating"
                            value="1"
                            onChange={(e) =>
                              setState({ ...state, star: e.target.value })
                            }
                          />
                          <label htmlFor="1-star" className="star">
                            ★
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required=""
                          value={state.name}
                          onChange={(e) =>
                            setState({ ...state, name: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required=""
                          value={state.email}
                          onChange={(e) =>
                            setState({ ...state, email: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          name="headline"
                          className="form-control"
                          placeholder="Add a headline "
                          required=""
                          value={state.headline}
                          onChange={(e) =>
                            setState({ ...state, headline: e.target.value })
                          }
                        />
                      </div>
                    </div>

                    <div className="p-0 col-md-12">
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          name="review"
                          placeholder="Write your review"
                          value={state.review}
                          onChange={(e) =>
                            setState({ ...state, review: e.target.value })
                          }
                        ></textarea>
                      </div>
                    </div>

                    <div className="p-0 col-md-12 mt-3 text-center">
                      <input
                        type="submit"
                        name="submit"
                        value="Submit"
                        className="btn btn-theme"
                        disabled={
                          state.name === "" ||
                          state.review === "" ||
                          state.email === "" ||
                          state.star === ""
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
