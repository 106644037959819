import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import api from "../utils/api";

// Async thunk to fetch addresses
export const fetchAddresses = createAsyncThunk(
  "addresses/fetch",
  async (customerId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/customer/${customerId}/addresses`);
      return response?.data?.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to create a new address
export const createAddress = createAsyncThunk(
  "addresses/create",
  async ({ customer, address }, { rejectWithValue }) => {
    // // comsole.log(error);(`customer/${customer}/address`);
    try {
      const response = await api.post(`customer/${customer}/address`, address);
      return response?.data?.data;
    } catch (error) {
      rejectWithValue(error.response.data);
    }
  }
);

// Async thunk to update an address
export const updateAddress = createAsyncThunk(
  "addresses/update",
  async (addressData, { rejectWithValue }) => {
    try {
      const { id, ...updatedData } = addressData;
      const response = await api.put(`customer/address/${id}`, updatedData);
      return response?.data?.data;
    } catch (error) {
      // toast.error("Failed to update address");
      return rejectWithValue(error.response.data);
    }
  }
);

const addressSlice = createSlice({
  name: "addresses",
  initialState: {
    addresses: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    // addRow: (state, action) => {
    //   state.addresses.push(action.payload); // Add the new address to the addresses array
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAddresses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addresses = action.payload;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        state.addresses.push(action.payload);
      })
      .addCase(createAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        const updatedAddress = action.payload;
        const index = state.addresses.findIndex(
          (address) => address.id === updatedAddress.id
        );
        if (index !== -1) {
          state.addresses[index] = updatedAddress;
        }
      })
      .addCase(updateAddress.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// export const { addRow } = addressSlice.actions;

export default addressSlice.reducer;
