import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import authApi from "../../../../../utils/authApi";
import { toast } from "react-toastify";
// import axios from "axios";
import { APIBASE, IMAGEURL } from "../../../../../constants";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../../../redux/userCartSlice";

const WishlistComponent = ({ product }) => {
  //   const [wishlistData, setWishlistData] = useState(null);
  const dispatch = useDispatch();

  //   const getAllWishlist = async () => {
  //     await authApi
  //       .get("/customer/get-user-wishlist")
  //       .then((response) => {
  //         setWishlistData(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching wishlist data:", error);
  //       });
  //   };
  //   useEffect(() => {
  //     getAllWishlist();
  //   }, []);

  const handleDeleteWishlist = async (id) => {
    try {
      // https://api.shopnmac.com/api/customer/wishlists/{id}
      await authApi.delete(`/api/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      // getAllWishlist();
      window.location.reload();
    } catch (error) {
      // console.log(error);
      toast.error("Error");
    }
  };

  const handleAddToCart = (item, qty, interval) => {
    const newItem = {
      id: item.id,
      sku: item.sku,
      name: item.product_name,
      slug: item.slug,
      avl_qty: +item?.quantity- +item?.quantity_used,
      // price: item?.selling_price || 0,
      old_price: item?.selling_price || 0,
      price: item?.discounted_price ?? item?.selling_price,
      discount: item?.discount_value || 0,
      discount_type: item?.discount_type,
      qty: 1,
      unit: item?.unit,
      category: item?.category,
      weight: item.weight,
      image: item.thumbnail,
      thumbnail_comprees: item?.thumbnail_comprees,
    };
    dispatch(addToCart(newItem)); // Dispatch the addToCart action
    toast.success("Product added to cart!", {
      autoClose: 500,
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  // console.log("wishlistData", wishlistData);
  return (
    <>
      {/* {wishlistData && wishlistData.length > 0 ? (
        wishlistData.map((product) => ( */}
      <div className="wishlist-list py-4" key={product?.id}>
        <div className="row">
          <div className="col-xl-7 col-lg-8 col-md-7 col-sm-12">
            <div className="d-flex">
              <div className="product-img">
                <img
                  src={`${IMAGEURL}${product?.thumbnail}`}
                  alt="product"
                  className="img-fluid"
                />
              </div>

              <div className="product-info ml-3">
                <p className="mb-2">{product?.product_name}</p>
                <p className="mb-2">
                  <b>Price: </b> $ {product?.selling_price}
                </p>
                <p className="mb-2">
                  <b>Product Code: </b>#{product?.product_id}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-4 col-md-5 col-sm-12">
            <div className="wislist-right float-lg-right mt-3 mt-md-0">
              <p>
                <b>Item Added On :</b>
                <span>
                  <Moment format="DD-MM-YYYY">{product?.created_at}</Moment>
                </span>
              </p>

              <div className="mt-2">
                <button
                  to="#"
                  className="btn theme-btn"
                  onClick={() => handleAddToCart(product)}
                >
                  Add to Cart
                </button>
                <button
                  onClick={() => handleDeleteWishlist(product.id)}
                  className="btn edit-btn ml-3 ml-xl-3 mt-0 mt-xl-0 ml-lg-0 mt-lg-2"
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ))
      ) : (
        <div className="empty-wishlist mt-lg-3 mt-3">
          <p>Nothing in the wishlist.</p>
        </div>
      )} */}
    </>
  );
};

export default WishlistComponent;

// import React from 'react';
// import { Link } from "react-router-dom";
// import ProductImage from "../../../../assets/product-image.jpg";

// const WishlistComponent = () => {
//   return (
//     <>
//           <div className="wishlist-list py-4">
//             <div className="row">
//               <div className="col-xl-7 col-lg-8 col-md-7 col-sm-12">
//                 <div className="d-flex">
//                   <div className="product-img">
//                     <img
//                       src={ProductImage}
//                       alt="product"
//                       className="img-fluid"
//                     />
//                   </div>

//                   <div className="product-detail ml-3">
//                     <p>Intensive moisture balance moisturizer 0.5 oz</p>
//                     <p>
//                       <b>Price: </b> $234.00
//                     </p>
//                     <p>
//                       <b>Product Code: </b>#TEST123654
//                     </p>
//                   </div>
//                 </div>
//               </div>

//               <div className="col-xl-5 col-lg-4 col-md-5 col-sm-12">
//                 <div className="wislist-right float-lg-right mt-3 mt-md-0">
//                   <p>Item Added 17 May 2023</p>

//                   <div className="mt-2">
//                     <Link to="#" className="btn theme-btn">
//                       Add to Cart
//                     </Link>
//                     <Link to="#" className="btn edit-btn ml-3 ml-xl-3 mt-0 mt-xl-0 ml-lg-0 mt-lg-2">
//                       Remove
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//     </>
//   )
// }

// export default WishlistComponent
