import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import api from "../../../utils/api";

function Footer() {
  const [categories, setCategories] = useState([]);

  const handleScrollUp = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //fetch category products
  const fetchCategories = async () => {
    try {
      const response = await api.get("categories");
      // // comsole.log(error);(response.data.data);
      setCategories(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  // // comsole.log(error);(categories);
  return (
    <footer className="footer-bg">
      <div className="container-side-space">
        <div className="row">
          {/* <div className="col-lg-3 col-sm-6 col-xs-12 col-md-6 mb-md-0 mb-2">
              <h6>Categories</h6>
              <ul className="footer-links">
                {categories?.map((row, index) => (
                  <li key={index}>
                    <Link to={`/category/${row.slug}`} className="">
                      {row.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div> */}

          <div className="col-lg-4 col-sm-6 col-xs-12 col-md-6  mb-md-0 mb-2">
            <h6>Headquarters</h6>
            <ul className="footer-links">
              <li>7 North Shore Road, Devonshire Dv01, Bermuda</li>
            </ul>
          </div>

          <div className="col-lg-4 col-sm-6 col-xs-12 col-md-6  mb-md-0 mb-2">
            <h6>Mailing Address</h6>
            <ul className="footer-links">
              <li>
                {" "}
                NorthShore Medical &amp; Aesthetics Center Ltd. <br></br>P.O.
                Box HM1839 Hamilton HMGX, Bermuda
              </li>
              <li>
                <Link to="tel:293-5476"> Tel: 293-5476 </Link>
              </li>
              <li>
                <Link to="#">Email: info@nmac.bm</Link>
              </li>
            </ul>
          </div>

          <div className="col-lg-4 col-sm-6 col-xs-12 col-md-6  mb-md-0 mb-2">
            <h6>Stay Up to Date</h6>
            <form action="" method="post" className="mt-3 mb-1">
              <div className="form-group">
                <input
                  type="email"
                  className="form-control form-custom email"
                  name="email"
                  placeholder="Enter Your Email Id"
                  required=""
                />
              </div>

              <div className="form-group">
                <button type="button" className="btn  btn-skyblue">
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="line"></div>
        <div className="">
          <div className="footer-bottom">
            <div className="footer-bottom-inner">
              <p className="copyright-text">
                © Copyright© {new Date().getFullYear()},{" "}
                <Link to="#">
                  NorthShore Medical &amp; Aesthetics Center Ltd.
                </Link>
                <br></br> All Rights Reserved.
              </p>
            </div>

            <div className="footer-bottom-inner text-left  text-lg-center" >
              <Link to="/terms-conditions" className="policy-text" onClick={handleScrollUp}>
                Term &amp; Condition{" "}
              </Link>{" "}
              <span className="hideline">|</span>
              <Link to="/privacy-policy" className="policy-text" onClick={handleScrollUp}>
                {" "}
                Privacy Policy{" "}
              </Link>{" "}
              <span className="hideline">|</span>{" "}
              <Link to="/refund-policy" className="policy-text" onClick={handleScrollUp}>
                {" "}
                Return Policy{" "}
              </Link>
            </div>

            <div className="footer-bottom-inner">
              <ul className="social-icons">
                <li>
                  <Link to="#" target="_blank">
                    <i className="fab fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <i className="fab fa-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#" target="_blank">
                    <i className="fab fa-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-3 pb-3">
          <div className="designed-by">
            <p>
              <span>
                designed by{" "}
                <Link
                  to="https://www.designdot.co.in/"
                  target="_blank"
                  className="designdot-link"
                ></Link>
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
