import React from 'react';
import './MyReviewRatings.css';
import ReviewCard from './ReviewCard';


function MyReviewRatings() {
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='main-heading py-2'>
            <h3>My Review & Ratings</h3>
          </div>

          {/* Divider Below heading is here */}
          <div className='divider' />

          {/* Products cards for review are displayed here */}
          <div className='row my-4'>
              <ReviewCard/>
              {/* <ReviewCard/>
              <ReviewCard/>
              <ReviewCard/>
              <ReviewCard/>
              <ReviewCard/> */}
          </div>
        </div>
      </div>




    </>
  )
}

export default MyReviewRatings
