import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Search.css";
import axios from "axios";
import authApi from "../../../utils/authApi";

export default function SearchComponent() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("search") ?? "";
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const searchFormRef = useRef(null);
  const handleSearchChange = () => {
    if (
      !location.pathname.startsWith("/shop") &&
      !location.pathname.startsWith("/category") &&
      !location.pathname.startsWith("/brand")
    ) {
      navigate("/shop");
    }
  };
  useEffect(() => {
    setSearchQuery(search);
  }, [search]);
  let cancelToken;

  const fetchSearchSuggestions = async () => {
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Cancelled");
    }
    cancelToken = axios.CancelToken.source();
    try {
      // Example: Fetching search suggestions from an API
      const response = await authApi.get(
        `/search/product/${encodeURIComponent(searchQuery)}`,
        { cancelToken: cancelToken?.token }
      );

      setSearchResults(response?.data); // Assuming `data.results` is an array of suggestions
      setShowDropdown(true); 
    } catch (error) {
      console.error("Error fetching search suggestions:", error);
    }
  };
  const handleItemClick=(item)=>{
    const searchUrl = `/shop?search=${item?.product_name}`;
    navigate(searchUrl);
    setShowDropdown(false);
  }
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setShowDropdown(false);
    } else {
      fetchSearchSuggestions();
    }

    return () => {
      if (cancelToken) {
        cancelToken.cancel("Cancelled");
      }
    };
  }, [searchQuery]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchFormRef.current && !searchFormRef.current.contains(event.target)) {
        // Clicked outside the search form, hide the dropdown
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      {/* search componen */}

      <form ref={searchFormRef} onSubmit={handleSearchChange} className="position-relative">
        <div className="input-group">
          <input
            type="text"
            className="custom-input form-control border-right-0"
            placeholder="I’m shopping for"
            id="search"
            name="search"
            value={searchQuery}
            onClick={() => setShowDropdown(true)} 
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="input-group-prepend">
            <button
              type="submit"
              className="input-group-text border-left-0 input-group-bg"
            >
              <i className="fa fa-search text-white"></i>
            </button>
          </div>
        </div>
        {showDropdown && searchResults?.length > 0 && (
          <ul className="search-suggestions">
            {searchResults?.map((item, index) => (
              <li key={index} onClick={()=>handleItemClick(item)}>{item?.product_name}</li>
            ))}
          </ul>
        )}
      </form>
    </>
  );
}
