import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const AddressForm = () => {
  const navigate = useNavigate();
  const user_id = useSelector((state) => state.userAuth.user.data.id);
  // // comsole.log(error);("Address form user id", user_id);


  const [formData, setFormData] = useState({
    user_id: user_id,
    first_name: "",
    middle_name: "",
    last_name: "",
    phone: "",
    email: "",
    flat_apartment: "",
    address: "",
    street: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await authApi.post("customer/address", formData);
      // comsole.log(error);("API Response:", response);

      if (response?.status === 200 || response?.status === 204) {
        navigate("/dashboard/my-profile");
        toast.success("Address added successfully!");
        // setFormData({ ...formData, first_name: "", middle_name: "", last_name: "", phone: "", email: "", flat_apartment: "", address: "", street: "", city: "", state: "", zipcode: "", country: "" });
        window.location.reload();

      } else {
        toast.error("Failed to add address");
      }
    } catch (error) {
      console.error("Error adding address:", error);
      toast.error("An error occurred while adding address");
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="w-100 pt-5">
        <div className="divider" />
        <div className="col-lg-12">
          <div className="add-address pt-5">
            <div className="main-heading pb-3">
              <h3>Add Addresses</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      name="first_name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Middle Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Middle Name"
                      value={formData.middle_name}
                      onChange={handleInputChange}
                      name="middle_name"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      name="last_name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      name="phone"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {/* <span style={{ color: "red", fontWeight: "800" }}>*</span> */}
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      name="email"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Flat/Apartment</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Flat/Apartment"
                      value={formData.flat_apartment}
                      onChange={handleInputChange}
                      name="flat_apartment"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Street</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Street"
                      value={formData.street}
                      onChange={handleInputChange}
                      name="street"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="City"
                      value={formData.city}
                      onChange={handleInputChange}
                      name="city"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      value={formData.state}
                      onChange={handleInputChange}
                      name="state"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>ZIP Code</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ZIP Code"
                      value={formData.zipcode}
                      onChange={handleInputChange}
                      name="zipcode"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Country</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Country"
                      value={formData.country}
                      onChange={handleInputChange}
                      name="country"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-3">
                <button type="submit" className="btn theme-btn">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddressForm;