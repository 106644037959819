import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";
import "./Slider.css";
import { APIBASE, IMAGEURL } from "../../../../constants";
import { json } from "react-router-dom";
export default function Slider({ images, thumbnail, productName }) {
  const [activeThumb, handleSwiper] = useState();
  // comsole.log(error);("Slider images", images);

  return (
    <>
      <div className="d-flex" style={{ gap: "10px" }}>
        <Swiper
          loop={true}
          spaceBetween={10}
          navigation={true}
          modules={[Navigation, Thumbs]}
          grabCursor={true}
          thumbs={{ swiper: activeThumb }}
          className="product-images-slider mySwiper2"
        >
          <SwiperSlide>
            <img
              src={thumbnail}
              // src={ thumbnail? IMAGEURL + thumbnail : thumbnail2}      
              alt={productName} />

          </SwiperSlide>
          {/* {// comsole.log(error);(images)} */}
          {images &&
            images?.map((imagex, index) => (
              <SwiperSlide key={index}>
                <img
                  // src={IMAGEURL + imagex.url} 
                  // src={`${IMAGEURL}${imagex?.url}`}
                  // src={`${IMAGEURL}${imagex?.url}`}
                  src={JSON.parse(imagex?.compress_image)?.resized["800px"] ? JSON.parse(imagex?.compress_image)?.resized["800px"] : `${IMAGEURL}${imagex?.url}`}
                  alt={imagex.image_name}
                // src={
                //   imagex?.compress_image
                //     ? JSON.parse(imagex?.compress_image).image_urls["800px"]
                //     : IMAGEURL + imagex.url
                // }

                />
              </SwiperSlide>
            ))}
        </Swiper>

        <Swiper
          direction="vertical"
          onClick={handleSwiper}
          loop={false}
          slidesPerView={4}
          modules={[Navigation, Thumbs]}
          className="product-images-slider-thumbs mySwiper"
        >
          <SwiperSlide>
            <div className="product-images-slider-thumbs-wrapper">
              <img src={thumbnail} alt={productName} />
            </div>
          </SwiperSlide>
          {images &&
            images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="product-images-slider-thumbs-wrapper">
                  {/* <img src={`${IMAGEURL}${image.url}`} alt="product images" /> */}
                  <img
                    className="image-main"
                    // src={`${IMAGEURL}${image?.url}`}
                    // src={JSON.parse(image?.compress_image)?.resized["800px"]}
                    src={JSON.parse(image?.compress_image)?.resized["800px"] ? JSON.parse(image?.compress_image)?.resized["800px"] : `${IMAGEURL}${image?.url}`}
                    // src={
                    //   image?.compress_image
                    //     ? JSON.parse(image?.compress_mage).image_urls["800px"]
                    //     : `${IMAGEURL}${image?.url}`
                    // }
                    alt={image?.name}
                  />
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </>
  );
}


// import { useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation, Thumbs } from "swiper";
// import "./Slider.css";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { json } from "react-router-dom";
// export default function Slider({ images, thumbnail, productName }) {
//   const [activeThumb, handleSwiper] = useState();

//   return (
//     <>
//       <div className="d-flex" style={{ gap: "10px" }}>
//         <Swiper
//           loop={true}
//           spaceBetween={10}
//           navigation={true}
//           modules={[Navigation, Thumbs]}
//           grabCursor={true}
//           thumbs={{ swiper: activeThumb }}
//           className="product-images-slider mySwiper2"
//         >
//           <SwiperSlide>
//             <img src={IMAGEURL + thumbnail} alt={productName} />
//             {/* {thumbnail ? (
//               <img
//                 src={`${IMAGEURL}upload/product/thumbnail/800/${thumbnail}`}
//                 alt="product images"
//               />
//             ) : (
//               <img src={`${IMAGEURL}${thumbnail}`} alt="product images" />
//             )} */}
//           </SwiperSlide>
//           {/* {// comsole.log(error);(images)} */}
//           {images &&
//             images?.map((imagex, index) => (
//               <SwiperSlide key={index}>
//                 <img src={IMAGEURL + imagex.url} alt={imagex.image_name} />
//               </SwiperSlide>
//             ))}
//         </Swiper>

//         <Swiper
//           direction="vertical"
//           onClick={handleSwiper}
//           loop={false}
//           slidesPerView={4}
//           modules={[Navigation, Thumbs]}
//           className="product-images-slider-thumbs mySwiper"
//         >
//           <SwiperSlide>
//             <div className="product-images-slider-thumbs-wrapper">
//               <img src={IMAGEURL + thumbnail} alt={productName} />
//             </div>
//           </SwiperSlide>
//           {images &&
//             images.map((image, index) => (
//               <SwiperSlide key={index}>
//                 <div className="product-images-slider-thumbs-wrapper">
//                   <img src={`${IMAGEURL}${image.url}`} alt="product images" />
//                 </div>
//               </SwiperSlide>
//             ))}
//         </Swiper>
//       </div>
//     </>
//   );
// }
