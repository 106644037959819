import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import authApi from "../../../../utils/authApi";
import UserImage from "../../../../assets/user.jpg";
import { IMAGEURL } from "../../../../constants";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    authApi
      .get("/customer/get-user")
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, []);

  return (
    <>
      {userData && (
        <div className="col-lg-12 col-lm-12 col-sm-12 text-center py-5">
          <div className="user-img pb-3">
            {userData?.thumbnail ? (
              <img
                src={`${IMAGEURL}${userData.thumbnail}`}
                alt="User"
              />
            ) : (
              <div className="default-user-image">
                <img src={UserImage} alt="Default User" />
              </div>
            )}
          </div>
          <div className="user-text">
            <h5>
              {userData?.first_name} {userData?.middle_name} {userData?.last_name}
            </h5>
            <p>
              <b>Email:</b> {userData?.email}
            </p>
            <p>
              <b>Phone:</b> {userData?.phone}
            </p>
            <p>
              <b>Address:</b> {userData?.address}{" "}{userData?.street}{" "}{userData?.city}{" "}{userData?.country}{" "}{userData?.zipcode}
            </p>
            <div className="mt-3">
              <Link to="/dashboard/edit-profile" title="Edit" className="btn edit-btn">
                <FaPencilAlt /> &nbsp;Edit
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserProfile;

// import React from "react";
// import { Link } from "react-router-dom";
// import { FaPencilAlt } from "react-icons/fa";
// import UserImage from "../../../assets/user.jpg";

// const UserProfile = () => {
//   return (
//     <>
//       <div className="col-lg-12 col-lm-12 col-sm-12 text-center py-5">
//         <div className="user-img pb-3">
//           <img src={UserImage} alt="user" />
//         </div>
//         <div className="user-text">
//           <h5>JYOTIRMOY SINHA</h5>
//           <p>
//             <b>Email:</b> jyotirmoysinha@gmail.com
//           </p>
//           <p>
//             <b>Phone:</b> 9999652812
//           </p>
//           <div className="mt-3">
//             <Link to="/edit-profile" title="Edit" className="btn edit-btn">
//               <FaPencilAlt /> &nbsp;Edit
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default UserProfile;
