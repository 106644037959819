import { Link, useNavigate } from "react-router-dom";
import "./ProductCard.css";
import NOIMAGE from "../../../assets/no-img.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIBASE, IMAGEURL } from "../../../constants";
import authApi from "../../../utils/authApi";
import { fetchWishlist } from "../../../redux/productSlice";
import { getNormalDate } from "../../../utils/getNormalDate";
export default function ProductCard({ product, onAddToCart }) {
  // Call parent addToCart function
  const navigate = useNavigate();
  const handleAddToCart = () => {
    // alert("ji")
    onAddToCart(product);
  };

  const dispatch = useDispatch();
  const [isWish, setIsWish] = useState("");

  const user_id = useSelector((state) => state.userAuth.user.data?.id);
  const wishlist = useSelector((state) => state.products.wishlist);

  const items = useSelector((state) => state.userCart.items);
  const [isInCart, setIsInCart] = useState("");

  const [overallReviews, setOverAllReviews] = useState(0);
  useEffect(() => {
    if (product?.reviews?.length > 0) {
      const rSum = product?.reviews?.reduce((a, b) => {
        return a + (b.star > 0 ? b.star : 0);
      }, 0);

      setOverAllReviews((rSum / product?.reviews.length).toFixed(1));
    }
  }, [product?.reviews]);

  useEffect(() => {
    if (items.length > 0) {
      const filter = items?.filter((elem) => elem.id === product.id);
      if (filter.length > 0) {
        setIsInCart(filter[0]?.id);
      }
    }
  }, [items]);

  const handleAddToWishList = async (id) => {
    const payload = { user_id: user_id ? user_id : null, product_id: id };
    if (user_id && id) {
      try {
        await axios.post(`${APIBASE}wishlists`, payload);
        toast.success("product added to wishlist successfully.", {
          autoClose: 300,
        });
        dispatch(fetchWishlist());
      } catch (error) {
        // comsole.log(error);(error);
        toast.error("Error!", { autoClose: 300 });
      }
    } else {
      navigate("/login");
    }
  };

  const checkWish = (id) => {
    const filter = wishlist?.filter((elem) => elem?.product?.id === id);
    if (filter?.length > 0) {
      setIsWish(filter[0]?.product?.id);
    } else {
      setIsWish("");
    }
  };
  useEffect(() => checkWish(product.id), [wishlist]);

  const handleDeleteWishlist = async (id) => {
    try {
      await authApi.delete(`/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      dispatch(fetchWishlist());
    } catch (error) {
      // comsole.log(error);(error);
      toast.error("Error");
    }
  };

  return (
    <div className="product-card">
      <div className="prod-img">
        <Link to={`/product-detail/${product?.slug}`}>
          {/* <img
            className="image-main"
            src={product?.thumbnail || productImage}
            alt={product?.name}
          /> */}
          {/* <img
            className="image-main"
            src={
              product?.thumbnail_name
                ? `${IMAGEURL}upload/product/thumbnail/300/${product.thumbnail_name}` || productImage
                : `${IMAGEURL}${product?.thumbnail}` || productImage
            }
            alt={product?.name}
          /> */}

          {/* <img
            className="image-main"
            src={
              product?.thumbnail_compress
                ? JSON.parse(product.thumbnail_compress).image_urls["300px"]
                : (product?.thumbnail_name
                  ? `${IMAGEURL}upload/product/thumbnail/300/${product.thumbnail_name}`
                  : `${IMAGEURL}${product?.thumbnail}`)
            }
            alt={product?.name}
          /> */}
          <img
            className="image-main"
            src={
              product?.thumbnail_compress
                ? JSON.parse(product.thumbnail_compress).image_urls["300px"]
                : `${IMAGEURL}${product?.thumbnail}`
            }
            onError={(e) => (e.target.src = NOIMAGE)}
            alt={product?.name}
          />
        </Link>
      </div>
      <div className="over-layer">
        <div className="pr-3 pt-3">
          <div className="pt-2 pb-1">
            {isWish ? (
              <Link onClick={() => handleDeleteWishlist(isWish)}>
                <i className="fas fa-heart red-color"></i>
              </Link>
            ) : (
              <Link
                to={!user_id && "/login"}
                onClick={() => handleAddToWishList(product.id)}
              >
                <i className="far fa-heart gray-color"></i>
              </Link>
            )}
          </div>
          <div className="pt-2 pb-1">
            <Link to={`/product-detail/${product?.slug}`}>
              <i className="far fa-eye gray-color"></i>
            </Link>
          </div>
        </div>
      </div>

      <div className="prod-content">
        <div className="text-center">
          {isInCart === product.id ? (
            <button
              className="addCart prod-cart cursor-pointer "
              onClick={() => navigate("/Cart")}
            >
              View Cart
            </button>
          ) : (
            <button
              className="addCart prod-cart cursor-pointer "
              onClick={handleAddToCart}
              disabled={product.quantity == 0}
              style={{ color: product.quantity == 0 && "red" }}
            >
              {product.quantity == 0 ? "Out Of Stock" : "Add To Cart"}
            </button>
          )}
        </div>

        <p className="price-text mt-3 mb-2">
          <span>
            US $
            {product?.discounted_price < product?.selling_price
              ? product?.discounted_price
              : product?.selling_price}
          </span>{" "}
          {product?.discounted_price < product?.selling_price && (
            <del>US ${product?.selling_price}</del>
          )}
        </p>

        <Link
          to={`/product-detail/${product?.slug}`}
          className="text-decoration-none"
        >
          <p className="prod-description pb-1">{product?.product_name}</p>
        </Link>

        <div className="avail-text">
          <div className="text-left prod-code">
            {/* <p>{product?.sku}</p> */}
            <p>
              <strong>Qty : </strong>
              {+product?.quantity - +product?.quantity_used}
            </p>
            <p>
              <strong>Unit : </strong>
              {product?.unit}
            </p>
          </div>

          <div className="d-flex flex-column text-right align-items-end text-xs-left mt-sm-0 mt-2">
            {/* <span className="w-max rating-star">
              {overallReviews ?? 0}
              <i
                className="fa fa-star orange-color"
                aria-hidden="true"
              ></i> | {product?.review_count ?? 0}
            </span> */}
            {product?.expiry_date && (
              <span className="w-max rating-star mt-1">
                {getNormalDate(product?.expiry_date)}
               </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// import { Link, useNavigate } from "react-router-dom";
// import "./ProductCard.css";
// import productImage from "../../../assets/product-image.jpg";
// import axios from "axios";
// import { toast } from "react-toastify";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { APIBASE, IMAGEURL } from "../../../constants";
// import authApi from "../../../utils/authApi";
// import { fetchWishlist } from "../../../redux/productSlice";
// export default function ProductCard({ product, onAddToCart }) {
//   // Call parent addToCart function
//   const navigate = useNavigate();
//   const handleAddToCart = () => {
//     onAddToCart(product);
//   };

//   const dispatch = useDispatch();
//   const [isWish, setIsWish] = useState("");

//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const wishlist = useSelector((state) => state.products.wishlist);

//   const items = useSelector((state) => state.userCart.items);
//   const [isInCart, setIsInCart] = useState("");

//   const [overallReviews, setOverAllReviews] = useState(0);
//   useEffect(() => {
//     if (product?.reviews?.length > 0) {
//       const rSum = product?.reviews?.reduce((a, b) => {
//         return a + (b.star > 0 ? b.star : 0);
//       }, 0);

//       setOverAllReviews((rSum / product?.reviews.length).toFixed(1));
//     }
//   }, [product?.reviews]);

//   useEffect(() => {
//     if (items.length > 0) {
//       const filter = items?.filter((elem) => elem.id === product.id);
//       if (filter.length > 0) {
//         setIsInCart(filter[0]?.id);
//       }
//     }
//   }, [items]);

//   const handleAddToWishList = async (id) => {

//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("product added to wishlist successfully.", {
//           autoClose: 300,
//         });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     } else {
//       navigate("/login")
//     }
//   };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem?.product?.id === id);
//     if (filter?.length > 0) {
//       setIsWish(filter[0]?.product?.id);
//     } else {
//       setIsWish("");
//     }
//   };
//   useEffect(() => checkWish(product.id), [wishlist]);

//   const handleDeleteWishlist = async (id) => {
//     try {
//       await authApi.delete(`/customer/wishlists/${id}`);
//       toast.success("Item deleted from wishlist.");
//       dispatch(fetchWishlist());
//     } catch (error) {
//       // comsole.log(error);(error);
//       toast.error("Error");
//     }
//   };

//   return (
//     <div className="product-card">
//       <div className="prod-img">
//         <Link to={`/product-detail/${product?.slug}`}>
//           {/* <img
//             className="image-main"
//             src={product?.thumbnail || productImage}
//             alt={product?.name}
//           /> */}
//           <img
//             className="image-main"
//             src={
//               product?.thumbnail_name
//                 ? `${IMAGEURL}upload/product/thumbnail/300/${product.thumbnail_name}` || productImage
//                 : `${IMAGEURL}${product?.thumbnail}` || productImage
//             }
//             alt={product?.name}
//           />
//         </Link>
//       </div>
//       <div className="over-layer">
//         <div className="pr-3 pt-3">
//           <div className="pt-2 pb-1">
//             {isWish ? (
//               <Link onClick={() => handleDeleteWishlist(isWish)}>
//                 <i className="fas fa-heart red-color"></i>
//               </Link>
//             ) : (
//               <Link to={!user_id && '/login'} onClick={() => handleAddToWishList(product.id)}>
//                 <i className="far fa-heart gray-color"></i>
//               </Link>
//             )}
//           </div>
//           <div className="pt-2 pb-1">
//             <Link to={`/product-detail/${product?.slug}`}>
//               <i className="far fa-eye gray-color"></i>
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="prod-content">
//         <div className="text-center">
//           {isInCart === product.id ? (
//             <button
//               className="addCart prod-cart cursor-pointer "
//               onClick={() => navigate("/Cart")}
//             >
//               View Cart
//             </button>
//           ) : (
//             <button
//               className="addCart prod-cart cursor-pointer "
//               onClick={handleAddToCart}
//             >
//               Add To Cart
//             </button>
//           )}
//         </div>

//         <p className="price-text mt-3 mb-2">
//           <span>
//             US $
//             {product?.discounted_price < product?.selling_price
//               ? product?.discounted_price
//               : product?.selling_price}
//           </span>{" "}
//           {product?.discounted_price < product?.selling_price && (
//             <del>US ${product?.selling_price}</del>
//           )}
//         </p>

//         <Link
//           to={`/product-detail/${product?.slug}`}
//           className="text-decoration-none"
//         >
//           <p className="prod-description pb-1">{product?.product_name}</p>
//         </Link>

//         <div className="avail-text">
//           <div className="text-left prod-code">
//             <p>{product?.sku}</p>
//           </div>

//           <div className="text-right text-xs-left mt-sm-0 mt-2">
//             <span className="rating-star">
//               {overallReviews ?? 0}
//               <i
//                 className="fa fa-star orange-color"
//                 aria-hidden="true"
//               ></i> | {product?.review_count ?? 0}
//             </span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
