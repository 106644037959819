import React from "react";
// import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import authApi from "../../../../utils/authApi";
import { APIBASE, IMAGEURL } from "../../../../constants";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { addToCart } from "../../../../redux/userCartSlice";

const ProductCard = ({ product }) => {
  const dispatch = useDispatch();

  // const { getAllWishlist } = product;

  const handleDeleteWishlist = async (id) => {
    try {
      await authApi.delete(`/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      window.location.reload();
      // getAllWishlist();
    } catch (error) {
      // comsole.log(error);(error);
      toast.error("Error");
    }
  };

  const handleAddToCart = (item, qty, interval) => {
    const newItem = {
      id: item.id,
      sku: item.sku,
      name: item.product_name,
      slug: item.slug,
      // price: item?.selling_price || 0,
      old_price: item?.selling_price || 0,
      price: item?.discounted_price ?? item?.selling_price,
      discount: item?.discount_value || 0,
      discount_type: item?.discount_type,
      avl_qty: +item?.quantity- +item?.quantity_used,
      qty: 1,
      unit: item?.unit,
      category: item?.category,
      weight: item?.weight,
      image: item.thumbnail,
      thumbnail_comprees: item?.thumbnail_comprees,
    };

    if (interval > 0) {
      newItem.subscription = {
        interval: interval,
        type: "month",
      };
    }

    dispatch(addToCart(newItem));
    toast.success("Product added to cart!", {
      autoClose: 500,
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <>
      {/* {productData.length > 0 ? ( */}
      {/* productData.map((product, index) => ( */}
      <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
        <div className="product-card">
          <div className="prod-img">
            {/* <img
                className="image-main"
                src={`${IMAGEURL}${product.thumbnail}`}
                alt="ProductImage"
              /> */}
            <img
              className="image-main"
              src={
                product?.thumbnail_name
                  ? `${IMAGEURL}upload/product/thumbnail/300/${product?.thumbnail}`
                  : `${IMAGEURL}${product?.thumbnail}`
              }
              alt="ProductImage"
            />

            {/* <img
                className="image-main"
                src={product?.thumbnail_name ?
                  `upload/product/thumbnail/400/${product.thumbnail}` :
                  product?.thumbnail || productImage}
                alt={product?.name}
              /> */}
          </div>

          <div className="over-layer">
            <div className="pr-3 pt-3">
              <div className="pt-2 pb-1">
                <Link>
                  <i
                    className="fas fa-heart red-color"
                    onClick={() => handleDeleteWishlist(product?.id)}
                  ></i>
                </Link>
              </div>
              <div className="pt-2 pb-1">
                <Link to={`/product-detail/${product?.slug}`}>
                  <i className="far fa-eye gray-color"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="prod-content">
            <div className="text-center">
              <button
                className="addCart prod-cart cursor-pointer "
                // onClick={handleAddToCart}
                onClick={() => handleAddToCart(product, 1, 0)} // qty = 1, interval = 0
              >
                Add To Cart
              </button>
            </div>

            <p className="price-text mt-3 mb-2">
              <span>US ${product?.selling_price}</span>
            </p>

            <p className="prod-description pb-1">{product?.product_name}</p>

            <div className="avail-text">
              <div className="text-left prod-code">
                <p>{product?.product_code}</p>
              </div>

              {/* <div className="text-right">
                  <span className="rating-star">
                    0 <AiFillStar /> | 1
                  </span>
                </div> */}

              <div className="text-right text-xs-left mt-sm-0 mt-2">
                <span className="rating-star">
                  {product?.rating ?? 0}
                  <i
                    className="fa fa-star orange-color"
                    aria-hidden="true"
                  ></i>{" "}
                  | {product?.review_count ?? 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ))
      ) : (
        <div className="empty-product-card ml-3">
          <p>No items in your wishlist.</p>
        </div>
      )} */}
    </>
  );
};

export default ProductCard;
