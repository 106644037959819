import React, { useState, useEffect } from "react";
import "./MyWishList.css";
import WishlistComponent from "./WishlistComponent";
import authApi from "../../../../../utils/authApi"



const MyWishList = () => {
  const [productData, setProductData] = useState([]);

  const getAllWishlist = async () => {
    authApi
      .get("customer/get-user-wishlist")
      .then((response) => {
        const res = response.data.data;
        setProductData(res);
        // console.log("get-user-wishlist", res);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };

  useEffect(() => {
    getAllWishlist();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="main-heading py-2">
            <h3>My WishList</h3>
          </div>

          {/* Divider Below heading is here */}
          <div className="divider" />
          {productData.map((wishlist, index) => (
            <WishlistComponent key={index} product={wishlist.product} />
          ))}

          {/* WishList list start is here */}
          {/* <WishlistComponent/> */}
          {/* WishList list end   */}
        </div>
      </div>
    </>
  );
}

export default MyWishList;
