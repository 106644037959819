import React from "react";
import OrderDetails from "./OrderDetails";

const OrderDetailsPopup = ({ onClose, orderId }) => {
    return (
        <>
            <div>
                <div className="modal-footer">
                    <button className="modal-close-btn" onClick={onClose}>
                        ✘
                    </button>
                </div>
                <OrderDetails
                orderId={orderId}
                />

            </div>

        </>
    );
};

export default OrderDetailsPopup;
