import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchComponent from "./Search";
import Logo from "../../../assets/bdagpo-logo.png";
import CartIcon from "../../../assets/cart.svg";
import PhoneIcon from "../../../assets/phoneicon.svg";
import Dummyimage from "../../../assets/dummy.jpg";
import "./Navbar.css";
import { logout } from "../../../redux/userAuthSlice";

import { Link, useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import axios from "axios";
import { APIBASE } from "../../../constants";

function NavComponent() {
  const [isAllCategoriesOpen, setAllCategoriesOpen] = useState(false);
  const [isBrandOpen, setBrandOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isNavOpen, setNavOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [navTitle, setNavTitle] = useState("");
  const [navLink, setNavLink] = useState("");
  const [navBrief, setNavBrief] = useState("");
  const [navLinkText, setNavLinkText] = useState("");
  const totalCount = useSelector((state) => state.userCart.totalCount);
  // const [isAllProductOpen, setAllProductOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state?.userAuth?.isLoggedIn);
  const userName = useSelector(
    (state) => state?.userAuth?.user?.data?.first_name
  );

  const userLastName = useSelector(
    (state) => state?.userAuth?.user?.data?.last_name
  );

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const AllCategoriesMouseEnter = () => {
    setAllCategoriesOpen(true);
  };

  const AllCategoriesMouseLeave = () => {
    setAllCategoriesOpen(false);
  };

  const BrandMouseEnter = () => {
    setBrandOpen(true);
  };

  const BrandMouseLeave = () => {
    setBrandOpen(false);
  };

  const LoginMouseEnter = () => {
    setLoginOpen(true);
  };

  const LoginMouseLeave = () => {
    setLoginOpen(false);
  };

  // for mobile

  const openNav = () => {
    setNavOpen(true);
  };

  const closeNav = () => {
    setNavOpen(false);
    setActiveDropdown(null);
  };

  const toggleDropdown = (dropdownIndex) => {
    setActiveDropdown(activeDropdown === dropdownIndex ? null : dropdownIndex);
  };

  // for scroll
  const [categories, setCategories] = useState([]);
  const [horizontalCategories, setHorizontalCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groupBrands, setGroupBrands] = useState([]);

  //fetch category products
  const fetchCategories = async () => {
    try {
      const response = await api.get("categories");
      // console.log(response.data.data);
      setCategories(response.data?.data);
      setHorizontalCategories(response.data?.data.slice(0, 8));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //fetch brand
  const fetchBrands = async () => {
    try {
      const response = await api.get("brands");
      // console.log(response.data);
      setBrands(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //fetch brand by alphabet group
  const fetchGroupBrands = async () => {
    try {
      const response = await api.get("group-brands");
      // console.log(response.data);
      setGroupBrands(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsSticky(scrollTop > 350);
    };

    window.addEventListener("scroll", handleScroll);

    fetchCategories();
    fetchBrands();
    fetchGroupBrands();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getHeadingAds = async () => {
    try {
      const res = await axios.get(`${APIBASE}get-promo`);

      setNavBrief(res.data.brief);
      setNavLink(res.data.url);
      setNavLinkText(res.data.link_text);
      setNavTitle(res.data.title);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getHeadingAds();
  }, []);
  return (
    <>
      {/* for desktop */}

      <header className={`for-desktop-device ${isSticky ? "sticky" : ""}`}>
        <div className="navigation-wrap bg-light  start-header start-style">
          <div className="container-side-space">
            {/* <div className="w-100 top-header d-flex justify-content-between align-items-center">
              <div>
                <p className="mb-0 header-text">
                  <span className="text-danger fa-600">{navTitle} </span>
                  {navBrief}
                  <a
                    href={navLink}
                    target="_blank"
                    style={{
                      marginLeft: "10px",
                      fontWeight: "700",
                      textDecoration: "underline",
                    }}
                    className="text-dark text-decoration-underline"
                  >
                    {navLinkText}
                  </a>
                </p>
              </div>

              <div className="top-menu text-right py-2">
                <ul className="list-menu">
                  <li className="nav-item m-0">
                    <Link
                      className="nav-link  topmenu-padding right-border-black "
                      to="/deals"
                    >
                      Deals
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link
                      className="nav-link  topmenu-padding right-border-black"
                      to="/contact-us"
                    >
                      Contact
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link
                      className="nav-link  topmenu-padding right-border-black"
                      to="/blog"
                    >
                      Blog
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link
                      className="nav-link  topmenu-padding right-border-black"
                      to=""
                    >
                      Stores Location
                    </Link>
                  </li>

                  <li className="nav-item m-0 ">
                    <Link className="nav-link  topmenu-padding pr-0 " to="">
                      Track Your Order
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="line m-0"></div>
            <nav className="navbar navbar-expand-md  align-items-center pl-0 pr-0">
              <div className="w-15">
                <Link className="navbar-brand" to="/all-products">
                  <img src={Logo} alt="shopnmac Logo" />
                </Link>
              </div>
              <div className="w-60 w-mdd-55">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse justify-content-between align-items-center"
                  id="collapsibleNavbar"
                >
                  {/* search */}
                  <div className="search w-60 w-mdd-50">
                    <SearchComponent />
                  </div>
                  {/* end search */}

                  <div className="w-40 w-mdd-50">
                    <ul className="navbar-nav ml-5 ml-medium-3 align-items-center">
                      <li
                        className="nav-item show"
                        onMouseEnter={BrandMouseEnter}
                        onMouseLeave={BrandMouseLeave}
                      >
                        <Link
                          className="nav-link  fa-16 fa-md-14"
                          to="#"
                          aria-haspopup="true"
                          aria-expanded={isBrandOpen}
                        >
                          All Brands
                          <i className="fa fa-caret-down gray-color font-18 ml-2"></i>
                        </Link>

                        {isBrandOpen && (
                          <div
                            className="dropdown-menu "
                            onMouseEnter={BrandMouseEnter}
                            onMouseLeave={BrandMouseLeave}
                          >
                            <div className="custom-scrollbar">
                              <div className="mega-container">
                                <div className="mega-box1">
                                  {Object.keys(groupBrands)
                                    .slice(0, 4)
                                    .map((letter) => (
                                      <div className="mega-menu" key={letter}>
                                        <p className="menu-title mb-sm-1 mt-sm-3">
                                          {letter}
                                        </p>
                                        <ul className="inner-menu">
                                          {groupBrands[letter]?.map((brand) => (
                                            <li key={brand.id}>
                                              <Link
                                                className="dropdown-item"
                                                to={`/brand/${brand.slug}`}
                                              >
                                                {brand.name}
                                              </Link>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    ))}
                                </div>
                                {[0, 1, 2, 3, 4].map((colIndex) => (
                                  <div className="mega-box1" key={colIndex}>
                                    {Object.keys(groupBrands)
                                      .slice(
                                        4 + colIndex * 5,
                                        5 + colIndex * 5 + 4
                                      )
                                      .map((letter) => (
                                        <div className="mega-menu" key={letter}>
                                          <p className="menu-title mb-sm-1 mt-sm-1">
                                            {letter}
                                          </p>
                                          <ul className="inner-menu">
                                            {groupBrands[letter]?.map(
                                              (brand) => (
                                                <li key={brand.id}>
                                                  <Link
                                                    className="dropdown-item"
                                                    to={`/brand/${brand.slug}`}
                                                  >
                                                    {brand.name}
                                                  </Link>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </li>

                      <li className="nav-item mr-0 m-0 ">
                        <Link
                          className="nav-link d-flex"
                          to="tel:(441)293-5476"
                        >
                          <img
                            src={PhoneIcon}
                            className="phone-img pr-2"
                            alt="phone icon"
                          />
                          <span>
                            Hotline <br></br>
                            (441)293-5476
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="w-25 w-mdd-30">
                {/* cart / login */}
                <div className="cart_login">
                  <div className="cart">
                    <ul className="d-flex  align-items-center justify-content-end">
                      <li
                        className="nav-item show m-0"
                        onMouseEnter={LoginMouseEnter}
                        onMouseLeave={LoginMouseLeave}
                      >
                        <Link className="nav-link text-capitalize" to="#">
                          {isLoggedIn && userName ? (
                            <p className="fa-13 fw-400 mb-0 line-height-20 mt-2">
                              Hello {userName}
                            </p>
                          ) : (
                            <p className="fa-13 fw-400 mb-0 line-height-20 mt-2">
                              Hello User
                            </p>
                          )}
                          Accounts & List
                          <i className="fa fa-caret-down gray-color font-18 ml-1"></i>
                        </Link>

                        {isLoginOpen && (
                          <div
                            className="dropdown-menu"
                            onMouseEnter={LoginMouseEnter}
                            onMouseLeave={LoginMouseLeave}
                          >
                            <div className="mega-box-signin">
                              <div className="login-btn mt-2 mb-2 text-center">
                                {isLoggedIn ? (
                                  <Link
                                    className="btn btn-theme text-white"
                                    onClick={handleLogout}
                                  >
                                    Logout
                                  </Link>
                                ) : (
                                  <Link
                                    to="/Login"
                                    className="btn btn-theme text-white"
                                  >
                                    Login
                                  </Link>
                                )}
                                <p className="fw-400 fa-13 mb-0">
                                  New Customer ?
                                  <Link
                                    to="/signup"
                                    className="text-capitalize"
                                  >
                                    Start Here
                                  </Link>
                                </p>
                              </div>

                              <div className="line"></div>

                              <div className="mega-box-1">
                                <div className="mega-menu-1">
                                  <p className="menu-title mb-1">Your List</p>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard"
                                      >
                                        Dashboard
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link className="dropdown-item" to="">
                                        Create Wishlist
                                      </Link>
                                    </li> */}
                                  </ul>
                                </div>

                                <div className="mega-menu-1">
                                  <p className="menu-title mb-1">
                                    Your Account
                                  </p>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/my-profile"
                                      >
                                        Your Account
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/order"
                                      >
                                        Your Orders
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/wishlist"
                                      >
                                        Your Wish List
                                      </Link>
                                    </li>

                                    {/* <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/subscribed-products"
                                      >
                                        Your Subscribe & Save Items
                                      </Link>
                                    </li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                      <span className="vertical-divider"></span>
                      <li>
                        <Link to="/cart">
                          <span className="cart-sm">Cart</span>
                          <img
                            src={CartIcon}
                            className="cartimg ml-2"
                            alt="Cart Icon"
                          />
                          <span className="badge badge-danger border-radius-50 cart-badge">
                            {totalCount}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>

            <div className="line m-0"></div>

            <div className="bottom-header w-100 display-flex align-items-center">
              <div className="w-15">
                <ul className="catgory-list">
                  <li
                    className="nav-item show"
                    onMouseEnter={AllCategoriesMouseEnter}
                    onMouseLeave={AllCategoriesMouseLeave}
                  >
                    <Link
                      className="nav-link pl-0 fw-600 fa-16 fa-md-16 fa-mdd-14"
                      to="#"
                      aria-haspopup="true"
                      aria-expanded={isAllCategoriesOpen}
                    >
                      <i
                        className="fa fa-bars gray-color font-18 fa-md-14 mr-2"
                        aria-hidden="true"
                      ></i>
                      All Categories
                    </Link>

                    {isAllCategoriesOpen && (
                      <div
                        className="dropdown-menu side-dropdwon"
                        onMouseEnter={AllCategoriesMouseEnter}
                        onMouseLeave={AllCategoriesMouseLeave}
                      >
                        <div className="custom-scrollbar">
                          <div className="mega-box-category">
                            <div className="mega-menu-category">
                              <p className="menu-title mb-sm-1 mt-sm-3">
                                Shop By Categories
                              </p>
                              {/* Categories Mega Menu */}
                              <ul className="inner-menu float-none">
                                {categories?.map((item, index) => (
                                  <li key={index}>
                                    <Link
                                      className="dropdown-item"
                                      to={`/category/${item.slug}`}
                                    >
                                      <span className="text-capitalize">
                                        {item.name}
                                      </span>
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="line"></div>
                            {/* <div className="mega-menu-category">
                              <p className="menu-title mb-sm-1 mt-sm-3">
                                Other Categories
                              </p>
                              <ul className="inner-menu float-none">
                                <li>
                                  <Link className="dropdown-item" to="/">
                                    Deals
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Contact
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Blog
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Stores Location
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    Track Your Order
                                  </Link>
                                </li>
                              </ul>
                            </div> */}
                            <div className="line"></div>
                            <div className="mega-menu-category mt-2">
                              <p className="menu-title mb-sm-1 mt-sm-3">
                                Help & Support
                              </p>
                              <ul className="inner-menu float-none">
                                <li>
                                  <Link
                                    className="dropdown-item"
                                    to="tel:(441)293-5476"
                                  >
                                    Hot Line : (441)293-5476
                                  </Link>
                                </li>
                                <li>
                                  <Link className="dropdown-item" to="#">
                                    FAQ
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
              <div className="w-85">
                <div className="category-menu">
                  <ul className="list-bottom-menu">
                    {/* Horizontal categories */}
                    {horizontalCategories?.map((hCategory, index) => (
                      <li key={index} className="nav-item m-0">
                        <Link
                          className="nav-link  topmenu-padding pl-0"
                          to={`/category/${hCategory.slug}`}
                        >
                          <span className="text-capitalize">
                            {hCategory.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* for mobile */}

      <header className={`for-mobile-device ${isSticky ? "sticky" : ""}`}>
        <div className="navigation-wrap bg-light  start-header start-style">
          <div className="container-side-space">
            <div className="line m-0"></div>
            <nav className="navbar navbar-expand-md  align-items-center pl-0 pr-0">
              <div className="w-15">
                <Link className="navbar-brand" to="/all-products">
                  <img src={Logo} alt="shopnmac Logo" />
                </Link>
              </div>

              <div className="w-85">
                {/* cart / login */}
                <div className="cart_login">
                  <div className="cart">
                    <ul className="d-flex  align-items-center justify-content-end">
                      {/* <li> <Link to="/login" >Login</Link> </li> */}

                      <li
                        className="nav-item show m-0"
                        onMouseEnter={LoginMouseEnter}
                        onMouseLeave={LoginMouseLeave}
                      >
                        <Link
                          className="nav-link text-capitalize lg-device"
                          to="#"
                        >
                          <p className="fa-13 fw-400 mb-0 line-height-20 mt-2">
                            Hello {userName}
                          </p>
                          Accounts & List
                          <i className="fa fa-caret-down gray-color font-18 ml-1"></i>
                        </Link>

                        <Link className="sm-device">
                          <i
                            className="fa fa-user-circle fa-24"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        {isLoginOpen && (
                          <div
                            className="dropdown-menu"
                            onMouseEnter={LoginMouseEnter}
                            onMouseLeave={LoginMouseLeave}
                          >
                            <div className="mega-box-signin">
                              <div className="login-btn mt-2 mb-2 text-center">
                                {isLoggedIn ? (
                                  <Link
                                    className="btn btn-theme text-white"
                                    onClick={handleLogout}
                                  >
                                    Logout
                                  </Link>
                                ) : (
                                  <Link
                                    to="/Login"
                                    className="btn btn-theme text-white"
                                  >
                                    Login / Regiter
                                  </Link>
                                )}
                                <p className="fw-400 fa-13 mb-0">
                                  New Customer ?
                                  <Link
                                    to="/signup"
                                    className="text-capitalize"
                                  >
                                    Start Here
                                  </Link>
                                </p>
                              </div>

                              <div className="line"></div>

                              <div className="mega-box-1">
                                <div className="mega-menu-1">
                                  <p className="menu-title mb-1">Your List</p>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link className="dropdown-item" to="/dashboard">
                                        Dashboard
                                      </Link>
                                    </li>
                                  </ul>
                                </div>

                                <div className="mega-menu-1">
                                  <p className="menu-title mb-1">
                                    Your Account
                                  </p>
                                  <ul className="inner-menu">
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/my-profile"
                                      >
                                        Your Account
                                      </Link>
                                    </li>

                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/order"
                                      >
                                        Your Orders
                                      </Link>
                                    </li>
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/wishlist"
                                      >
                                        Your Wish List
                                      </Link>
                                    </li>
                                    {/* 
                                    <li>
                                      <Link
                                        className="dropdown-item"
                                        to="/dashboard/subscribed-products"
                                      >
                                        Your Subscribe & Save Items
                                      </Link>
                                    </li> */}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </li>
                      <span className="vertical-divider"></span>
                      <li>
                        <Link to="/cart">
                          <img
                            src={CartIcon}
                            className="cartimg ml-2"
                            alt="Cart Icon"
                          />
                          <span className="badge badge-danger border-radius-50 cart-badge">
                            {totalCount}
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>

            <div className="line m-0"></div>

            <div className="bottom-header w-100 display-flex align-items-center py-2">
              <div className="w-10">
                <span
                  style={{ fontSize: "30px", cursor: "pointer" }}
                  onClick={openNav}
                >
                  <i
                    className="fa fa-bars gray-color font-18 fa-md-16 mr-2"
                    aria-hidden="true"
                  ></i>
                </span>
              </div>
              <div className="w-90">
                <div className="search w-100">
                  <SearchComponent categories={categories} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div>
        <div id="myNav" className={`overlay ${isNavOpen ? "open" : ""}`}>
          <div className="d-flex justify-content-between p-3">
            <div className="d-flex align-items-center">
              <div className="profile-icon">
                <img src={Dummyimage} alt="user profile" />
              </div>
              <p className="mb-0 text-black ml-3">
                {" "}
                {userName} {userLastName}{" "}
              </p>
            </div>
            <Link className="closebtn" onClick={closeNav}>
              &times;
            </Link>
          </div>
          <div className="line mx-2 mb-4" />
          <div className="overlay-content">
            <div className={`dropdown ${activeDropdown === 1 ? "open" : ""}`}>
              <div className="d-flex justify-content-between align-items-center">
                <Link to="#" onClick={() => toggleDropdown(1)}>
                  All Categories{" "}
                  <span className="text-right">
                    {" "}
                    <i
                      className={`fas ${activeDropdown === 1 ? "fa-angle-up" : "fa-angle-down"
                        }  fa-20`}
                    />
                  </span>
                </Link>
              </div>

              <div className="dropdown-content">
                {categories?.map((item, index) => (
                  <Link
                    key={index}
                    to={`/category/${item.slug}`}
                    onClick={closeNav}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            <div className={`dropdown ${activeDropdown === 2 ? "open" : ""}`}>
              <div className="d-flex justify-content-between align-items-center">
                <Link to="#" onClick={() => toggleDropdown(2)}>
                  Brand{" "}
                  <span className="text-right">
                    {" "}
                    <i
                      className={`fas ${activeDropdown === 1 ? "fa-angle-up" : "fa-angle-down"
                        }  fa-20`}
                    />
                  </span>
                </Link>
              </div>

              <div className="dropdown-content">
                {brands?.map((brand) => (
                  <Link
                    key={brand.id}
                    to={`/brand/${brand.slug}`}
                    onClick={closeNav}
                  >
                    {brand.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* <p className="menu-title mb-sm-1 mt-3 ">Other Categories</p> */}

            {/* <Link to="#" onClick={closeNav}>
              Deals
            </Link>
            <Link to="#" onClick={closeNav}>
              Contact
            </Link>
            <Link to="#" onClick={closeNav}>
              Blog
            </Link> */}
            <Link to="#" onClick={closeNav}>
              Stores Location
            </Link>
            <Link to="#" onClick={closeNav}>
              Track Your Order
            </Link>
            <p className="menu-title mb-sm-1 mt-3" onClick={closeNav}>
              HELP & SUPPORT
            </p>
            <Link to="#" onClick={closeNav}>
              Hot Line : (441)293-5476
            </Link>
            <Link to="#" onClick={closeNav}>
              FAQ
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavComponent;
