import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import CardComponent from "./CardCompnent";
import { BiDollar } from "react-icons/bi";
import { BsBoxSeam } from "react-icons/bs";
import DefaultAddress from "./DefaultAddress";
import { AiOutlineShoppingCart, AiFillHeart } from "react-icons/ai";
import ProductCard from "./ProductCard";
// import { Link, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import authApi from "../../../../utils/authApi";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const [totalOrderValue, setTotalOrderValue] = useState(0);
  const totalCartCount = useSelector((state) => state.userCart.totalCount);
  // // comsole.log(error);("cart count",totalCartCount);
  const [productData, setProductData] = useState([]);
  const [totalWishlistCount, setTotalWishlistCount] = useState(0);

  const getAllWishlist = async () => {
    authApi
      .get("customer/get-user-wishlist")
      .then((response) => {
        const res = response.data.data;
        setProductData(res);
        setTotalWishlistCount(res.length);
        // // comsole.log(error);("get-user-wishlist", res.data.data);
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };

  // // comsole.log(error);("setTotalWishlistCount", totalWishlistCount);
  useEffect(() => {
    getAllWishlist();
  }, []);

  // // comsole.log(error);("wishlisT", productData);

  // const apiEndpoint = 'https://snapi.quicsy.com/api/customer/get-total-orders';

  useEffect(() => {
    // Axios GET request with authorization header
    authApi
      .get("customer/get-total-orders")
      .then((response) => {
        const totalValue = response.data["Total Orders"];
        setTotalOrderValue(totalValue);
        // // comsole.log(error);(totalValue);
      })
      .catch((error) => {
        console.error("Error fetching order value:", error);
      });
  }, []);

  // useEffect(() => {
  //   // Axios GET request with authorization header
  //   authApi
  //     .get("/customer/get-user-wishlist")
  //     .then((response) => {
  //       const totalValue = response.data.dataproduct.id;
  //       setWishlistCount(totalValue);
  //       // comsole.log(error);("totalWishlistCount", totalValue);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching order value:", error);
  //     });
  // }, []);

  const [totalSubscription, setTotalSubscription] = useState(0);

  useEffect(() => {
    // Axios GET request with authorization header
    authApi
      .get("customer/get-user-total-subscription")
      .then((response) => {
        const totalSubs = response.data["total_subsriptions"];
        setTotalSubscription(totalSubs);
        // // comsole.log(error);("totalSubscription", totalSubs);
      })
      .catch((error) => {
        console.error("Error fetching order value:", error);
      });
  }, []);

  const [totalExpanditure, setTotalExpanditure] = useState("");

  useEffect(() => {
    // Axios GET request with authorization header
    authApi
      .get(`customer/get-user-expenditure`)
      .then((response) => {
        const totalExpand = response.data["Total Expenditure"];
        setTotalExpanditure(totalExpand);
        // // comsole.log(error);("totalExpanditure", totalExpand);
      })
      .catch((error) => {
        console.error("Error fetching order value:", error);
      });
  }, []);

  // const navigate = useNavigate();

  // const handleLinkClick = (url) => {
  //   navigate(url);
  // };

  return (
    <>
      <div className="row">
        {/* card component*/}
        {/* <Link to></Link> */}
        <CardComponent
          backgroundColor="#2b2d42"
          color="#efefef"
          title="Total Expenditure"
          totalvalue={`$${totalExpanditure}`}
          linkText="View Order History"
          icon={BiDollar}
          righticon={FaAngleRight}
          // onClick={() => navigate("/dashboard/order")}
          // onClick={() => handleLinkClick("/dashboard/order")}
        />

        {/* <CardComponent
          backgroundColor="#8d99ae"
          color="#fff"
          title="Subscribed Products"
          totalvalue={`${totalSubscription}`}
          linkText="View Products"
          icon={BsBoxSeam}
          righticon={FaAngleRight}
        ></CardComponent> */}
        <CardComponent
          backgroundColor="#edf2f4"
          color="#333"
          title="My Orders"
          totalvalue={`${totalOrderValue}`}
          linkText="View Order"
          link="/dashboard/order"
          icon={BsBoxSeam}
          righticon={FaAngleRight}
        ></CardComponent>
        {/*end  card component*/}

        {/*start DefaultAddress */}

        <DefaultAddress />

        {/* end DefaultAddress */}

        {/* start CardComponent */}

        <CardComponent
          backgroundColor="#9e9e9e1a"
          // className="mt-4 border-1"
          color="#333"
          title="Product In Cart"
          totalvalue={`(${totalCartCount}) Items`}
          icon={AiOutlineShoppingCart}
          righticon={FaAngleRight}
          linkText="View Cart"
          link="/cart"
        ></CardComponent>
        <CardComponent
          backgroundColor="#9e9e9e1a"
          className="mt-4 border-1"
          color="#333"
          title="Product In Wishlist"
          totalvalue={`(${totalWishlistCount}) Items`}
          link="/dashboard/wishlist"
          icon={AiFillHeart}
        ></CardComponent>

        {/* end CardComponent */}
      </div>

      {/* Start wishlist */}

      <div className="wishlist-section mt-4">
        <div className="row">
          <div className="col-6">
            <div className="member-heading">
              <h3>My Wishlist</h3>
            </div>
          </div>
          <div className="col-6 text-right">
            <Link className="text-link text-right" to="dashboard/wish-list">
              All View
            </Link>
          </div>
        </div>
        <div className="row mt-3">
          {/* start ProductCard */}
          {productData.map((wishlist, index) => (
            <ProductCard key={index} product={wishlist.product} />
          ))}

          {/* <ProductCard />
          <ProductCard />
          <ProductCard /> */}

          {/* end  ProductCard */}
        </div>
      </div>

      {/* end wishlist */}
    </>
  );
};
export default Dashboard;
