import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ProductCart.css";
import { toast } from "react-toastify";

export default function MobProductCart({ product, onAddToCart }) {
  const [interval, setInterval] = useState(0);
  const [sQty, setSQty] = useState(1);
  const [qty, setQty] = useState(1);
  const [subscriptionDiscountedPrice, setSubscriptionDiscountedPrice] =
    useState(0);
  const [type, setType] = useState("one time purchase");

  //Qty decrement
  const handleDecrement = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };

  //Qty increment
  const handleIncrement = () => {
    if (qty < 20) {
      setQty(qty + 1);
    }
  };

  //Qty decrement
  const handleSubsDecrement = () => {
    if (sQty > 1) {
      setSQty(sQty - 1);
    }
  };

  //Qty increment
  const handleSubsIncrement = () => {
    if (sQty < 20) {
      setSQty(sQty + 1);
    }
  };

  const handleIntervalChange = (e) => {
    setInterval(parseInt(e.target.value));
  };

  const handleAddToCart = () => {
    onAddToCart(product, qty);
  };

  const handleAddToSubscription = () => {
    if (interval > 0) {
      onAddToCart(product, sQty, interval);
    } else {
      toast.error("Please select a subscription month.");
    }
  };

  const handleRadioChange = (e, val) => {
    setType(e.target.name);
  };

  useEffect(() => {
    const subscriptionXDiscount = () => {
      if (interval > 0 && product && product.subscriptions) {
        const matchingSubscription = product.subscriptions.find(
          (subscription) => subscription.months === parseInt(interval)
        );

        if (matchingSubscription) {
          const discountPercentage = matchingSubscription.discount || 0; // Default to 0 if discount is undefined
          const discountedPrice = (
            product.selling_price -
            (discountPercentage / 100) * product.selling_price
          ).toFixed(2);
          setSubscriptionDiscountedPrice(discountedPrice);
        } else {
          setSubscriptionDiscountedPrice(0);
        }
      } else {
        setSubscriptionDiscountedPrice(0);
      }
    };
    subscriptionXDiscount();
  }, [interval, product]);

  return (
    <div className="accordion" id="accordionWithRadioExample_PreChecked">
      <div className="card">
        <div className="card-header border-bottom-0 m-1">
          <div className="custom-control pl-0 d-flex align-items-start">
            <input
              // defaultChecked
              // data-toggle="collapse"
              // data-target="#collapseOne_PreChecked"
              style={{ cursor: "pointer" }}
              type="radio"
              id="customRadio1_PreChecked_mob"
              checked={type === "one time purchase mob"}
              name="one time purchase mob"
              className="mt-2 mr-2"
              onChange={(e) => handleRadioChange(e)}
            />
           <label className="font-weight-600 text-dark  fa-18x fa-lg-16 fa-md-14 cursor-pointer" htmlFor="customRadio1_PreChecked_mob"  style={{cursor: 'pointer'}}>

             One Time Purchase
              <p className="text-price">
                {" "}
                &nbsp; &nbsp;US ${product.selling_price}
              </p>
            </label>
          </div>
        </div>

        {type === "one time purchase mob" && (
          <div
          // id="collapseOne_PreChecked"
          // className="collapse show"
          // data-parent="#accordionWithRadioExample_PreChecked"
          >
            <div className="card-body">
              {product.stock > 0 && (
                <p className="text-stock text-success">
                  {product.stock} In Stock
                </p>
              )}

              {product.stock <= 0 && (
                <p className="text-stock text-danger">Out of Stock</p>
              )}

              <div className="qty">
                <button
                  className="qtyminus border-right-0 qty-border-left"
                  aria-hidden="true"
                  onClick={handleDecrement}
                >
                  &minus;
                </button>
                <input
                  className="border-left-0 border-right-0"
                  type="number"
                  name="qty"
                  value={qty}
                  readOnly
                />
                <button
                  className="qtyplus border-left-0 qty-border-right"
                  aria-hidden="true"
                  onClick={handleIncrement}
                >
                  &#43;
                </button>
              </div>
              <button
                onClick={handleAddToCart}
                className="btn btn-black mt-3 mb-3"
              >
                Add To Cart
              </button>
            </div>
          </div>
        )}
      </div>

      {/* {product?.subscriptions && product.subscriptions.length > 0 && ( */}
      <div className="card">
        {product?.subscriptions && product.subscriptions.length > 0 && (
          <>
            <div className="card-header border-bottom-0 m-1">
              <div className="custom-control pl-0 d-flex align-items-start">
                <input
                  // data-toggle="collapse"
                  // data-target="#collapseTwo_PreChecked"
                  style={{ cursor: "pointer" }}
                  type="radio"
                  id="customRadio2_PreChecked_mob"
                  onChange={(e) => handleRadioChange(e)}
                  checked={type === "SubscribeMob"}
                  name="SubscribeMob"
                  className="mt-2 mr-2"
                />
                <label className="font-weight-600 text-dark  fa-18x fa-lg-16 fa-md-14 cursor-pointer" htmlFor="customRadio2_PreChecked_mob"  style={{cursor: 'pointer'}}
                // className="custom-control-label font-weight-600 text-dark fa-18x  fa-lg-16 fa-md-14 cursor-pointer"
                // htmlFor="customRadio2_PreChecked"
                >
                  Subscribe & Save
                  <p className="text-price">
                    <span>
                      US $
                      {+subscriptionDiscountedPrice > 0
                        ? subscriptionDiscountedPrice
                        : product?.selling_price}
                    </span>
                    &nbsp;&nbsp;
                    {+subscriptionDiscountedPrice > 0 && (
                      <span
                        style={{
                          textDecorationLine: "line-through",
                          marginLeft: "6px",
                        }}
                      >
                        ${product?.selling_price}
                      </span>
                    )}
                  </p>
                </label>
              </div>
            </div>

            {type === "SubscribeMob" && (
              <div
              // id="collapseTwo_PreChecked"
              // className="collapse"
              // data-parent="#accordionWithRadioExample_PreChecked"
              >
                <div className="card-body">
                  {product.stock > 0 && (
                    <p className="text-stock text-success">
                      {product.stock} In Stock
                    </p>
                  )}

                  {product.stock <= 0 && (
                    <p className="text-stock text-danger">Out of Stock</p>
                  )}

                  <div className="qty">
                    <button
                      className="qtyminus border-right-0 qty-border-left"
                      aria-hidden="true"
                      onClick={handleSubsDecrement}
                    >
                      &minus;
                    </button>
                    <input
                      className="border-left-0 border-right-0"
                      type="number"
                      name="qty"
                      value={sQty}
                      readOnly
                    />
                    <button
                      className="qtyplus border-left-0 qty-border-right"
                      aria-hidden="true"
                      onClick={handleSubsIncrement}
                    >
                      &#43;
                    </button>
                  </div>

                  <div className="subscribe-list mt-3">
                    <label htmlFor="subscription">Delivery Every</label>
                    <select
                      className="form-control"
                      id="subscription"
                      value={interval}
                      onChange={handleIntervalChange}
                    >
                      <option value="0">Select Month</option>
                      {product?.subscriptions.map((subscription, index) => (
                        <option key={index} value={subscription.months}>
                          {subscription.months} month
                        </option>
                      ))}
                      {/* <option value="3">3 months</option> */}
                    </select>
                  </div>

                  <Link
                    onClick={handleAddToSubscription}
                    className="btn btn-yellow mt-3 mb-3"
                    to=""
                  >
                    Subscribe Now & Buy
                  </Link>

                  <div className="subscribe-text">
                    <p>Monthly Auto-deliveries sold by Shopnmac </p>
                    {/* <p>Save {subsDis}% </p>
                  <p>Subscription discount with {interval} Month</p> */}
                    {product?.subscriptions.map((subscription, index) => (
                      <div key={index}>
                        <p>Save {subscription.discount}% </p>
                        <p>On subscription with {subscription.months} Months</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
