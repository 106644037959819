import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

// const Protected = () => {
//   // const navigate = useNavigate();
//   const isLoggedIn = useSelector((state) => state.userAuth.isLoggedIn);

//   if (!isLoggedIn) {
//     <Navigate to="/login" replace />; // Redirect to login page if user is not authenticated
//   }
// };

// export default Protected;

const ProtectedRoute = () => {
  const authenticated = useSelector((state) => state.userAuth.isLoggedIn);

  if (!authenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" />;
  }

  // Render the nested routes if authenticated
  return <Outlet />;
};

export default ProtectedRoute;
