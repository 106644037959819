import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { APIBASE, IMAGEURL } from "../../../../constants";
import { fetchWishlist } from "../../../../redux/productSlice";
import authApi from "../../../../utils/authApi";

const DelevariesProductCard = () => {
  const [productData, setProductData] = useState([]);
  const user_id = useSelector((state) => state.userAuth.user.data?.id);
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.products.wishlist);

  // Remove redundant state 'isWishForItem' since it's not used

  const handleAddToWishList = async (id) => {
    const payload = { user_id: user_id || null, product_id: id };
    if (user_id && id) {
      try {
        await axios.post(`${APIBASE}wishlists`, payload);
        toast.success("Product added to wishlist successfully.", { autoClose: 300 });
        dispatch(fetchWishlist());
      } catch (error) {
        console.error(error);
        toast.error("Error!", { autoClose: 300 });
      }
    }
  };

  const handleDeleteWishlist = async (id) => {
    try {
      await authApi.delete(`/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      dispatch(fetchWishlist());
    } catch (error) {
      // comsole.log(error);(error);
      toast.error("Error");
    }
  };

  const SubscribedProducts = async () => {
    try {
      const response = await authApi.get("customer/get-next-deliveries-subscription");
      setProductData(response.data);
      // comsole.log(error);("active", response.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    SubscribedProducts();
  }, []);

  // comsole.log(error);("products to get", productData);

  const handleCancelSubscription = async (subscriptionId) => {
    try {
      const payload = { subscription_id: subscriptionId };
      const response = await authApi.post("customer/cancel-subscription", payload);
      // comsole.log(error);(response);
      toast.success("Subscription canceled successfully");
      SubscribedProducts();
      // comsole.log(error);(productData);
    } catch (error) {
      toast.error("Failed to cancel subscription");
      console.error("Error canceling subscription:", error);
    }
  };

  const checkWish = (id) => {
    const filter = wishlist?.filter((elem) => elem?.product?.id === id);
    return filter?.length > 0;
  };

  return (
    <div className="">
      {productData.map((product, index) => {
        const items = JSON.parse(product.items);
        return (
          <div className="custom-subscribed-products" key={index}>
            <div className="row">
              {items.map((item, itemIndex) => {
                const itemProductID = item.product_id;
                const isWishForItem = checkWish(itemProductID);
                return (
                  <div key={itemIndex} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
                    <div className="product-card">
                      <div className="prod-img">
                        {/* <img
                          className="image-main"
                          src={item.thumbnail_name ? `${IMAGEURL}upload/product/thumbnail/300/${item.image}` : `${IMAGEURL}${item.image}`}
                          alt="ProductImage"
                        /> */}
                        <img
                          className="image-main"
                          src={
                            item?.thumbnail_compress
                              ? JSON.parse(item.thumbnail_compress).image_urls["300px"]
                              : `${IMAGEURL}${item?.thumbnail}`
                          }
                          alt={item?.name}
                        />
                      </div>
                      <div className="over-layer">
                        <div className="pr-3 pt-3">
                          <div className="pt-2 pb-1">
                            {isWishForItem ? (
                              <Link onClick={() => handleDeleteWishlist(itemProductID)}>
                                <i className="fas fa-heart red-color"></i>
                              </Link>
                            ) : (
                              <Link onClick={() => handleAddToWishList(itemProductID)}>
                                <i className="far fa-heart gray-color"></i>
                              </Link>
                            )}
                          </div>
                          <div className="pt-2 pb-1">
                            <Link to={`/product-detail/${items[0].slug}`}>
                              <i className="far fa-eye gray-color"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="prod-content">
                        <p className="price-text mt-3 mb-2">
                          <span>US ${item.price}</span>
                        </p>
                        <p className="prod-description pb-1">{item.name}</p>
                        <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                          Quantity <strong>{item.qty}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <div className="subs-details-cancel">
              <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product?.subscription_no)}>
                <Link to="" className="addCart">
                  Cancel Subscription
                </Link>
              </button>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                Next delivery by <strong>{product?.next_date}</strong>
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                <strong>{product?.month} </strong> month's subscription
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px", width: "200px" }}>
                Subscription No <strong>{product?.subscription_no}</strong>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DelevariesProductCard;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { AiFillStar } from "react-icons/ai";
// import authApi from "../../../../utils/authApi";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { fetchWishlist } from "../../../../redux/productSlice";

// const DelevariesProductCard = () => {
//   const [productData, setProductData] = useState([]);
//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const dispatch = useDispatch();
//   const wishlist = useSelector((state) => state.products.wishlist);


//   const handleAddToWishList = async (id) => {
//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("Product added to wishlist successfully.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     }
//   };

//   const handleDeleteWishlist = async (id) => {
//     try {
//       await authApi.delete(`/customer/wishlists/${id}`);
//       toast.success("Item deleted from wishlist.");
//       dispatch(fetchWishlist());
//     } catch (error) {
//       // comsole.log(error);(error);
//       toast.error("Error");
//     }
//   };

//   const SubscribedProducts = async () => {
//     authApi
//       .get("customer/get-next-deliveries-subscription")
//       .then((response) => {
//         const res = response?.data?.data.data;
//         setProductData(res);
//         // comsole.log(error);("active", res);
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   }
//   useEffect(() => {
//     SubscribedProducts()
//   }, []);

//   // comsole.log(error);("products to get", productData);

//   const handleCancelSubscription = async (subscriptionId) => {
//     try {
//       const payload = {
//         subscription_id: subscriptionId,
//       };

//       const response = await authApi.post("customer/cancel-subscription", payload);

//       // comsole.log(error);(response);

//       // Show a success toast message
//       toast.success("Subscription canceled successfully");

//       SubscribedProducts();
//       // comsole.log(error);(productData);
//     } catch (error) {
//       // Show an error toast message
//       toast.error("Failed to cancel subscription");
//       console.error("Error canceling subscription:", error);
//     }
//   };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem?.product_id === id);

//     return filter?.length > 0 ? filter[0]?.id : "";
//   };

//   return (
//     <div className="">
//       {productData.map((product, index) => {
//         const items = JSON.parse(product?.items);
//         return (
//           <div className="custom-subscribed-products">
//             <div className="row">

//               {items.map((item, itemIndex) => {
//                 const itemProductID = item?.product_id;
//                 const isWishForItem = checkWish(itemProductID);
//                 return (
//                   <div key={itemIndex} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
//                     <div className="product-card">


//                       <div className="prod-img">
//                         <img
//                           className="image-main"
//                           src={item?.thumbnail_name ?
//                             `${IMAGEURL}upload/product/thumbnail/300/${item?.image}` :
//                             `${IMAGEURL}${item?.image}`}
//                           alt="ProductImage"
//                         />
//                       </div>

//                       <div className="over-layer">
//                         <div className="pr-3 pt-3">
//                           <div className="pt-2 pb-1">
//                           {isWishForItem ? (
//                               <Link onClick={() => handleDeleteWishlist(itemProductID)}>
//                                 <i className="fas fa-heart red-color"></i>
//                               </Link>
//                             ) : (
//                               <Link onClick={() => handleAddToWishList(itemProductID)}>
//                                 <i className="far fa-heart gray-color"></i>
//                               </Link>
//                             )}
//                           </div>
//                           <div className="pt-2 pb-1">
//                             <Link to={`/product-detail/${items[0]?.slug}`}>
//                               <i className="far fa-eye gray-color"></i>
//                             </Link>
//                           </div>
//                         </div>
//                       </div>
//                       <div className="prod-content">
//                         <p className="price-text mt-3 mb-2">
//                           <span>US ${item?.price}</span>
//                         </p>
//                         <p className="prod-description pb-1">{item?.name}</p>
//                         <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                           Quantity <strong>{item?.qty}</strong>
//                         </p>

//                       </div>
//                     </div>
//                   </div>
//                 );
//               })}
//             </div>

//             <hr />

//             <div className="subs-details-cancel">
//               <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product?.subscription_no)}>
//                 <Link to="" className="addCart">
//                   Cancel Subscription
//                 </Link>
//               </button>
//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 Next delivery by <strong>{product?.next_date}</strong>
//               </p>
//               <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                 <strong>{product?.month} </strong> month's subscription
//               </p>
//               <p className="prod-description pb-1" style={{ fontSize: "10px", width: "200px" }}>
//                 Subscription No <strong>{product?.subscription_no}</strong>
//               </p>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default DelevariesProductCard;
