import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { APIBASE, IMAGEURL } from "../../../../constants";
import { fetchWishlist } from "../../../../redux/productSlice";
import authApi from "../../../../utils/authApi";

const SubscribedProductCard = () => {
  const [productData, setProductData] = useState([]);
  const user_id = useSelector((state) => state.userAuth.user.data?.id);
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.products.wishlist);

  // Remove redundant state 'isWishForItem' since it's not used

  const handleAddToWishList = async (id) => {
    const payload = { user_id: user_id || null, product_id: id };
    if (user_id && id) {
      try {
        await axios.post(`${APIBASE}wishlists`, payload);
        toast.success("Product added to wishlist successfully.", { autoClose: 300 });
        dispatch(fetchWishlist());
      } catch (error) {
        console.error(error);
        toast.error("Error!", { autoClose: 300 });
      }
    }
  };

  const handleDeleteWishlist = async (id) => {
    try {
      await authApi.delete(`/customer/wishlists/${id}`);
      toast.success("Item deleted from wishlist.");
      dispatch(fetchWishlist());
    } catch (error) {
      // comsole.log(error);(error);
      toast.error("Error");
    }
  };

  const SubscribedProducts = async () => {
    try {
      const response = await authApi.get("customer/get-active-subscription");
      setProductData(response.data);
      // comsole.log(error);("active", response.data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };

  useEffect(() => {
    SubscribedProducts();
  }, []);

  // comsole.log(error);("products to get", productData);

  const handleCancelSubscription = async (subscriptionId) => {
    try {
      const payload = { subscription_id: subscriptionId };
      const response = await authApi.post("customer/cancel-subscription", payload);
      // comsole.log(error);(response);
      toast.success("Subscription canceled successfully");
      SubscribedProducts();
      // comsole.log(error);(productData);
    } catch (error) {
      toast.error("Failed to cancel subscription");
      console.error("Error canceling subscription:", error);
    }
  };

  const checkWish = (id) => {
    const filter = wishlist?.filter((elem) => elem?.product?.id === id);
    return filter?.length > 0;
  };

  return (
    <div className="">
      {productData.map((product, index) => {
        const items = JSON.parse(product.items);
        return (
          <div className="custom-subscribed-products" key={index}>
            <div className="row">
              {items.map((item, itemIndex) => {
                const itemProductID = item.product_id;
                const isWishForItem = checkWish(itemProductID);
                return (
                  <div key={itemIndex} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
                    <div className="product-card">
                      <div className="prod-img">
                        {/* <img
                          className="image-main"
                          src={item.thumbnail_name ? `${IMAGEURL}upload/product/thumbnail/300/${item.image}` : `${IMAGEURL}${item.image}`}
                          alt="ProductImage"
                        /> */}
                        <img
                          className="image-main"
                          src={
                            item?.thumbnail_compress
                              ? JSON.parse(item.thumbnail_compress).image_urls["300px"]
                              : `${IMAGEURL}${item?.thumbnail}`
                          }
                          alt={item?.name}
                        />
                      </div>
                      <div className="over-layer">
                        <div className="pr-3 pt-3">
                          <div className="pt-2 pb-1">
                            {isWishForItem ? (
                              <Link onClick={() => handleDeleteWishlist(itemProductID)}>
                                <i className="fas fa-heart red-color"></i>
                              </Link>
                            ) : (
                              <Link onClick={() => handleAddToWishList(itemProductID)}>
                                <i className="far fa-heart gray-color"></i>
                              </Link>
                            )}
                          </div>
                          <div className="pt-2 pb-1">
                            <Link to={`/product-detail/${items[0].slug}`}>
                              <i className="far fa-eye gray-color"></i>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="prod-content">
                        <p className="price-text mt-3 mb-2">
                          <span>US ${item.price}</span>
                        </p>
                        <p className="prod-description pb-1">{item.name}</p>
                        <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                          Quantity <strong>{item.qty}</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <hr />
            <div className="subs-details-cancel">
              <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product.subscription_no)}>
                <Link to="" className="addCart">
                  Cancel Subscription
                </Link>
              </button>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                Next delivery by <strong>{product.next_date}</strong>
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
                <strong>{product.month} </strong> month's subscription
              </p>
              <p className="prod-description pb-1" style={{ fontSize: "10px", width: "200px" }}>
                Subscription No <strong>{product.subscription_no}</strong>
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SubscribedProductCard;



// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { FiChevronDown } from "react-icons/fi";
// import Moment from "react-moment";
// // import TrackPackagePopup from "./TrackPackagePopup";
// // import OrderDetailsPopup from "./OrderDetailsPopup";
// // import { usePopup } from "./PopupContext";
// import { Modal, ModalBody, ModalHeader } from "reactstrap";
// import authApi from "../../../../utils/authApi";
// import { IMAGEURL } from "../../../../constants";
// import ReactPaginate from "react-paginate";

// const SubscribedProductCard = () => {
//   // const { showPopup, openPopup, closePopup } = usePopup();

//   const [orderData, setOrderData] = useState([]);
//   const [track, setTrack] = useState(false);
//   const [currentOrderId, setCurrentOrderId] = useState(null);
//   const [myHoverId, setMyHoverId] = useState("");
//   const [totalPages, setTotalPages] = useState(1);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [shippingAddress, setShippingAddress] = useState({});
//   const [hoverEffect, setHoverEffect] = useState(false); // Initially set to false
//   const [isLoading, setIsLoading] = useState(false);

//   const displayNone = {
//     display: "none",
//   };

//   const displayInherit = {
//     display: "inherit",
//   };

//   const handlePageChange = (selectedPage) => {
//     const newPage = selectedPage.selected + 1;
//     fetchOrders({ page: newPage });
//   };

//   const handleMouseHover = (id) => {
//     setMyHoverId(id);
//     setHoverEffect(true); // Show the dropdown
//   };

//   const handleMouseRemove = () => {
//     setMyHoverId("");
//     setHoverEffect(false); // Hide the dropdown
//   };

//   const fetchOrders = async ({ page }) => {
//     try {
//       setIsLoading(true);
//       const response = await authApi.get(`customer/get-orders`);
//       const data = response.data.data;
//       setOrderData(data.data);
//       setTotalPages(Math.ceil(data.total / data.per_page));
//       setCurrentPage(data.current_page);
//     } catch (error) {
//       // comsole.log(error);("Error fetching orders:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchOrders({ page: currentPage });
//   }, [currentPage]);

//   useEffect(() => {
//     if (orderData.length > 0) {
//       const firstOrder = orderData[0];
//       const parsedAddress = JSON.parse(firstOrder.shipping_address);
//       setShippingAddress(parsedAddress);
//     }
//   }, [orderData]);

//   const handleOrdDetailClick = (id) => {
//     // openPopup();
//     setCurrentOrderId(id);
//   };

//   const handleTrackPackageClick = (id) => {
//     setTrack(true);
//     setCurrentOrderId(id);
//   };

//   return (
//     <>
//       <div>
//         {/* <Modal isOpen={track} toggle={() => setTrack(!track)}>
//           <ModalHeader toggle={() => setTrack(!track)}>
//             <div style={{ display: "flex", justifyContent: "space-between" }}>
//               Track Order
//             </div>
//           </ModalHeader>
//           <ModalBody>
//             {currentOrderId !== null && (
//               <TrackPackagePopup orderId={currentOrderId} />
//             )}
//           </ModalBody>
//         </Modal> */}
//       </div>

//       {isLoading ? (
//         <div className="loader-container" style={{ display: "flex" }}>
//           <h6>Loading...</h6>
//         </div>
//       ) : orderData.length === 0 ? (
//         <div className="no-orders">
//           <p>No orders available.</p>
//         </div>
//       ) : (
//         <div className="row">
//           <div className="col-lg-12 col-md-12">
//             {orderData.map((order) => (
//               <div className="order-list" key={order.id}>
//                 {/* {showPopup && currentOrderId === order.id && (
//                   <OrderDetailsPopup
//                     orderId={currentOrderId}
//                     onClose={closePopup}
//                   />
//                 )} */}

//                 <div className="order-header p-3">
//                   <div className="row">
//                     <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12">
//                       {/* Order header content */}
//                       <div className="d-block d-sm-flex justify-content-between align-items-center">
//                         <div className="order-header-text">
//                           <ul>
//                             <li>ORDER PLACED ON</li>
//                             <Moment format="DD MMM-YYYY">
//                               {order?.created_at}
//                             </Moment>
//                             <li>
//                               <strong>Order Status:</strong> {order?.order_status}
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="order-header-text">
//                           <ul>
//                             <li>SHIP TO</li>
//                             <strong>
//                               {shippingAddress?.first_name}{" "}
//                               {shippingAddress?.last_name}
//                             </strong>
//                             <li
//                               onMouseEnter={() => handleMouseHover(order.id)}
//                               onMouseLeave={handleMouseRemove}
//                             >
//                               <Link
//                                 to="#"
//                                 className="link-text"
//                                 style={{ fontWeight: "500" }}
//                               >
//                                 <FiChevronDown />
//                               </Link>
//                               {order?.shipping_address && order.id === myHoverId && (
//                                 <div className="ship-to-dropdown" style={hoverEffect ? displayInherit : displayNone}>
//                                   <h4>
//                                     {shippingAddress?.first_name}{" "}
//                                     {shippingAddress?.last_name}
//                                   </h4>
//                                   <p>{shippingAddress?.address}</p>
//                                   <p>
//                                     {shippingAddress?.city},{" "}
//                                     {shippingAddress?.zipcode},{" "}
//                                     {shippingAddress?.country}
//                                   </p>
//                                   <p>Phone: {shippingAddress?.phone}</p>
//                                 </div>
//                               )}
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="order-header-text">
//                           <ul>
//                             <li>TOTAL</li>
//                             <li>${order?.grand_total}</li>
//                           </ul>
//                         </div>
//                         <div
//                           className="order-header-text"
//                           onClick={() => {
//                             handleTrackPackageClick(order.id);
//                           }}
//                         >
//                           <Link to="#" className="tracking-Btn">
//                             Cancel Subscription
//                           </Link>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="col-xl-4 col-lg-12 col-md-12">
//                       <div className="d-block d-sm-flex justify-content-xl-end align-items-center mt-2 mt-xl-0">
//                         <div className="order-header-right">
//                           <div className="para-text">
//                             <p>
//                               <b>ORDER</b> #{order?.order_no}
//                             </p>
//                             <p className="text-left text-xl-right">
//                               <Link
//                                 to="#"
//                                 className="link-text"
//                                 onClick={() => handleOrdDetailClick(order.id)}
//                               >
//                                 Order Details
//                               </Link>
//                               <span className="mr-2 ml-2">|</span>
//                               <a
//                                 href={`${IMAGEURL}${order.invoice_pdf}`}
//                                 target="_blank"
//                                 rel="noopener noreferrer"
//                                 className="link-text"
//                               >
//                                 Invoice
//                               </a>
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="order-content p-3">
//                   <div className="row">
//                     <div className="col-xl-12">
//                       {/* Order content items */}
//                       {order.items.map((item) => (
//                         <div className="order-product-list" key={item.id}>
//                           <div className="row">
//                             <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12">
//                               <div className="d-sm-flex d-block">
//                                 <div className="product-img">
//                                   <img
//                                     src={
//                                       item.product?.thumbnail_name
//                                         ? `${IMAGEURL}upload/product/thumbnail/100/${item.product.thumbnail}`
//                                         : `${IMAGEURL}${item.product?.thumbnail}`
//                                     }
//                                     alt="product"
//                                     className="img-fluid"
//                                   />
//                                 </div>
//                                 <div className="product-info ml-md-3 ml-0 mt-md-0 mt-3">
//                                   <p className="mb-2">{item.product?.product_name}</p>
//                                   <p className="mb-2">
//                                     <b>Quantity: </b>
//                                     {item.quantity}
//                                   </p>
//                                   <p className="mb-2">
//                                     <b>Product Code: </b>
//                                     {item.product?.product_code}
//                                   </p>
//                                   <p className="mb-2">
//                                     <b>Sku: </b>
//                                     {item.product?.sku}
//                                   </p>
//                                 </div>
//                               </div>
//                             </div>
//                             <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12">
//                               <div className="product-review-part text-right float-left float-lg-right mt-3 mt-lg-0">
//                                 <Link
//                                   to={`/dashboard/create-review/${item.product_id}`}
//                                   className="theme-btn d-block"
//                                 >
//                                   Write A Product Review
//                                 </Link>
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//             {/* Pagination */}
//             <div style={{ float: "right" }}>
//               <ReactPaginate
//                 previousLabel={"<<"}
//                 nextLabel={">>"}
//                 breakLabel={<span className="ellipsis">...</span>}
//                 pageCount={totalPages || 0}
//                 marginPagesDisplayed={2}
//                 pageRangeDisplayed={2}
//                 onPageChange={handlePageChange}
//                 forcePage={currentPage - 1}
//                 containerClassName="pagination justify-content-center pt-5"
//                 activeClassName="active"
//                 pageClassName="page-item"
//                 pageLinkClassName="page-link"
//                 previousClassName="page-item"
//                 previousLinkClassName="page-link"
//                 nextClassName="page-item"
//                 nextLinkClassName="page-link"
//                 breakClassName="page-item"
//                 breakLinkClassName="page-link"
//               />
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default SubscribedProductCard;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// // import { AiFillStar } from "react-icons/ai";
// import authApi from "../../../../utils/authApi";
// import { toast } from "react-toastify";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
// import { APIBASE, IMAGEURL } from "../../../../constants";
// import { fetchWishlist } from "../../../../redux/productSlice";

// const SubscribedProductCard = () => {
//   const [productData, setProductData] = useState([]);
//   const user_id = useSelector((state) => state.userAuth.user.data?.id);
//   const dispatch = useDispatch();
//   const wishlist = useSelector((state) => state.products.wishlist);


//   const handleAddToWishList = async (id) => {
//     const payload = { user_id: user_id ? user_id : null, product_id: id };
//     if (user_id && id) {
//       try {
//         await axios.post(`${APIBASE}wishlists`, payload);
//         toast.success("Product added to wishlist successfully.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error!", { autoClose: 300 });
//       }
//     }
//   };

//   const handleDeleteWishlist = async (id) => {
//     if (id && user_id) {
//       try {
//         await axios.delete(`${APIBASE}wishlists/${id}`);
//         toast.success("Item deleted from wishlist.", { autoClose: 300 });
//         dispatch(fetchWishlist());
//       } catch (error) {
//         // comsole.log(error);(error);
//         toast.error("Error", { autoClose: 300 });
//       }
//     }
//   };

//   useEffect(() => {
//     authApi
//       .get("customer/get-active-subscription")
//       .then((response) => {
//         const res = response.data.data.data;
//         setProductData(res);
//         // comsole.log(error);(res);
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   }, []);

//   // comsole.log(error);("products to get", productData);

//   const handleCancelSubscription = async (subscriptionId) => {
//     try {
//       const payload = {
//         subscription_id: subscriptionId,
//       };

//       const response = await authApi.post("customer/cancel-subscription", payload);

//       // comsole.log(error);(response);

//       // Show a success toast message
//       toast.success("Subscription canceled successfully");
//       // comsole.log(error);(productData);
//     } catch (error) {
//       // Show an error toast message
//       toast.error("Failed to cancel subscription");
//       console.error("Error canceling subscription:", error);
//     }
//   };

//   const checkWish = (id) => {
//     const filter = wishlist?.filter((elem) => elem.product_id === id);

//     return filter?.length > 0 ? filter[0]?.id : "";
//   };

//   return (
//     <div className="row">
//       {productData.map((product, index) => {
//         const items = JSON.parse(product.items);
//         const itemProductID = items[0]?.product_id;
//         const isWishForItem = checkWish(itemProductID);
//         return (
//           <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-4">
//             <div className="product-card">
//               <div className="prod-img">
//                 {/* <img
//                   className="image-main"
//                   src={`${IMAGEURL}${items[0].image}`}
//                   alt="ProductImage"
//                 /> */}
//                 <img
//                   className="image-main"
//                   src={items[0]?.thumbnail_name ?
//                     `upload/product/thumbnail/100/${items[0].image}` :
//                     `${IMAGEURL}${items[0].image}`}
//                   alt="ProductImage"
//                 />

//               </div>
//               <div className="over-layer">
//                 <div className="pr-3 pt-3">
//                   <div className="pt-2 pb-1">
//                     {/* <Link to="">
//                       <i className="far fa-heart gray-color"></i>
//                     </Link> */}
//                     {isWishForItem ? (
//                       <Link onClick={() => handleDeleteWishlist(isWishForItem)}>
//                         <i className="fas fa-heart red-color"></i>
//                       </Link>
//                     ) : (
//                       <Link onClick={() => handleAddToWishList(itemProductID)}>
//                         <i className="far fa-heart gray-color"></i>
//                       </Link>
//                     )}
//                   </div>
//                   <div className="pt-2 pb-1">
//                     <Link to={`/product-detail/${items[0].slug}`}>
//                       <i className="far fa-eye gray-color"></i>
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="prod-content">
//                 <button className="prod-cart mt-2 mb-2" onClick={() => handleCancelSubscription(product.subscription_no)}>
//                   <Link to="" className="addCart">
//                     Cancel Subscription
//                   </Link>
//                 </button>

//                 <p className="price-text mt-3 mb-2">
//                   <span>US ${items[0].price}</span>
//                 </p>

//                 <p className="prod-description pb-1">{items[0].name}</p>

//                 <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                   Next delivery by <strong>{product.next_date}</strong>
//                 </p>

//                 <p className="prod-description pb-1" style={{ fontSize: "10px" }}>
//                   <strong>{product.month} </strong> month's subscription
//                 </p>

//                 {/* <div className="avail-text">
//                   <div className="text-left prod-code">
//                     <p>{product?.sku}</p>
//                   </div>

//                   <div className="text-right">
//                     <span className="rating-star">
//                       0 <AiFillStar /> | 1
//                     </span>
//                   </div>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default SubscribedProductCard;
