import React from 'react';
import BlogComponent from '../../modules/shared/Blog/Blog';




const Blog = () => {
  return (
    <>

      <section className='ptb-5'>
        <div className='container-side-space'>
            <div className='blog-container'>
              <BlogComponent/>
              <BlogComponent/>
              <BlogComponent/>
              <BlogComponent/>
            </div>
        </div>
      </section>
      
    </>
  )
}

export default Blog
 