import { Link } from "react-router-dom";
import "./PolicyPages.css";


export default function TermsAndConditions() {

  return (
    <main>
      <section>
        <div className="container-side-space">
          <div className="header-contact-body ptb-5">
            <div className="header-contact-form-new">
              <div className="heading-policy pb-4">
                <h3>Terms & Conditions</h3>
               
                <h5>
                  Terms and Conditions of Shopnmac.bm - Effective Date: 30 August 2023
                </h5>
                <br />
                <p>Welcome to Shopnmac.bm ("we," "us," "our," or "Shopnmac"). By using our website and services, you agree to comply with and be bound by the following Terms and Conditions. Please read these terms carefully before accessing or using our website. If you do not agree with these terms, please do not use our website.</p>
                {/* <br /> */}
                <h5>1. Use of the Website:</h5>
                <p>   - You must be at least 18 years old or the legal age in your jurisdiction to use our website.</p>
                <p>   - You agree to use our website for lawful purposes only and not for any illegal, fraudulent, or unauthorized activities.</p>
                <h5>2. Product Information:</h5>
                <p>   - We strive to provide accurate and up-to-date information about our products. However, we do not warrant the accuracy, completeness, or reliability of the product descriptions, images, or other content on our website.</p>
                <p>   - Prices and availability of products are subject to change without notice.</p>
                <h5>3. Orders and Payments</h5>
                <p>   - When you place an order, you are making an offer to purchase the products in your cart. We reserve the right to accept or decline your order.</p>
                <p>   - Payment for orders is processed through secure payment gateways. You agree to provide accurate and complete payment information.</p>
                <p>   - We reserve the right to cancel or refuse orders at our discretion, including due to inaccuracies in product information or pricing.</p>
                <h5>4. Shipping and Returns:</h5>
                <p>   - Shipping and delivery times are estimates and may vary based on factors beyond our control.</p>
                <p>   - Returns and exchanges are subject to our Return Policy, which is available on our website.</p>
                <h5>5. Intellectual Property:</h5>
                <p>   - All content on our website, including images, text, logos, and graphics, is protected by intellectual property laws and is the property of Shopnmac or its licensors.</p>
                <p>   - You may not reproduce, distribute, modify, or create derivative works from our content without our explicit permission.</p>
                <h5>6. User Accounts:</h5>
                <p>   - You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account</p>
                <p>   - You agree to provide accurate and complete information when creating an account and to update your information as needed.</p>
                <h5>7. User Conduct:</h5>

                <p>   - You agree not to use our website for any purpose that is unlawful, harassing, abusive, defamatory, or otherwise objectionable.</p>

                <p>   - You may not engage in any activity that disrupts or interferes with the functioning of our website or its associated services.
                </p>

                <h5>8. Limitation of Liability:</h5>

                <p>- Shopnmac is not liable for any indirect, incidental, special, consequential, or punitive damages arising from your use of our website or products.
                </p>

                <p>
                  - Our total liability for any claim relating to our website or products is limited to the amount you paid for the relevant product.
                </p>


                <h5>9. Indemnification:</h5>
                <p>
                  - You agree to indemnify and hold Shopnmac and its affiliates, officers, employees, and agents harmless from any claims, liabilities, damages, and expenses arising from your use of our website or violation of these Terms and Conditions.</p>



                <h5>10. Modification of Terms:</h5>
                <p>
                  - We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Changes will be effective upon posting on our website.
                </p>

                <h5>11. Governing Law and Dispute Resolution:</h5>
                <p>
                  - These Terms and Conditions are governed by the laws of Bermuda, without regard to its conflict of law principles.
                </p>
                <p>
                  - Any disputes arising from or relating to these terms shall be subject to the exclusive jurisdiction of the courts in Bermuda.
                </p>


                <h5>
                  12. Contact Us:
                </h5>

                <p>
                  - If you have any questions, concerns, or feedback regarding these Terms and Conditions, please contact us at{" "}
                  <Link className="ancher-text" to="mailto:info@shopnmac.com">
                    info@nmac.bm
                  </Link>
                  .
                </p>

                <hr />
                {/* <h5>GENERAL CONDITIONS</h5> */}
                <p>
                  By using Shopnmac.bm, you acknowledge that you have read and understood these Terms and Conditions and agree to abide by them..</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
