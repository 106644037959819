import React from 'react';
import {Link } from "react-router-dom";

export default function BrandList() {
  return (
    
      <div className="brand-list">
        <Link to="#">
          <p>Advanced Naturals</p>
        </Link>
      </div>
    
  );
}
