import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import ProductImage from "../../../assets/product-image.jpg";
import { updateQuantity, removeItem } from "../../../redux/userCartSlice";
import { IMAGEURL } from "../../../../src/constants";
import { toast } from "react-toastify";
export default function Items({ item }) {
  // const [qty,setQty]=useState('')
  const dispatch = useDispatch();

  const handleUpdateQuantity = (event, rowId) => {
    const quantity = +event.target.value;
    if(+item?.avl_qty>=quantity){
      dispatch(updateQuantity({ rowId, quantity }));

    }else{
      toast.warn(`Only ${item?.avl_qty} left`)
    }
  };

  const handleRemoveItem = (rowId) => {
    dispatch(removeItem({ rowId }));
  };

  return (
    <div key={item.rowId} className="view-cart-product">
      <div className="check">
        <div className="custom-control custom-checkbox mb-3">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`customCheck-${item.rowId}`}
          />
          <label
            className="custom-control-label fa-18x"
            htmlFor={`customCheck-${item.rowId}`}
          ></label>
        </div>
      </div>

      <div className="cart-prod-img">
        <Link to={`/product-detail/${item.slug}`}>
          {/* <img src={`${item.image}` || ProductImage} alt={item.name} /> */}
          {item?.thumbnail_name ? (
            <img
              src={`${IMAGEURL}upload/product/thumbnail/100/${item.image}`}
              alt={item.name}
            />
          ) : (
            <img src={`${IMAGEURL}${item.image}`} alt={item.name} />
          )}
        </Link>
      </div>
      <div className="cart-prod-name">
        <div className="cart-rod-detail">
          <h3>
            <Link to={`/product-detail/${item.slug}`}>
              {item.name ? item.name : item.slug}
            </Link>
          </h3>
          <p>
            {item.category && (
              <span>
                <b>Categories :</b> {item.category},
              </span>
            )}

            {item.sku && (
              <span>
                <b> Item Code :</b> {item.sku}
              </span>
            )}
          </p>

          <p>
            {item.unit && (
              <span>
                <b>Unit :</b> {item.unit},
              </span>
            )}
            {item.weight && (
              <span>
                <b>Weight :</b> {item.weight}
              </span>
            )}
          </p>
         {item?.avl_qty && <p>
            {item?.avl_qty} left
          </p>}
          <div className="cart-qty d-flex mt-2">
            <input
              id={`qty-${item.rowId}`}
              type="text"
              className="form-control w-40"
              placeholder="No. Of Quantity"
              value={item.qty}
              onChange={(event) => handleUpdateQuantity(event, item.rowId)}
            />
            <button
              onClick={() => handleRemoveItem(item.rowId)}
              type="btn"
              className="btn btn-gray border-1 ml-3"
            >
              Remove
            </button>
          </div>
        </div>
        <div className="prod-price position-relative">
          <p className="text-right text-smm-left">
            <b>${item.price <= item.old_price && item.price}</b>{" "}
            {item.price < item.old_price && (
              <b
                style={{
                  textDecorationLine: "line-through",
                  marginRight: "15px",
                }}
              >
                ${item.old_price}
              </b>
            )}
          </p>
          {item.subscription && (
            <>
              <p className="fa-15">
                {/* {// comsole.log(error);(item.subscription.discount)} */}
                {item.subscription?.discount?.discount || 0}% Subscribe & Save
                discount applied.
              </p>
              <div className="dropdown text-right">
                <span>Deliver every: </span>
                <Link
                  className="dropdown-toggle link-text ml-2"
                  to="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {item.subscription.interval} {item.subscription.type}
                </Link>

                <div
                  className="dropdown-menu fade-in custom-dropdown-style"
                  aria-labelledby="dropdownMenuLink"
                >
                  <span>
                    <b>Deliver every</b>
                  </span>
                  <Link
                    className="dropdown-item"
                    to="#"
                  // onClick={handleUpdateSUbscriptionInterval(item.rowId, 1)}
                  >
                    1 Month
                  </Link>
                  <Link
                    className="dropdown-item"
                    to="#"
                  // onClick={handleUpdateSUbscriptionInterval(item.rowId, 2)}
                  >
                    3 Months
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
